import React, { useEffect, useState } from "react";
import { faCirclePlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { validEmail, isValidContactNumber, resetErrorFieldStyle } from "../Variables";
import { Modal } from "react-bootstrap";
import { useContextStateManagement } from "../context/ContextApi";
import InputMask from 'react-input-mask';
import { useDispatch } from "react-redux";
import { logout } from "../features/userSlice";

export const PriorAuthQForm = ({ closeModal, setIsUploadLead }) => {
  const [providerData, setProviderData] = useState([]);
  const [addRows, setAddRows] = useState([]);
  const [modalShow, setModalShow] = useState(false)
  const [providerId, setProviderId] = useState(0);
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const dispatch = useDispatch();
  const { PracticeId, setSideBarLinkActive, setToken, token, fetchUserRecord, usersPostRequest } = useContextStateManagement();

  let isAnyUploadFieldEmpty = false;
  let isValidDetails = false;

  const isAnyFormFieldEmpty = () => {
    document.querySelectorAll('.uploadLeadForm-table-row').forEach(row => {

      const patientContactNumber = row.querySelector('.PatientContactNumber').value;
      const patientEmailAddress = row.querySelector('.PatientEmailAddress').value;
      const PatientInsuranceContactNumber = row.querySelector('.PatientInsuranceContactNumber').value;

      row.querySelectorAll('.form-control').forEach((e) => {
        if (e.value === "") {
          e.classList.add("invalid-Credentials");
          isAnyUploadFieldEmpty = true;
        }
      })

      if (patientContactNumber !== "" && isValidContactNumber(patientContactNumber) === false) {
        row.querySelector('.PatientContactNumber').classList.add("invalid-Credentials");
        isValidDetails = false;
      }

      if (patientEmailAddress !== "" && validEmail.test(patientEmailAddress) === false) {
        row.querySelector('.PatientEmailAddress').classList.add("invalid-Credentials");
        isValidDetails = false;
      }

      if (PatientInsuranceContactNumber !== "" && isValidContactNumber(PatientInsuranceContactNumber) === false) {
        row.querySelector('.PatientInsuranceContactNumber').classList.add("invalid-Credentials");
        isValidDetails = false;
      }

      if (patientEmailAddress !== "" && validEmail.test(row.querySelector('.PatientEmailAddress').value) === true && patientContactNumber !== "" && isValidContactNumber(row.querySelector('.PatientContactNumber').value) === true && PatientInsuranceContactNumber !== "" && isValidContactNumber(row.querySelector('.PatientInsuranceContactNumber').value) === true) {
        isValidDetails = true;
      }

      document.querySelectorAll('.uploadLeadForm-table-row').forEach((e) => {
        e.querySelectorAll('.form-control').forEach((e) => {
          if (e.classList.contains("invalid-Credentials")) {
            isValidDetails = false;
          }
        })
      })

    })

    if (isAnyUploadFieldEmpty === true) {
      alert("Please enter complete details of all leads")
      isAnyUploadFieldEmpty = false;
      setModalShow(false)
      return
    }

    if (isValidDetails === false) {
      setModalShow(false)
    } else if (isValidDetails === true) {
      setModalShow(true)
    }
  }

  const fetchApiHandle = async () => {
    const userData = await fetchUserRecord(token, `/api/PAuthQOnBoardingTemplate/${PracticeId}`);
    if (userData == "Token is expired, login again") {
      setIsTokenExpired(true)
    }
    else {
      setProviderData(userData)
      userData.length > 0 ? setProviderId(userData[0].Id) : "";
    }
  }

  const addNewRowHandle = () => {
    let gridRows = [...addRows];
    const selectedProvider = providerData.filter((provider) => provider.Id == providerId);
    const firstProviderData = selectedProvider[0];

    const newRow = {
      Id: gridRows.length + 1,
      PatientFullName: '',
      PatientDateOfBirth: '',
      PatientContactNumber: '',
      PatientEmailAddress: '',
      PatientInsuranceProvider: '',
      PatientInsuranceIdNumber: '',
      PatientInsuranceContactNumber: '',
      ProcedureCPT_codes: '',
      ProcedureICD_diagnosisCode: '',
      ProcedureDescription: '',
      ICD_descriptions: '',
      ProviderName: firstProviderData.ProviderName,
      ProviderFacilityName: firstProviderData.ProviderFacilityName,
      NationalProviderIdentifier: firstProviderData.NationalProviderIdentifier,
      ProviderContactNumber: firstProviderData.ProviderContactNumber,
      ProviderFaxNumber: firstProviderData.ProviderFaxNumber,
      ProviderEmailAddress: firstProviderData.ProviderEmailAddress
    };

    gridRows.push(newRow);
    setAddRows(gridRows);
    //populateFormFieldsHandle();
  };

  const populateDefaultRow = () => {
    let gridRows = [...addRows];
    if (gridRows.length == 0) {
      const selectedProvider = providerData.filter((provider) => provider.Id == providerId);
      const firstProviderData = selectedProvider[0];

      const newRow = {
        Id: gridRows.length + 1,
        PatientFullName: '',
        PatientDateOfBirth: '',
        PatientContactNumber: '',
        PatientEmailAddress: '',
        PatientInsuranceProvider: '',
        PatientInsuranceIdNumber: '',
        PatientInsuranceContactNumber: '',
        ProcedureCPT_codes: '',
        ProcedureICD_diagnosisCode: '',
        ProcedureDescription: '',
        ICD_descriptions: '',
        ProviderName: firstProviderData.ProviderName,
        ProviderFacilityName: firstProviderData.ProviderFacilityName,
        NationalProviderIdentifier: firstProviderData.NationalProviderIdentifier,
        ProviderContactNumber: firstProviderData.ProviderContactNumber,
        ProviderFaxNumber: firstProviderData.ProviderFaxNumber,
        ProviderEmailAddress: firstProviderData.ProviderEmailAddress
      };
      gridRows.push(newRow);
      setAddRows(gridRows);
    }
  }

  const updateGridRowData = (e) => {

    let pId = e.target.value;
    let rowIndex = e.target.getAttribute("dataindex");

    const selectedProvider = providerData.filter((provider) => provider.Id == pId);
    const firstProviderData = selectedProvider[0];

    let gridRows = [...addRows];

    gridRows[rowIndex].ProviderName = firstProviderData.ProviderName;
    gridRows[rowIndex].ProviderFacilityName = firstProviderData.ProviderFacilityName;
    gridRows[rowIndex].NationalProviderIdentifier = firstProviderData.NationalProviderIdentifier;
    gridRows[rowIndex].ProviderContactNumber = firstProviderData.ProviderContactNumber;
    gridRows[rowIndex].ProviderFaxNumber = firstProviderData.ProviderFaxNumber;
    gridRows[rowIndex].ProviderEmailAddress = firstProviderData.ProviderEmailAddress;

    setAddRows(gridRows);
  }

  const selectedProviderName = (e) => {
    if (e.selectedIndex === -1) {
      return null;
    }
    return e.options[e.selectedIndex].text;
  }

  const saveRecordHandle = async () => {
    const array = [];
    document.querySelectorAll('.uploadLeadForm-table-row').forEach(row => {
      const patientFullName = row.querySelector('.PatientFullName').value;
      const patientDateOfBirth = row.querySelector('.PatientDateOfBirth').value;
      const patientContactNumber = row.querySelector('.PatientContactNumber').value;
      const patientEmailAddress = row.querySelector('.PatientEmailAddress').value;
      const PatientInsuranceProvider = row.querySelector('.PatientInsuranceProvider').value;
      const PatientInsuranceIdNumber = row.querySelector('.PatientInsuranceIdNumber').value;
      const PatientInsuranceContactNumber = row.querySelector('.PatientInsuranceContactNumber').value;
      const ProcedureCPT_codes = row.querySelector('.ProcedureCPT_codes').value;
      const ProcedureICD_diagnosisCode = row.querySelector('.ProcedureICD_diagnosisCode').value;
      const ProcedureDescription = row.querySelector('.ProcedureDescription').value;
      const ICD_descriptions = row.querySelector('.ICD_descriptions').value;
      const ProviderId = row.querySelector('.ProviderName').value;
      const ProviderName = row.querySelector('.ProviderName');
      const newProviderName = selectedProviderName(ProviderName);
      const ProviderFacilityName = row.querySelector('.ProviderFacilityName').value;
      const NationalProviderIdentifier = row.querySelector('.NationalProviderIdentifier').value;
      const ProviderContactNumber = row.querySelector('.ProviderContactNumber').value;
      const ProviderFaxNumber = row.querySelector('.ProviderFaxNumber').value;
      const ProviderEmailAddress = row.querySelector('.ProviderEmailAddress').value;

      const pAuthQNewObj = {
        PracticeId: PracticeId,
        PatientFullName: patientFullName,
        PatientDateOfBirth: patientDateOfBirth,
        PatientContactNumber: patientContactNumber,
        PatientEmailAddress: patientEmailAddress,
        PatientInsuranceProvider: PatientInsuranceProvider,
        PatientInsuranceIdNumber: PatientInsuranceIdNumber,
        PatientInsuranceContactNumber: PatientInsuranceContactNumber,
        ProcedureCPT_codes: ProcedureCPT_codes,
        ProcedureICD_diagnosisCode: ProcedureICD_diagnosisCode,
        ProcedureDescription: ProcedureDescription,
        ICD_descriptions: ICD_descriptions,
        ProviderId: ProviderId,
        ProviderName: newProviderName,
        ProviderFacilityName: ProviderFacilityName,
        NationalProviderIdentifier: NationalProviderIdentifier,
        ProviderContactNumber: ProviderContactNumber,
        ProviderFaxNumber: ProviderFaxNumber,
        ProviderEmailAddress: ProviderEmailAddress,
        Transcript: "SDFASD",
        MissedCalls: 0,
        LastCallDuration: 0,
        CallSummary: "",
        TotalCallAttempts: 0,
        DoNotCall: false
      };

      array.push(pAuthQNewObj);
    });

    let url = "/api/PriorAuthQLead";
    const userData = await usersPostRequest(token, url, array);
    if (userData == "Token is expired, login again") {
      setIsTokenExpired(true)
      return
    }
    else {
      setIsUploadLead(true)
      setSideBarLinkActive("careConsole")
      setModalShow(false)
      closeModal()
    }

  };

  const deleteRowHandle = (id) => {
    let updatedRows = addRows.filter(item => item.Id !== id);
    setAddRows(updatedRows);
  }

  useEffect(() => {
    if (providerData.length == 0) {
      fetchApiHandle();

    }
  }, [providerData])

  useEffect(() => {
    if (providerData.length > 0) {
      populateDefaultRow();
      document.getElementById("addNewRow").removeAttribute("disabled");
    } else {
      document.getElementById('addNewRow').setAttribute("disabled", "true");
    }
  }, [providerData]);

  useEffect(() => {
    if (isTokenExpired === true) {
      alert("Token is expired, login again");
      setToken("")
      setIsTokenExpired("")
      dispatch(logout());
    }
  }, [isTokenExpired]);

  return (
    <>
      <button id="addNewRow" className="simple-btn mb-3" onClick={addNewRowHandle}> <FontAwesomeIcon icon={faCirclePlus} className="me-2" />Add New Row</button>
      <div className="row position-relative">
        <div className="col-lg-12 priorTable">
          <table
            id="pAuthQualificationModalTable"
            className="table table-styling mb-5"
          >
            <thead>
              <tr>
                <th colSpan="7" className="text-center border-end border-dark">
                  Patient Information
                </th>
                <th colSpan="4" className="text-center border-end border-dark">
                  Procedure Information
                </th>
                <th colSpan="7" className="text-center providerInfoHeading">
                  Provider Information
                </th>
              </tr>
              <tr className="text-center table-columns-name">
                <th style={{ paddingRight: "3.9rem" }}>Full Name</th>
                <th>Date of Birth</th>
                <th>Contact Number</th>
                <th style={{ paddingRight: "5.6rem" }}>Email Address</th>
                <th>Insurance Provider</th>
                <th>Insurance Id Number</th>
                <th className="border-end border-dark">
                  Insurance Contact Number
                </th>
                <th>CPT Codes for the Procedure</th>
                <th>Procedure Description</th>
                <th>ICD Diagnosis Codes</th>
                <th className="border-end border-dark">ICD Description</th>
                <th>Name</th>
                <th>Clinic/Facility Name</th>
                <th>National Provider Identifier (NPI)</th>
                <th>Contact Number</th>
                <th>Fax Number</th>
                <th>Email Address</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {addRows.map((row, index) => (
                <tr key={index} className="uploadLeadForm-table-row">
                  <td><input type="text" className={`PatientFullName form-control`} placeholder="John Smith" onBlur={resetErrorFieldStyle} /></td>
                  <td><input type="date" className="PatientDateOfBirth form-control" placeholder="" onBlur={resetErrorFieldStyle} /></td>
                  <td><InputMask mask="999-999-9999" onBlur={resetErrorFieldStyle} className="PatientContactNumber form-control" placeholder="123-456-7890" /></td>
                  <td><input type="text" onBlur={resetErrorFieldStyle} className="PatientEmailAddress form-control" placeholder="john.smith@example.com" /></td>
                  <td><input type="text" className="PatientInsuranceProvider form-control" onBlur={resetErrorFieldStyle} placeholder="ABC Insurance" /></td>
                  <td><input type="text" className="PatientInsuranceIdNumber form-control" onBlur={resetErrorFieldStyle} placeholder="G1234567890" /></td>
                  <td><InputMask mask="999-999-9999" onBlur={resetErrorFieldStyle} className="PatientInsuranceContactNumber form-control" placeholder="123-456-7890" /></td>
                  <td><input type="text" className="ProcedureCPT_codes form-control" onBlur={resetErrorFieldStyle} placeholder="CPT codes" /></td>
                  <td><input type="text" className="ProcedureDescription form-control" onBlur={resetErrorFieldStyle} placeholder="Procedure Description" /></td>
                  <td><input type="text" className="ProcedureICD_diagnosisCode form-control" onFocus={resetErrorFieldStyle} placeholder="ICD diagnosisCode" /></td>
                  <td><input type="text" className="ICD_descriptions form-control" onBlur={resetErrorFieldStyle} placeholder="ICD_descriptions" /></td>
                  <td>
                    <select className="ProviderName uploadLeadProviders form-select" dataindex={index} onChange={updateGridRowData} onBlur={resetErrorFieldStyle}>
                      {
                        providerData.length > 0 ?
                          providerData.map((provider) => {
                            return (
                              <option value={provider.Id}>{provider.ProviderName}</option>
                            );
                          })
                          : ""
                      }
                    </select>
                  </td>
                  <td><input type="text" className="ProviderFacilityName form-control" onBlur={resetErrorFieldStyle} value={row.ProviderFacilityName} /></td>
                  <td><input type="text" className="NationalProviderIdentifier form-control" onBlur={resetErrorFieldStyle} value={row.NationalProviderIdentifier} /></td>
                  <td><input type="text" className="ProviderContactNumber form-control" onBlur={resetErrorFieldStyle} value={row.ProviderContactNumber} /></td>
                  <td><input type="text" className="ProviderFaxNumber form-control" onBlur={resetErrorFieldStyle} value={row.ProviderFaxNumber} /></td>
                  <td><input type="text" className="ProviderEmailAddress form-control" onBlur={resetErrorFieldStyle} value={row.ProviderEmailAddress} /></td>
                  <td><button className="simple-btn" onClick={() => deleteRowHandle(row.Id)}><FontAwesomeIcon icon={faXmark} /></button></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="text-end position-absolute bottom-0 mb-3">
          <button className="simple-btn" onClick={isAnyFormFieldEmpty} >Save</button>
        </div>

      </div>
      <div id="conFirmModalContainer">
        <Modal className="popup" show={modalShow} onHide={() => setModalShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Do you confirm</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>By saving you are verifying that you are uploading the data in the database</p>
          </Modal.Body>
          <Modal.Footer>
            <button className="simple-btn me-2" onClick={() => setModalShow(false)}>No</button>
            <button className="simple-btn" onClick={saveRecordHandle}>Yes</button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};


