import * as React from "react";
import { useContextStateManagement } from "../context/ContextApi";
import { validContactNumber, resetErrorFieldStyle } from "../Variables";
import InputMask from "react-input-mask";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../features/userSlice";

export const VisitRemindersOnboardingForm = () => {
    const { PracticeId, userProfession, token, usersPostRequest, setToken } = useContextStateManagement();
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const dispatch = useDispatch();
    let isAnyUploadFieldEmpty = false;
    let isValidDetails = false;

    const handleSubmit = async () => {
        let ProviderName = document.getElementById('ProviderName').value;
        let ProviderFacilityName = document.getElementById('ProviderFacilityName').value;
        let ProviderSpecialtyType = document.getElementById('ProviderSpecialtyType').value;
        let ProviderContactNumber = document.getElementById('ProviderContactNumber').value;

        document.querySelectorAll('.px-3').forEach((e) => {
            if (e.value === "") {
                e.classList.add("invalid-details")
            }
        })

        if (ProviderName === "" || ProviderFacilityName === "" || ProviderContactNumber === "" || ProviderSpecialtyType === "") {
            isAnyUploadFieldEmpty = true;
        }

        if (ProviderContactNumber !== "" && validContactNumber.test(ProviderContactNumber) === false) {
            document.getElementById('ProviderContactNumber').classList.add("invalid-details");
            isValidDetails = true;
        }

        if (isAnyUploadFieldEmpty === true) {
            alert('Please enter complete details of onboarding')
            return
        }

        if (isValidDetails === true) {
            return
        }

        let obj = {
            PracticeId: PracticeId,
            ProviderName: ProviderName,
            ProviderFacilityName: ProviderFacilityName,
            ProviderSpecialtyType: ProviderSpecialtyType,
            ProviderContactNumber: ProviderContactNumber,
        }

        let url = '/api/VisitRemindersOnboarding';
        const userData = await usersPostRequest(token, url, obj);
        if (userData == "Token is expired, login again") {
            setIsTokenExpired(true)
        }
        else if (userData && userData.message) {
            alert(userData.message)
        }

        if (ProviderName !== "" || ProviderContactNumber !== "" || ProviderSpecialtyType !== "") {
            document.getElementById('ProviderName').value = "";
            document.getElementById('ProviderContactNumber').value = "";
            document.getElementById('ProviderSpecialtyType').value = "";
        }
    };

    useEffect(() => {
        if (isTokenExpired === true) {
            alert("Token is expired, login again");
            setToken("")
            setIsTokenExpired(false)
            dispatch(logout());
        }
    }, [isTokenExpired]);

    return (
        <>
            <div className="container onboardingLayout py-4 px-4 shadow-sm">
                <div className="row p-3 col-md-5 m-auto login-container onboarding-forms bg_dark_opacity">
                    <div>
                        <h6 className="fw-bold mt-2 text-white mb-1">Visit Reminders</h6>
                        <p className="text-white">Enter your practice information</p>
                    </div>
                    <div className="col">
                        <div className="mb-3">
                            <label className="text-white form-label mt-3">Provider Name</label>
                            <input type="text" onFocus={resetErrorFieldStyle} className="bg-black text-white border-0 py-2 px-3 form-control" id="ProviderName" placeholder="Enter Provider name" />
                        </div>
                        <div className="mb-3">
                            <label className="text-white form-label">Clinic/Facility Name</label>
                            <input type="text" disabled onFocus={resetErrorFieldStyle} className="bg-black text-white border-0 py-2 px-3 form-control" value={userProfession} id="ProviderFacilityName" placeholder="Enter your clinic name" />
                        </div>
                        <div className="mb-3">
                            <label className="text-white form-label">Speciality Type</label>
                            <input type="text" onFocus={resetErrorFieldStyle} className="bg-black text-white border-0 py-2 px-3 form-control" id="ProviderSpecialtyType" placeholder="Cardiology" />
                        </div>
                        <div className="mb-3">
                            <label className="text-white form-label">Contact Information</label>
                            <InputMask mask="999-999-9999" className="bg-black text-white border-0 py-2 px-3 form-control" onFocus={resetErrorFieldStyle} id="ProviderContactNumber"
                                placeholder="Enter contact number" />
                        </div>
                        <div className="text-center mb-2">
                            <a
                                href="javascript:void(0)"
                                className="simple-btn login-btn"
                                onClick={handleSubmit}
                            >
                                Save
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
