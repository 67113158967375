import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faHouse, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { CircularProgress, Box, Select, MenuItem } from '@mui/material';
import { useDispatch } from "react-redux";
import { logout } from "../features/userSlice";
import { useNavigate } from "react-router-dom";
import { useContextStateManagement } from "../context/ContextApi";

export const Dashboard = ({ setSideBarLinkActive, setDashboardUrl, userName, userProfession }) => {
    const { usersPostRequest, PracticeId, token, setToken, setActiveTab } = useContextStateManagement();
    // const [isVisitRChartsData, setIsVisitRChartsData] = useState(false);
    // const [pAuthSChartsData, setPAuthSChartsData] = useState([]);
    // const [durationInMinOnly, setDurationInMinOnly] = useState("");
    // const [durationInSecOnly, setDurationInSecOnly] = useState(null);
    // const [bookedCallsAll, setBookedCallsAll] = useState(null);
    // const [totalCallsAll, setTotalCallsAll] = useState(null);
    // const [totalDurationMine, setTotalDurationMine] = useState(null);
    // const [completedCallsMine, setCompletedCallsMine] = useState(null);
    // const [missedBusyNoAnswerCallsMine, setMissedBusyNoAnswerCallsMine] = useState(null);
    // const [totalCallsData, setTotalCallsData] = useState(null);
    // const [totalDurationData, setTotalDurationData] = useState(null);
    // const [completedCallsData, setCompletedCallsData] = useState(null);
    // const [missedBusyNoAnswerCallsData, setMissedBusyNoAnswerCallsData] = useState(null);
    // const [bookedCallsData, setBookedCallsData] = useState(null);
    // const [isGetUserData, setIsGetUserData] = useState(false);
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const [totalCallsMine, setTotalCallsMine] = useState(null);
    const [totalDurationInHours, setTotalDurationInHours] = useState("");
    const [totalDurationInMin, setTotalDurationInMin] = useState(null);
    const [secRemainingFromMin, setSecRemainingFromMin] = useState("");
    const [bookedCallsMine, setBookedCallsMine] = useState(null);
    const [memberListName, setMemberListName] = useState("VisitRemindersUploadLead");
    const [loadingEnable, setLoadingEnable] = useState(false);
    const [chartsLoadingEnable, setChartsLoadingEnable] = useState(false);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const careConsoleHandle = () => {
        setSideBarLinkActive("careConsole")
        setActiveTab("pAuthQTab")
    }

    var dataCurrentLivesQM;
    var dataOutReachedQM;
    var dataReachedQM;
    var dataEngagedQM;
    var dataCurrentLivesColonoscopy;
    var dataOutReachedColonoscopy;
    var dataReachedColonoscopy;
    var dataEngagedColonoscopy;
    var dataIneligiblesColonoscopy;
    var dataIneligiblesQM;
    var dataCurrentLivesMammogram;
    var dataOutReachedMammogram;
    var dataReachedMammogram;
    var dataEngagedMammogram;
    var dataIneligiblesMammogram;
    var dataCurrentLivesHbA1c;
    var dataOutReachedHbA1c;
    var dataReachedHbA1c;
    var dataEngagedHbA1c;
    var dataIneligiblesHbA1c;
    var dataCurrentLivesFlu;
    var dataOutReachedFlu;
    var dataReachedFlu;
    var dataEngagedFlu;
    var dataIneligiblesFlu;
    var dataCurrentLivesBP140;
    var dataOutReachedBP140;
    var dataReachedBP140;
    var dataEngagedBP140;
    var dataIneligiblesBP140;
    var distinctCareCoordinators;
    var selectedTopRiskScorePercentage;
    var CareCoordinatorsNew;
    var patientsPerCareCoordinator;
    var legendData;

    var metricsKeysDetail = {
        "ChrisGarcia": "Chris Garcia",
        "SNFReadmission": "SNF Readmission",
        "EDAdmission": "ED Admission",
        "AvoidableED": "Avoidable ED",
        "AWV": "AWV"
    };

    var defaultCareCoordForNonMetricsCoordinators = "ChrisGarcia";

    // var metricsConfiguration = {
    //     Percent_35: {
    //         "ChrisGarcia": {
    //             "HospitalReadmission": {
    //                 "all": 10,
    //                 "mine": 13.79
    //             },
    //             "SNFReadmission": {
    //                 "all": 10,
    //                 "mine": 3.22
    //             },
    //             "EDAdmission": {
    //                 "all": 9.95,
    //                 "mine": 12.07
    //             },
    //             "AvoidableED": {
    //                 "all": 10,
    //                 "mine": 12
    //             },
    //             "AWV": {
    //                 "all": 9.97,
    //                 "mine": 10.74
    //             }
    //         },
    //         "DebbieBrooke": {
    //             "HospitalReadmission": {
    //                 "all": 10,
    //                 "mine": 5.17
    //             },
    //             "SNFReadmission": {
    //                 "all": 10,
    //                 "mine": 6.45
    //             },
    //             "EDAdmission": {
    //                 "all": 9.95,
    //                 "mine": 8.95
    //             },
    //             "AvoidableED": {
    //                 "all": 10,
    //                 "mine": 9
    //             },
    //             "AWV": {
    //                 "all": 9.97,
    //                 "mine": 9.87
    //             }
    //         },
    //         "MirzaHuda": {
    //             "HospitalReadmission": {
    //                 "all": 10,
    //                 "mine": 8.62
    //             },
    //             "SNFReadmission": {
    //                 "all": 10,
    //                 "mine": 38.7
    //             },
    //             "EDAdmission": {
    //                 "all": 9.95,
    //                 "mine": 9.48
    //             },
    //             "AvoidableED": {
    //                 "all": 10,
    //                 "mine": 10
    //             },
    //             "AWV": {
    //                 "all": 9.97,
    //                 "mine": 8.72
    //             }
    //         },
    //         "ReninaShonda": {
    //             "HospitalReadmission": {
    //                 "all": 10,
    //                 "mine": 8.62
    //             },
    //             "SNFReadmission": {
    //                 "all": 10,
    //                 "mine": 6.45
    //             },
    //             "EDAdmission": {
    //                 "all": 9.95,
    //                 "mine": 9.42
    //             },
    //             "AvoidableED": {
    //                 "all": 10,
    //                 "mine": 9
    //             },
    //             "AWV": {
    //                 "all": 9.97,
    //                 "mine": 10.84
    //             }
    //         }
    //     },
    //     Percent_15: {
    //         "ChrisGarcia": {
    //             "HospitalReadmission": {
    //                 "all": 9.32,
    //                 "mine": 13.91
    //             },
    //             "SNFReadmission": {
    //                 "all": 11,
    //                 "mine": 3.22
    //             },
    //             "EDAdmission": {
    //                 "all": 10,
    //                 "mine": 12.47
    //             },
    //             "AvoidableED": {
    //                 "all": 10,
    //                 "mine": 15
    //             },
    //             "AWV": {
    //                 "all": 10,
    //                 "mine": 10
    //             }
    //         },
    //         "DebbieBrooke": {
    //             "HospitalReadmission": {
    //                 "all": 9.32,
    //                 "mine": 5.21
    //             },
    //             "SNFReadmission": {
    //                 "all": 11,
    //                 "mine": 3.32
    //             },
    //             "EDAdmission": {
    //                 "all": 10,
    //                 "mine": 9.64
    //             },
    //             "AvoidableED": {
    //                 "all": 10,
    //                 "mine": 14
    //             },
    //             "AWV": {
    //                 "all": 10,
    //                 "mine": 9.89
    //             }
    //         },
    //         "MirzaHuda": {
    //             "HospitalReadmission": {
    //                 "all": 9.32,
    //                 "mine": 8.69
    //             },
    //             "SNFReadmission": {
    //                 "all": 11,
    //                 "mine": 3.28
    //             },
    //             "EDAdmission": {
    //                 "all": 10,
    //                 "mine": 9.17
    //             },
    //             "AvoidableED": {
    //                 "all": 10,
    //                 "mine": 13
    //             },
    //             "AWV": {
    //                 "all": 10,
    //                 "mine": 9.25
    //             }
    //         },
    //         "ReninaShonda": {
    //             "HospitalReadmission": {
    //                 "all": 9.32,
    //                 "mine": 8.69
    //             },
    //             "SNFReadmission": {
    //                 "all": 11,
    //                 "mine": 3.12
    //             },
    //             "EDAdmission": {
    //                 "all": 10,
    //                 "mine": 8
    //             },
    //             "AvoidableED": {
    //                 "all": 10,
    //                 "mine": 16
    //             },
    //             "AWV": {
    //                 "all": 10,
    //                 "mine": 10.63
    //             }
    //         }
    //     },
    //     Percent_5: {
    //         "ChrisGarcia": {
    //             "HospitalReadmission": {
    //                 "all": 9.41,
    //                 "mine": 13.79
    //             },
    //             "SNFReadmission": {
    //                 "all": 9,
    //                 "mine": 3.22
    //             },
    //             "EDAdmission": {
    //                 "all": 10.14,
    //                 "mine": 12.07
    //             },
    //             "AvoidableED": {
    //                 "all": 10,
    //                 "mine": 12
    //             },
    //             "AWV": {
    //                 "all": 9.97,
    //                 "mine": 10.74
    //             }
    //         },
    //         "DebbieBrooke": {
    //             "HospitalReadmission": {
    //                 "all": 9.41,
    //                 "mine": 5.35
    //             },
    //             "SNFReadmission": {
    //                 "all": 9,
    //                 "mine": 3.25
    //             },
    //             "EDAdmission": {
    //                 "all": 10.14,
    //                 "mine": 9.85
    //             },
    //             "AvoidableED": {
    //                 "all": 10,
    //                 "mine": 11
    //             },
    //             "AWV": {
    //                 "all": 10.03,
    //                 "mine": 11.28
    //             }
    //         },
    //         "MirzaHuda": {
    //             "HospitalReadmission": {
    //                 "all": 9.41,
    //                 "mine": 8.92
    //             },
    //             "SNFReadmission": {
    //                 "all": 9,
    //                 "mine": 3.28
    //             },
    //             "EDAdmission": {
    //                 "all": 10.14,
    //                 "mine": 7.53
    //             },
    //             "AvoidableED": {
    //                 "all": 10,
    //                 "mine": 13
    //             },
    //             "AWV": {
    //                 "all": 10.03,
    //                 "mine": 9.09
    //             }
    //         },
    //         "ReninaShonda": {
    //             "HospitalReadmission": {
    //                 "all": 9.41,
    //                 "mine": 8.92
    //             },
    //             "SNFReadmission": {
    //                 "all": 9,
    //                 "mine": 3.30
    //             },
    //             "EDAdmission": {
    //                 "all": 10.14,
    //                 "mine": 8.69
    //             },
    //             "AvoidableED": {
    //                 "all": 10,
    //                 "mine": 10
    //             },
    //             "AWV": {
    //                 "all": 10.03,
    //                 "mine": 10.65
    //             }
    //         }
    //     },
    //     Percent_1: {
    //         "ChrisGarcia": {
    //             "HospitalReadmission": {
    //                 "all": 10,
    //                 "mine": 13.79
    //             },
    //             "SNFReadmission": {
    //                 "all": 10,
    //                 "mine": 3.22
    //             },
    //             "EDAdmission": {
    //                 "all": 10,
    //                 "mine": 12.07
    //             },
    //             "AvoidableED": {
    //                 "all": 10,
    //                 "mine": 12
    //             },
    //             "AWV": {
    //                 "all": 10,
    //                 "mine": 10.74
    //             }
    //         },
    //         "DebbieBrooke": {
    //             "HospitalReadmission": {
    //                 "all": 10,
    //                 "mine": 4.16
    //             },
    //             "SNFReadmission": {
    //                 "all": 10,
    //                 "mine": 3.15
    //             },
    //             "EDAdmission": {
    //                 "all": 10,
    //                 "mine": 14.28
    //             },
    //             "AvoidableED": {
    //                 "all": 10,
    //                 "mine": 11
    //             },
    //             "AWV": {
    //                 "all": 10,
    //                 "mine": 13.33
    //             }
    //         },
    //         "MirzaHuda": {
    //             "HospitalReadmission": {
    //                 "all": 10,
    //                 "mine": 10.41
    //             },
    //             "SNFReadmission": {
    //                 "all": 10,
    //                 "mine": 3.18
    //             },
    //             "EDAdmission": {
    //                 "all": 10,
    //                 "mine": 5.95
    //             },
    //             "AvoidableED": {
    //                 "all": 10,
    //                 "mine": 13
    //             },
    //             "AWV": {
    //                 "all": 10,
    //                 "mine": 11.66
    //             }
    //         },
    //         "ReninaShonda": {
    //             "HospitalReadmission": {
    //                 "all": 10,
    //                 "mine": 8.33
    //             },
    //             "SNFReadmission": {
    //                 "all": 10,
    //                 "mine": 3.21
    //             },
    //             "EDAdmission": {
    //                 "all": 10,
    //                 "mine": 10.71
    //             },
    //             "AvoidableED": {
    //                 "all": 10,
    //                 "mine": 14
    //             },
    //             "AWV": {
    //                 "all": 10,
    //                 "mine": 8.33
    //             }
    //         }
    //     }
    // };

    var metricsConfiguration = {};
    function metricsConfigurationMineAllData(MetricsAllData, MetricsMineData, coordinatorName, selectedPercentage) {
        var metricsAllData = {};
        metricsAllData.all1 = 50;
        metricsAllData.all2 = 45;
        metricsAllData.all3 = 35;
        metricsAllData.all4 = 40;
        metricsAllData.all5 = 43;

        var metricsMineData = {};
        metricsMineData.mine1 = 30;
        metricsMineData.mine2 = 30;
        metricsMineData.mine3 = 20;
        metricsMineData.mine4 = 15;
        metricsMineData.mine5 = 25;

        var metricsData = {
            MetricsAllData: metricsAllData,
            MetricsMineData: metricsMineData
        };//JSON.parse(localStorage.getItem("metricsChartData"));

        MetricsAllData = metricsData.MetricsAllData;
        MetricsMineData = metricsData.MetricsMineData;
        coordinatorName = "Debbie Brook";//localStorage.getItem("selectedCareCoordinator");
        selectedPercentage = 30;//localStorage.getItem("selectedPercentage");

        // console.log(`Mine: ${MetricsMineData.mine1} / ${MetricsMineData.mine2} / ${MetricsMineData.mine3} / ${MetricsMineData.mine4} / ${MetricsMineData.mine5}`);  
        // console.log(`All: ${MetricsAllData.all1} / ${MetricsAllData.all2} / ${MetricsAllData.all3} / ${MetricsAllData.all4} / ${MetricsAllData.all5}`);  

        let metricsConfigurationData = {
            ["Percent_" + selectedPercentage]: {
                [coordinatorName]: {
                    HospitalReadmission: {
                        all: MetricsAllData.all1,
                        mine: MetricsMineData.mine1,
                    },
                    SNFReadmission: {
                        all: MetricsAllData.all2,
                        mine: MetricsMineData.mine2,
                    },
                    EDAdmission: {
                        all: MetricsAllData.all3,
                        mine: MetricsMineData.mine3,
                    },
                    AWV: {
                        all: MetricsAllData.all4,
                        mine: MetricsMineData.mine4,
                    },
                    AvoidableED: {
                        all: MetricsAllData.all5,
                        mine: MetricsMineData.mine5,
                    },
                },
            },
        };
        metricsConfiguration = metricsConfigurationData;
    }

    var metricsConfigurationCCM = {
        Percent_35: {
            "ChrisGarcia": {
                "CurrentLives": {
                    "all": 700,
                    "mine": 600
                },
                "OfficeEnrolled": {
                    "all": 400,
                    "mine": 250
                },
                "OutReached": {
                    "all": 250,
                    "mine": 250
                },
                "Submitted": {
                    "all": 225,
                    "mine": 250
                },
                "AWV": {
                    "all": 100,
                    "mine": 200
                }
            },
            "DebbieBrooke": {
                "CurrentLives": {
                    "all": 700,
                    "mine": 600
                },
                "OfficeEnrolled": {
                    "all": 400,
                    "mine": 250
                },
                "OutReached": {
                    "all": 250,
                    "mine": 250
                },
                "Submitted": {
                    "all": 225,
                    "mine": 250
                },
                "AWV": {
                    "all": 100,
                    "mine": 200
                }
            },
            "MirzaHuda": {
                "CurrentLives": {
                    "all": 700,
                    "mine": 600
                },
                "OfficeEnrolled": {
                    "all": 400,
                    "mine": 250
                },
                "OutReached": {
                    "all": 250,
                    "mine": 250
                },
                "Submitted": {
                    "all": 225,
                    "mine": 250
                },
                "AWV": {
                    "all": 100,
                    "mine": 200
                }
            },
            "ReninaShonda": {
                "CurrentLives": {
                    "all": 700,
                    "mine": 600
                },
                "OfficeEnrolled": {
                    "all": 400,
                    "mine": 250
                },
                "OutReached": {
                    "all": 250,
                    "mine": 250
                },
                "Submitted": {
                    "all": 225,
                    "mine": 250
                },
                "AWV": {
                    "all": 100,
                    "mine": 200
                }
            }
        },
        Percent_15: {
            "ChrisGarcia": {
                "CurrentLives": {
                    "all": 700,
                    "mine": 600
                },
                "OfficeEnrolled": {
                    "all": 400,
                    "mine": 250
                },
                "OutReached": {
                    "all": 250,
                    "mine": 250
                },
                "Submitted": {
                    "all": 225,
                    "mine": 250
                },
                "AWV": {
                    "all": 100,
                    "mine": 200
                }
            },
            "DebbieBrooke": {
                "CurrentLives": {
                    "all": 700,
                    "mine": 600
                },
                "OfficeEnrolled": {
                    "all": 400,
                    "mine": 250
                },
                "OutReached": {
                    "all": 250,
                    "mine": 250
                },
                "Submitted": {
                    "all": 225,
                    "mine": 250
                },
                "AWV": {
                    "all": 100,
                    "mine": 200
                }
            },
            "MirzaHuda": {
                "CurrentLives": {
                    "all": 700,
                    "mine": 600
                },
                "OfficeEnrolled": {
                    "all": 400,
                    "mine": 250
                },
                "OutReached": {
                    "all": 250,
                    "mine": 250
                },
                "Submitted": {
                    "all": 225,
                    "mine": 250
                },
                "AWV": {
                    "all": 100,
                    "mine": 200
                }
            },
            "ReninaShonda": {
                "CurrentLives": {
                    "all": 700,
                    "mine": 600
                },
                "OfficeEnrolled": {
                    "all": 400,
                    "mine": 250
                },
                "OutReached": {
                    "all": 250,
                    "mine": 250
                },
                "Submitted": {
                    "all": 225,
                    "mine": 250
                },
                "AWV": {
                    "all": 100,
                    "mine": 200
                }
            }
        },
        Percent_5: {
            "ChrisGarcia": {
                "CurrentLives": {
                    "all": 700,
                    "mine": 600
                },
                "OfficeEnrolled": {
                    "all": 400,
                    "mine": 250
                },
                "OutReached": {
                    "all": 250,
                    "mine": 250
                },
                "Submitted": {
                    "all": 225,
                    "mine": 250
                },
                "AWV": {
                    "all": 100,
                    "mine": 200
                }
            },
            "DebbieBrooke": {
                "CurrentLives": {
                    "all": 700,
                    "mine": 600
                },
                "OfficeEnrolled": {
                    "all": 400,
                    "mine": 250
                },
                "OutReached": {
                    "all": 250,
                    "mine": 250
                },
                "Submitted": {
                    "all": 225,
                    "mine": 250
                },
                "AWV": {
                    "all": 100,
                    "mine": 200
                }
            },
            "MirzaHuda": {
                "CurrentLives": {
                    "all": 700,
                    "mine": 600
                },
                "OfficeEnrolled": {
                    "all": 400,
                    "mine": 250
                },
                "OutReached": {
                    "all": 250,
                    "mine": 250
                },
                "Submitted": {
                    "all": 225,
                    "mine": 250
                },
                "AWV": {
                    "all": 100,
                    "mine": 200
                }
            },
            "ReninaShonda": {
                "CurrentLives": {
                    "all": 700,
                    "mine": 600
                },
                "OfficeEnrolled": {
                    "all": 400,
                    "mine": 250
                },
                "OutReached": {
                    "all": 250,
                    "mine": 250
                },
                "Submitted": {
                    "all": 225,
                    "mine": 250
                },
                "AWV": {
                    "all": 100,
                    "mine": 200
                }
            }
        },
        Percent_1: {
            "ChrisGarcia": {
                "CurrentLives": {
                    "all": 700,
                    "mine": 600
                },
                "OfficeEnrolled": {
                    "all": 400,
                    "mine": 250
                },
                "OutReached": {
                    "all": 250,
                    "mine": 250
                },
                "Submitted": {
                    "all": 225,
                    "mine": 250
                },
                "AWV": {
                    "all": 100,
                    "mine": 200
                }
            },
            "DebbieBrooke": {
                "CurrentLives": {
                    "all": 700,
                    "mine": 600
                },
                "OfficeEnrolled": {
                    "all": 400,
                    "mine": 250
                },
                "OutReached": {
                    "all": 250,
                    "mine": 250
                },
                "Submitted": {
                    "all": 225,
                    "mine": 250
                },
                "AWV": {
                    "all": 100,
                    "mine": 200
                }
            },
            "MirzaHuda": {
                "CurrentLives": {
                    "all": 700,
                    "mine": 600
                },
                "OfficeEnrolled": {
                    "all": 400,
                    "mine": 250
                },
                "OutReached": {
                    "all": 250,
                    "mine": 250
                },
                "Submitted": {
                    "all": 225,
                    "mine": 250
                },
                "AWV": {
                    "all": 100,
                    "mine": 200
                }
            },
            "ReninaShonda": {
                "CurrentLives": {
                    "all": 700,
                    "mine": 600
                },
                "OfficeEnrolled": {
                    "all": 400,
                    "mine": 250
                },
                "OutReached": {
                    "all": 250,
                    "mine": 250
                },
                "Submitted": {
                    "all": 225,
                    "mine": 250
                },
                "AWV": {
                    "all": 100,
                    "mine": 200
                }
            }
        }
    };

    var metricsConfigurationQM = {
        Percent_35: {
            "ChrisGarcia": {
                "CurrentLives": {
                    "all": 700,
                    "mine": 600
                },
                "OutReached": {
                    "all": 700,
                    "mine": 600
                },
                "Reached": {
                    "all": 400,
                    "mine": 300
                },
                "Engaged": {
                    "all": 300,
                    "mine": 250
                },
                "Ineligibles": {
                    "all": 0,
                    "mine": 0
                }
            },
            "DebbieBrooke": {
                "CurrentLives": {
                    "all": 700,
                    "mine": 600
                },
                "OutReached": {
                    "all": 700,
                    "mine": 600
                },
                "Reached": {
                    "all": 400,
                    "mine": 300
                },
                "Engaged": {
                    "all": 300,
                    "mine": 250
                },
                "Ineligibles": {
                    "all": 0,
                    "mine": 0
                }
            },
            "MirzaHuda": {
                "CurrentLives": {
                    "all": 700,
                    "mine": 600
                },
                "OutReached": {
                    "all": 700,
                    "mine": 600
                },
                "Reached": {
                    "all": 400,
                    "mine": 300
                },
                "Engaged": {
                    "all": 300,
                    "mine": 250
                },
                "Ineligibles": {
                    "all": 0,
                    "mine": 0
                }
            },
            "ReninaShonda": {
                "CurrentLives": {
                    "all": 700,
                    "mine": 600
                },
                "OutReached": {
                    "all": 700,
                    "mine": 600
                },
                "Reached": {
                    "all": 400,
                    "mine": 300
                },
                "Engaged": {
                    "all": 300,
                    "mine": 250
                },
                "Ineligibles": {
                    "all": 0,
                    "mine": 0
                }
            }
        },
        Percent_15: {
            "ChrisGarcia": {
                "CurrentLives": {
                    "all": 400,
                    "mine": 300
                },
                "OutReached": {
                    "all": 300,
                    "mine": 200
                },
                "Reached": {
                    "all": 150,
                    "mine": 100
                },
                "Engaged": {
                    "all": 125,
                    "mine": 100
                },
                "Ineligibles": {
                    "all": 0,
                    "mine": 0
                }
            },
            "DebbieBrooke": {
                "CurrentLives": {
                    "all": 400,
                    "mine": 300
                },
                "OutReached": {
                    "all": 300,
                    "mine": 200
                },
                "Reached": {
                    "all": 150,
                    "mine": 100
                },
                "Engaged": {
                    "all": 125,
                    "mine": 100
                },
                "Ineligibles": {
                    "all": 0,
                    "mine": 0
                }
            },
            "MirzaHuda": {
                "CurrentLives": {
                    "all": 400,
                    "mine": 300
                },
                "OutReached": {
                    "all": 300,
                    "mine": 200
                },
                "Reached": {
                    "all": 150,
                    "mine": 100
                },
                "Engaged": {
                    "all": 125,
                    "mine": 100
                },
                "Ineligibles": {
                    "all": 0,
                    "mine": 0
                }
            },
            "ReninaShonda": {
                "CurrentLives": {
                    "all": 400,
                    "mine": 300
                },
                "OutReached": {
                    "all": 300,
                    "mine": 200
                },
                "Reached": {
                    "all": 150,
                    "mine": 100
                },
                "Engaged": {
                    "all": 125,
                    "mine": 100
                },
                "Ineligibles": {
                    "all": 0,
                    "mine": 0
                }
            }
        },
        Percent_5: {
            "ChrisGarcia": {
                "CurrentLives": {
                    "all": 200,
                    "mine": 175
                },
                "OutReached": {
                    "all": 150,
                    "mine": 100
                },
                "Reached": {
                    "all": 50,
                    "mine": 25
                },
                "Engaged": {
                    "all": 175,
                    "mine": 125
                },
                "Ineligibles": {
                    "all": 0,
                    "mine": 0
                }
            },
            "DebbieBrooke": {
                "CurrentLives": {
                    "all": 200,
                    "mine": 175
                },
                "OutReached": {
                    "all": 150,
                    "mine": 100
                },
                "Reached": {
                    "all": 50,
                    "mine": 25
                },
                "Engaged": {
                    "all": 175,
                    "mine": 125
                },
                "Ineligibles": {
                    "all": 0,
                    "mine": 0
                }
            },
            "MirzaHuda": {
                "CurrentLives": {
                    "all": 200,
                    "mine": 175
                },
                "OutReached": {
                    "all": 150,
                    "mine": 100
                },
                "Reached": {
                    "all": 50,
                    "mine": 25
                },
                "Engaged": {
                    "all": 175,
                    "mine": 125
                },
                "Ineligibles": {
                    "all": 0,
                    "mine": 0
                }
            },
            "ReninaShonda": {
                "CurrentLives": {
                    "all": 200,
                    "mine": 175
                },
                "OutReached": {
                    "all": 150,
                    "mine": 100
                },
                "Reached": {
                    "all": 50,
                    "mine": 25
                },
                "Engaged": {
                    "all": 175,
                    "mine": 125
                },
                "Ineligibles": {
                    "all": 0,
                    "mine": 0
                }
            }
        },
        Percent_1: {
            "ChrisGarcia": {
                "CurrentLives": {
                    "all": 100,
                    "mine": 25
                },
                "OutReached": {
                    "all": 75,
                    "mine": 25
                },
                "Reached": {
                    "all": 25,
                    "mine": 10
                },
                "Engaged": {
                    "all": 75,
                    "mine": 50
                },
                "Ineligibles": {
                    "all": 0,
                    "mine": 0
                }
            },
            "DebbieBrooke": {
                "CurrentLives": {
                    "all": 100,
                    "mine": 25
                },
                "OutReached": {
                    "all": 75,
                    "mine": 25
                },
                "Reached": {
                    "all": 25,
                    "mine": 10
                },
                "Engaged": {
                    "all": 75,
                    "mine": 50
                },
                "Ineligibles": {
                    "all": 0,
                    "mine": 0
                }
            },
            "MirzaHuda": {
                "CurrentLives": {
                    "all": 100,
                    "mine": 25
                },
                "OutReached": {
                    "all": 75,
                    "mine": 25
                },
                "Reached": {
                    "all": 25,
                    "mine": 10
                },
                "Engaged": {
                    "all": 75,
                    "mine": 50
                },
                "Ineligibles": {
                    "all": 0,
                    "mine": 0
                }
            },
            "ReninaShonda": {
                "CurrentLives": {
                    "all": 100,
                    "mine": 25
                },
                "OutReached": {
                    "all": 75,
                    "mine": 25
                },
                "Reached": {
                    "all": 25,
                    "mine": 10
                },
                "Engaged": {
                    "all": 75,
                    "mine": 50
                },
                "Ineligibles": {
                    "all": 0,
                    "mine": 0
                }
            }
        },
        Colonoscopy_Percent_35: {
            "ChrisGarcia": {
                "CurrentLives": {
                    "all": 550,
                    "mine": 450
                },
                "OutReached": {
                    "all": 250,
                    "mine": 200
                },
                "Reached": {
                    "all": 125,
                    "mine": 100
                },
                "Engaged": {
                    "all": 250,
                    "mine": 225
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "DebbieBrooke": {
                "CurrentLives": {
                    "all": 550,
                    "mine": 450
                },
                "OutReached": {
                    "all": 250,
                    "mine": 200
                },
                "Reached": {
                    "all": 125,
                    "mine": 100
                },
                "Engaged": {
                    "all": 250,
                    "mine": 225
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "MirzaHuda": {
                "CurrentLives": {
                    "all": 550,
                    "mine": 450
                },
                "OutReached": {
                    "all": 250,
                    "mine": 200
                },
                "Reached": {
                    "all": 125,
                    "mine": 100
                },
                "Engaged": {
                    "all": 250,
                    "mine": 225
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "ReninaShonda": {
                "CurrentLives": {
                    "all": 550,
                    "mine": 450
                },
                "OutReached": {
                    "all": 250,
                    "mine": 200
                },
                "Reached": {
                    "all": 125,
                    "mine": 100
                },
                "Engaged": {
                    "all": 250,
                    "mine": 225
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            }
        },
        Colonoscopy_Percent_15: {
            "ChrisGarcia": {
                "CurrentLives": {
                    "all": 425,
                    "mine": 325
                },
                "OutReached": {
                    "all": 350,
                    "mine": 250
                },
                "Reached": {
                    "all": 200,
                    "mine": 150
                },
                "Engaged": {
                    "all": 125,
                    "mine": 100
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "DebbieBrooke": {
                "CurrentLives": {
                    "all": 425,
                    "mine": 325
                },
                "OutReached": {
                    "all": 350,
                    "mine": 250
                },
                "Reached": {
                    "all": 200,
                    "mine": 150
                },
                "Engaged": {
                    "all": 125,
                    "mine": 100
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "MirzaHuda": {
                "CurrentLives": {
                    "all": 425,
                    "mine": 325
                },
                "OutReached": {
                    "all": 350,
                    "mine": 250
                },
                "Reached": {
                    "all": 200,
                    "mine": 150
                },
                "Engaged": {
                    "all": 125,
                    "mine": 100
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "ReninaShonda": {
                "CurrentLives": {
                    "all": 425,
                    "mine": 325
                },
                "OutReached": {
                    "all": 350,
                    "mine": 250
                },
                "Reached": {
                    "all": 200,
                    "mine": 150
                },
                "Engaged": {
                    "all": 125,
                    "mine": 100
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            }
        },
        Colonoscopy_Percent_5: {
            "ChrisGarcia": {
                "CurrentLives": {
                    "all": 150,
                    "mine": 125
                },
                "OutReached": {
                    "all": 125,
                    "mine": 100
                },
                "Reached": {
                    "all": 75,
                    "mine": 25
                },
                "Engaged": {
                    "all": 150,
                    "mine": 125
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "DebbieBrooke": {
                "CurrentLives": {
                    "all": 150,
                    "mine": 125
                },
                "OutReached": {
                    "all": 125,
                    "mine": 100
                },
                "Reached": {
                    "all": 75,
                    "mine": 25
                },
                "Engaged": {
                    "all": 150,
                    "mine": 125
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "MirzaHuda": {
                "CurrentLives": {
                    "all": 150,
                    "mine": 125
                },
                "OutReached": {
                    "all": 125,
                    "mine": 100
                },
                "Reached": {
                    "all": 75,
                    "mine": 25
                },
                "Engaged": {
                    "all": 150,
                    "mine": 125
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "ReninaShonda": {
                "CurrentLives": {
                    "all": 150,
                    "mine": 125
                },
                "OutReached": {
                    "all": 125,
                    "mine": 100
                },
                "Reached": {
                    "all": 75,
                    "mine": 25
                },
                "Engaged": {
                    "all": 150,
                    "mine": 125
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            }
        },
        Colonoscopy_Percent_1: {
            "ChrisGarcia": {
                "CurrentLives": {
                    "all": 50,
                    "mine": 25
                },
                "OutReached": {
                    "all": 100,
                    "mine": 25
                },
                "Reached": {
                    "all": 125,
                    "mine": 25
                },
                "Engaged": {
                    "all": 50,
                    "mine": 50
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "DebbieBrooke": {
                "CurrentLives": {
                    "all": 50,
                    "mine": 25
                },
                "OutReached": {
                    "all": 100,
                    "mine": 25
                },
                "Reached": {
                    "all": 125,
                    "mine": 25
                },
                "Engaged": {
                    "all": 50,
                    "mine": 50
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "MirzaHuda": {
                "CurrentLives": {
                    "all": 50,
                    "mine": 25
                },
                "OutReached": {
                    "all": 100,
                    "mine": 25
                },
                "Reached": {
                    "all": 125,
                    "mine": 25
                },
                "Engaged": {
                    "all": 50,
                    "mine": 50
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "ReninaShonda": {
                "CurrentLives": {
                    "all": 50,
                    "mine": 25
                },
                "OutReached": {
                    "all": 100,
                    "mine": 25
                },
                "Reached": {
                    "all": 125,
                    "mine": 25
                },
                "Engaged": {
                    "all": 50,
                    "mine": 50
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            }
        },
        Mammogram_Percent_35: {
            "ChrisGarcia": {
                "CurrentLives": {
                    "all": 750,
                    "mine": 650
                },
                "OutReached": {
                    "all": 550,
                    "mine": 400
                },
                "Reached": {
                    "all": 300,
                    "mine": 200
                },
                "Engaged": {
                    "all": 475,
                    "mine": 275
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "DebbieBrooke": {
                "CurrentLives": {
                    "all": 750,
                    "mine": 650
                },
                "OutReached": {
                    "all": 550,
                    "mine": 400
                },
                "Reached": {
                    "all": 300,
                    "mine": 200
                },
                "Engaged": {
                    "all": 475,
                    "mine": 275
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "MirzaHuda": {
                "CurrentLives": {
                    "all": 750,
                    "mine": 650
                },
                "OutReached": {
                    "all": 550,
                    "mine": 400
                },
                "Reached": {
                    "all": 300,
                    "mine": 200
                },
                "Engaged": {
                    "all": 475,
                    "mine": 275
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "ReninaShonda": {
                "CurrentLives": {
                    "all": 750,
                    "mine": 650
                },
                "OutReached": {
                    "all": 550,
                    "mine": 400
                },
                "Reached": {
                    "all": 300,
                    "mine": 200
                },
                "Engaged": {
                    "all": 475,
                    "mine": 275
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            }
        },
        Mammogram_Percent_15: {
            "ChrisGarcia": {
                "CurrentLives": {
                    "all": 550,
                    "mine": 450
                },
                "OutReached": {
                    "all": 500,
                    "mine": 475
                },
                "Reached": {
                    "all": 250,
                    "mine": 150
                },
                "Engaged": {
                    "all": 275,
                    "mine": 200
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "DebbieBrooke": {
                "CurrentLives": {
                    "all": 550,
                    "mine": 450
                },
                "OutReached": {
                    "all": 500,
                    "mine": 475
                },
                "Reached": {
                    "all": 250,
                    "mine": 150
                },
                "Engaged": {
                    "all": 275,
                    "mine": 200
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "MirzaHuda": {
                "CurrentLives": {
                    "all": 550,
                    "mine": 450
                },
                "OutReached": {
                    "all": 500,
                    "mine": 475
                },
                "Reached": {
                    "all": 250,
                    "mine": 150
                },
                "Engaged": {
                    "all": 275,
                    "mine": 200
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "ReninaShonda": {
                "CurrentLives": {
                    "all": 550,
                    "mine": 450
                },
                "OutReached": {
                    "all": 500,
                    "mine": 475
                },
                "Reached": {
                    "all": 250,
                    "mine": 150
                },
                "Engaged": {
                    "all": 275,
                    "mine": 200
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            }
        },
        Mammogram_Percent_5: {
            "ChrisGarcia": {
                "CurrentLives": {
                    "all": 300,
                    "mine": 250
                },
                "OutReached": {
                    "all": 250,
                    "mine": 225
                },
                "Reached": {
                    "all": 25,
                    "mine": 10
                },
                "Engaged": {
                    "all": 200,
                    "mine": 100
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "DebbieBrooke": {
                "CurrentLives": {
                    "all": 300,
                    "mine": 250
                },
                "OutReached": {
                    "all": 250,
                    "mine": 225
                },
                "Reached": {
                    "all": 25,
                    "mine": 10
                },
                "Engaged": {
                    "all": 200,
                    "mine": 100
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "MirzaHuda": {
                "CurrentLives": {
                    "all": 300,
                    "mine": 250
                },
                "OutReached": {
                    "all": 250,
                    "mine": 225
                },
                "Reached": {
                    "all": 25,
                    "mine": 10
                },
                "Engaged": {
                    "all": 200,
                    "mine": 100
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "ReninaShonda": {
                "CurrentLives": {
                    "all": 300,
                    "mine": 250
                },
                "OutReached": {
                    "all": 250,
                    "mine": 225
                },
                "Reached": {
                    "all": 25,
                    "mine": 10
                },
                "Engaged": {
                    "all": 200,
                    "mine": 100
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            }
        },
        Mammogram_Percent_1: {
            "ChrisGarcia": {
                "CurrentLives": {
                    "all": 200,
                    "mine": 175
                },
                "OutReached": {
                    "all": 175,
                    "mine": 150
                },
                "Reached": {
                    "all": 250,
                    "mine": 100
                },
                "Engaged": {
                    "all": 200,
                    "mine": 50
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "DebbieBrooke": {
                "CurrentLives": {
                    "all": 200,
                    "mine": 175
                },
                "OutReached": {
                    "all": 175,
                    "mine": 150
                },
                "Reached": {
                    "all": 250,
                    "mine": 100
                },
                "Engaged": {
                    "all": 200,
                    "mine": 50
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "MirzaHuda": {
                "CurrentLives": {
                    "all": 200,
                    "mine": 175
                },
                "OutReached": {
                    "all": 175,
                    "mine": 150
                },
                "Reached": {
                    "all": 250,
                    "mine": 100
                },
                "Engaged": {
                    "all": 200,
                    "mine": 50
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "ReninaShonda": {
                "CurrentLives": {
                    "all": 200,
                    "mine": 175
                },
                "OutReached": {
                    "all": 175,
                    "mine": 150
                },
                "Reached": {
                    "all": 250,
                    "mine": 100
                },
                "Engaged": {
                    "all": 200,
                    "mine": 50
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            }
        },
        HbA1c_Percent_35: {
            "ChrisGarcia": {
                "CurrentLives": {
                    "all": 850,
                    "mine": 750
                },
                "OutReached": {
                    "all": 800,
                    "mine": 700
                },
                "Reached": {
                    "all": 750,
                    "mine": 650
                },
                "Engaged": {
                    "all": 650,
                    "mine": 550
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "DebbieBrooke": {
                "CurrentLives": {
                    "all": 850,
                    "mine": 750
                },
                "OutReached": {
                    "all": 800,
                    "mine": 700
                },
                "Reached": {
                    "all": 750,
                    "mine": 650
                },
                "Engaged": {
                    "all": 650,
                    "mine": 550
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "MirzaHuda": {
                "CurrentLives": {
                    "all": 850,
                    "mine": 750
                },
                "OutReached": {
                    "all": 800,
                    "mine": 700
                },
                "Reached": {
                    "all": 750,
                    "mine": 650
                },
                "Engaged": {
                    "all": 650,
                    "mine": 550
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "ReninaShonda": {
                "CurrentLives": {
                    "all": 850,
                    "mine": 750
                },
                "OutReached": {
                    "all": 800,
                    "mine": 700
                },
                "Reached": {
                    "all": 750,
                    "mine": 650
                },
                "Engaged": {
                    "all": 650,
                    "mine": 550
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            }
        },
        HbA1c_Percent_15: {
            "ChrisGarcia": {
                "CurrentLives": {
                    "all": 650,
                    "mine": 600
                },
                "OutReached": {
                    "all": 550,
                    "mine": 500
                },
                "Reached": {
                    "all": 450,
                    "mine": 350
                },
                "Engaged": {
                    "all": 350,
                    "mine": 250
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "DebbieBrooke": {
                "CurrentLives": {
                    "all": 650,
                    "mine": 600
                },
                "OutReached": {
                    "all": 550,
                    "mine": 500
                },
                "Reached": {
                    "all": 450,
                    "mine": 350
                },
                "Engaged": {
                    "all": 350,
                    "mine": 250
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "MirzaHuda": {
                "CurrentLives": {
                    "all": 650,
                    "mine": 600
                },
                "OutReached": {
                    "all": 550,
                    "mine": 500
                },
                "Reached": {
                    "all": 450,
                    "mine": 350
                },
                "Engaged": {
                    "all": 350,
                    "mine": 250
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "ReninaShonda": {
                "CurrentLives": {
                    "all": 650,
                    "mine": 600
                },
                "OutReached": {
                    "all": 550,
                    "mine": 500
                },
                "Reached": {
                    "all": 450,
                    "mine": 350
                },
                "Engaged": {
                    "all": 350,
                    "mine": 250
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            }
        },
        HbA1c_Percent_5: {
            "ChrisGarcia": {
                "CurrentLives": {
                    "all": 550,
                    "mine": 450
                },
                "OutReached": {
                    "all": 450,
                    "mine": 350
                },
                "Reached": {
                    "all": 350,
                    "mine": 250
                },
                "Engaged": {
                    "all": 250,
                    "mine": 150
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "DebbieBrooke": {
                "CurrentLives": {
                    "all": 550,
                    "mine": 450
                },
                "OutReached": {
                    "all": 450,
                    "mine": 350
                },
                "Reached": {
                    "all": 350,
                    "mine": 250
                },
                "Engaged": {
                    "all": 250,
                    "mine": 150
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "MirzaHuda": {
                "CurrentLives": {
                    "all": 550,
                    "mine": 450
                },
                "OutReached": {
                    "all": 450,
                    "mine": 350
                },
                "Reached": {
                    "all": 350,
                    "mine": 250
                },
                "Engaged": {
                    "all": 250,
                    "mine": 150
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "ReninaShonda": {
                "CurrentLives": {
                    "all": 550,
                    "mine": 450
                },
                "OutReached": {
                    "all": 450,
                    "mine": 350
                },
                "Reached": {
                    "all": 350,
                    "mine": 250
                },
                "Engaged": {
                    "all": 250,
                    "mine": 150
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            }
        },
        HbA1c_Percent_1: {
            "ChrisGarcia": {
                "CurrentLives": {
                    "all": 350,
                    "mine": 250
                },
                "OutReached": {
                    "all": 250,
                    "mine": 150
                },
                "Reached": {
                    "all": 150,
                    "mine": 75
                },
                "Engaged": {
                    "all": 100,
                    "mine": 50
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "DebbieBrooke": {
                "CurrentLives": {
                    "all": 350,
                    "mine": 250
                },
                "OutReached": {
                    "all": 250,
                    "mine": 150
                },
                "Reached": {
                    "all": 150,
                    "mine": 75
                },
                "Engaged": {
                    "all": 100,
                    "mine": 50
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "MirzaHuda": {
                "CurrentLives": {
                    "all": 350,
                    "mine": 250
                },
                "OutReached": {
                    "all": 250,
                    "mine": 150
                },
                "Reached": {
                    "all": 150,
                    "mine": 75
                },
                "Engaged": {
                    "all": 100,
                    "mine": 50
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "ReninaShonda": {
                "CurrentLives": {
                    "all": 350,
                    "mine": 250
                },
                "OutReached": {
                    "all": 250,
                    "mine": 150
                },
                "Reached": {
                    "all": 150,
                    "mine": 75
                },
                "Engaged": {
                    "all": 100,
                    "mine": 50
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            }
        },
        Flu_Percent_35: {
            "ChrisGarcia": {
                "CurrentLives": {
                    "all": 950,
                    "mine": 850
                },
                "OutReached": {
                    "all": 900,
                    "mine": 800
                },
                "Reached": {
                    "all": 800,
                    "mine": 700
                },
                "Engaged": {
                    "all": 700,
                    "mine": 600
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "DebbieBrooke": {
                "CurrentLives": {
                    "all": 950,
                    "mine": 850
                },
                "OutReached": {
                    "all": 900,
                    "mine": 800
                },
                "Reached": {
                    "all": 800,
                    "mine": 700
                },
                "Engaged": {
                    "all": 700,
                    "mine": 600
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "MirzaHuda": {
                "CurrentLives": {
                    "all": 950,
                    "mine": 850
                },
                "OutReached": {
                    "all": 900,
                    "mine": 800
                },
                "Reached": {
                    "all": 800,
                    "mine": 700
                },
                "Engaged": {
                    "all": 700,
                    "mine": 600
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "ReninaShonda": {
                "CurrentLives": {
                    "all": 950,
                    "mine": 850
                },
                "OutReached": {
                    "all": 900,
                    "mine": 800
                },
                "Reached": {
                    "all": 800,
                    "mine": 700
                },
                "Engaged": {
                    "all": 700,
                    "mine": 600
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            }
        },
        Flu_Percent_15: {
            "ChrisGarcia": {
                "CurrentLives": {
                    "all": 850,
                    "mine": 750
                },
                "OutReached": {
                    "all": 750,
                    "mine": 650
                },
                "Reached": {
                    "all": 650,
                    "mine": 550
                },
                "Engaged": {
                    "all": 550,
                    "mine": 450
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "DebbieBrooke": {
                "CurrentLives": {
                    "all": 850,
                    "mine": 750
                },
                "OutReached": {
                    "all": 750,
                    "mine": 650
                },
                "Reached": {
                    "all": 650,
                    "mine": 550
                },
                "Engaged": {
                    "all": 550,
                    "mine": 450
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "MirzaHuda": {
                "CurrentLives": {
                    "all": 850,
                    "mine": 750
                },
                "OutReached": {
                    "all": 750,
                    "mine": 650
                },
                "Reached": {
                    "all": 650,
                    "mine": 550
                },
                "Engaged": {
                    "all": 550,
                    "mine": 450
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "ReninaShonda": {
                "CurrentLives": {
                    "all": 850,
                    "mine": 750
                },
                "OutReached": {
                    "all": 750,
                    "mine": 650
                },
                "Reached": {
                    "all": 650,
                    "mine": 550
                },
                "Engaged": {
                    "all": 550,
                    "mine": 450
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            }
        },
        Flu_Percent_5: {
            "ChrisGarcia": {
                "CurrentLives": {
                    "all": 600,
                    "mine": 500
                },
                "OutReached": {
                    "all": 500,
                    "mine": 400
                },
                "Reached": {
                    "all": 400,
                    "mine": 300
                },
                "Engaged": {
                    "all": 300,
                    "mine": 200
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "DebbieBrooke": {
                "CurrentLives": {
                    "all": 600,
                    "mine": 500
                },
                "OutReached": {
                    "all": 500,
                    "mine": 400
                },
                "Reached": {
                    "all": 400,
                    "mine": 300
                },
                "Engaged": {
                    "all": 300,
                    "mine": 200
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "MirzaHuda": {
                "CurrentLives": {
                    "all": 600,
                    "mine": 500
                },
                "OutReached": {
                    "all": 500,
                    "mine": 400
                },
                "Reached": {
                    "all": 400,
                    "mine": 300
                },
                "Engaged": {
                    "all": 300,
                    "mine": 200
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "ReninaShonda": {
                "CurrentLives": {
                    "all": 600,
                    "mine": 500
                },
                "OutReached": {
                    "all": 500,
                    "mine": 400
                },
                "Reached": {
                    "all": 400,
                    "mine": 300
                },
                "Engaged": {
                    "all": 300,
                    "mine": 200
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            }
        },
        Flu_Percent_1: {
            "ChrisGarcia": {
                "CurrentLives": {
                    "all": 400,
                    "mine": 350
                },
                "OutReached": {
                    "all": 350,
                    "mine": 300
                },
                "Reached": {
                    "all": 300,
                    "mine": 250
                },
                "Engaged": {
                    "all": 250,
                    "mine": 200
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "DebbieBrooke": {
                "CurrentLives": {
                    "all": 400,
                    "mine": 350
                },
                "OutReached": {
                    "all": 350,
                    "mine": 300
                },
                "Reached": {
                    "all": 300,
                    "mine": 250
                },
                "Engaged": {
                    "all": 250,
                    "mine": 200
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "MirzaHuda": {
                "CurrentLives": {
                    "all": 400,
                    "mine": 350
                },
                "OutReached": {
                    "all": 350,
                    "mine": 300
                },
                "Reached": {
                    "all": 300,
                    "mine": 250
                },
                "Engaged": {
                    "all": 250,
                    "mine": 200
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "ReninaShonda": {
                "CurrentLives": {
                    "all": 400,
                    "mine": 350
                },
                "OutReached": {
                    "all": 350,
                    "mine": 300
                },
                "Reached": {
                    "all": 300,
                    "mine": 250
                },
                "Engaged": {
                    "all": 250,
                    "mine": 200
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            }
        },
        BP140_Percent_35: {
            "ChrisGarcia": {
                "CurrentLives": {
                    "all": 950,
                    "mine": 900
                },
                "OutReached": {
                    "all": 850,
                    "mine": 750
                },
                "Reached": {
                    "all": 650,
                    "mine": 550
                },
                "Engaged": {
                    "all": 550,
                    "mine": 450
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "DebbieBrooke": {
                "CurrentLives": {
                    "all": 950,
                    "mine": 900
                },
                "OutReached": {
                    "all": 850,
                    "mine": 750
                },
                "Reached": {
                    "all": 650,
                    "mine": 550
                },
                "Engaged": {
                    "all": 550,
                    "mine": 450
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "MirzaHuda": {
                "CurrentLives": {
                    "all": 950,
                    "mine": 900
                },
                "OutReached": {
                    "all": 850,
                    "mine": 750
                },
                "Reached": {
                    "all": 650,
                    "mine": 550
                },
                "Engaged": {
                    "all": 550,
                    "mine": 450
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "ReninaShonda": {
                "CurrentLives": {
                    "all": 950,
                    "mine": 900
                },
                "OutReached": {
                    "all": 850,
                    "mine": 750
                },
                "Reached": {
                    "all": 650,
                    "mine": 550
                },
                "Engaged": {
                    "all": 550,
                    "mine": 450
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            }
        },
        BP140_Percent_15: {
            "ChrisGarcia": {
                "CurrentLives": {
                    "all": 800,
                    "mine": 700
                },
                "OutReached": {
                    "all": 700,
                    "mine": 600
                },
                "Reached": {
                    "all": 600,
                    "mine": 500
                },
                "Engaged": {
                    "all": 500,
                    "mine": 400
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "DebbieBrooke": {
                "CurrentLives": {
                    "all": 800,
                    "mine": 700
                },
                "OutReached": {
                    "all": 700,
                    "mine": 600
                },
                "Reached": {
                    "all": 600,
                    "mine": 500
                },
                "Engaged": {
                    "all": 500,
                    "mine": 400
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "MirzaHuda": {
                "CurrentLives": {
                    "all": 800,
                    "mine": 700
                },
                "OutReached": {
                    "all": 700,
                    "mine": 600
                },
                "Reached": {
                    "all": 600,
                    "mine": 500
                },
                "Engaged": {
                    "all": 500,
                    "mine": 400
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "ReninaShonda": {
                "CurrentLives": {
                    "all": 800,
                    "mine": 700
                },
                "OutReached": {
                    "all": 700,
                    "mine": 600
                },
                "Reached": {
                    "all": 600,
                    "mine": 500
                },
                "Engaged": {
                    "all": 500,
                    "mine": 400
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            }
        },
        BP140_Percent_5: {
            "ChrisGarcia": {
                "CurrentLives": {
                    "all": 500,
                    "mine": 450
                },
                "OutReached": {
                    "all": 450,
                    "mine": 400
                },
                "Reached": {
                    "all": 350,
                    "mine": 300
                },
                "Engaged": {
                    "all": 250,
                    "mine": 200
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "DebbieBrooke": {
                "CurrentLives": {
                    "all": 500,
                    "mine": 450
                },
                "OutReached": {
                    "all": 450,
                    "mine": 400
                },
                "Reached": {
                    "all": 350,
                    "mine": 300
                },
                "Engaged": {
                    "all": 250,
                    "mine": 200
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "MirzaHuda": {
                "CurrentLives": {
                    "all": 500,
                    "mine": 450
                },
                "OutReached": {
                    "all": 450,
                    "mine": 400
                },
                "Reached": {
                    "all": 350,
                    "mine": 300
                },
                "Engaged": {
                    "all": 250,
                    "mine": 200
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "ReninaShonda": {
                "CurrentLives": {
                    "all": 500,
                    "mine": 450
                },
                "OutReached": {
                    "all": 450,
                    "mine": 400
                },
                "Reached": {
                    "all": 350,
                    "mine": 300
                },
                "Engaged": {
                    "all": 250,
                    "mine": 200
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            }
        },
        BP140_Percent_1: {
            "ChrisGarcia": {
                "CurrentLives": {
                    "all": 400,
                    "mine": 350
                },
                "OutReached": {
                    "all": 300,
                    "mine": 250
                },
                "Reached": {
                    "all": 150,
                    "mine": 100
                },
                "Engaged": {
                    "all": 100,
                    "mine": 50
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "DebbieBrooke": {
                "CurrentLives": {
                    "all": 400,
                    "mine": 350
                },
                "OutReached": {
                    "all": 300,
                    "mine": 250
                },
                "Reached": {
                    "all": 150,
                    "mine": 100
                },
                "Engaged": {
                    "all": 100,
                    "mine": 50
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "MirzaHuda": {
                "CurrentLives": {
                    "all": 400,
                    "mine": 350
                },
                "OutReached": {
                    "all": 300,
                    "mine": 250
                },
                "Reached": {
                    "all": 150,
                    "mine": 100
                },
                "Engaged": {
                    "all": 100,
                    "mine": 50
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            },
            "ReninaShonda": {
                "CurrentLives": {
                    "all": 400,
                    "mine": 350
                },
                "OutReached": {
                    "all": 300,
                    "mine": 250
                },
                "Reached": {
                    "all": 150,
                    "mine": 100
                },
                "Engaged": {
                    "all": 100,
                    "mine": 50
                },
                "Ineligibles": {
                    "all": 4.9,
                    "mine": 3.5
                }
            }
        }
    };

    function getMetricValue(careCoordinator, percentage, metricType, mineOrAll) {
        if (metricsConfiguration["Percent_" + percentage][careCoordinator]) {
            return +metricsConfiguration["Percent_" + percentage][careCoordinator][metricType][mineOrAll];
        }
        else {
            return +metricsConfiguration["Percent_" + percentage][defaultCareCoordForNonMetricsCoordinators][metricType][mineOrAll];
        }
    }

    function getMetricValueCCM(careCoordinator, percentage, metricType, mineOrAll) {
        if (metricsConfigurationCCM["Percent_" + percentage][careCoordinator]) {
            return +metricsConfigurationCCM["Percent_" + percentage][careCoordinator][metricType][mineOrAll];
        }
        else {
            return +metricsConfigurationCCM["Percent_" + percentage][defaultCareCoordForNonMetricsCoordinators][metricType][mineOrAll];
        }
    }

    function getMetricValueQM(careCoordinator, percentage, metricType, mineOrAll) {
        // debugger;
        if (metricsConfigurationQM["Percent_" + percentage][careCoordinator]) {
            var colonoscopy = metricsConfigurationQM["Colonoscopy_Percent_" + percentage][careCoordinator][metricType][mineOrAll];

            var mamo = metricsConfigurationQM["Mammogram_Percent_" + percentage][careCoordinator][metricType][mineOrAll];

            var hba1c = metricsConfigurationQM["HbA1c_Percent_" + percentage][careCoordinator][metricType][mineOrAll];

            var flu = metricsConfigurationQM["Flu_Percent_" + percentage][careCoordinator][metricType][mineOrAll];

            var BP140 = metricsConfigurationQM["BP140_Percent_" + percentage][careCoordinator][metricType][mineOrAll];

            var cumulativeValues = (colonoscopy + mamo + hba1c + flu + BP140);
            metricsConfigurationQM["Percent_" + percentage][careCoordinator][metricType][mineOrAll] = cumulativeValues;
            return +metricsConfigurationQM["Percent_" + percentage][careCoordinator][metricType][mineOrAll];
        }
        else {
            colonoscopy = metricsConfigurationQM["Colonoscopy_Percent_" + percentage][defaultCareCoordForNonMetricsCoordinators][metricType][mineOrAll];

            var mamo = metricsConfigurationQM["Mammogram_Percent_" + percentage][defaultCareCoordForNonMetricsCoordinators][metricType][mineOrAll];

            var hba1c = metricsConfigurationQM["HbA1c_Percent_" + percentage][defaultCareCoordForNonMetricsCoordinators][metricType][mineOrAll];

            var flu = metricsConfigurationQM["Flu_Percent_" + percentage][defaultCareCoordForNonMetricsCoordinators][metricType][mineOrAll];

            BP140 = metricsConfigurationQM["BP140_Percent_" + percentage][defaultCareCoordForNonMetricsCoordinators][metricType][mineOrAll];

            cumulativeValues = (colonoscopy + mamo + hba1c + flu + BP140);
            metricsConfigurationQM["Percent_" + percentage][defaultCareCoordForNonMetricsCoordinators][metricType][mineOrAll] = cumulativeValues;
            return +metricsConfigurationQM["Percent_" + percentage][defaultCareCoordForNonMetricsCoordinators][metricType][mineOrAll];
        }
    }

    function getMetricValueQM_SubModule(moduleName, careCoordinator, percentage, metricType, mineOrAll) {
        if (metricsConfigurationQM[moduleName + "Percent_" + percentage][careCoordinator]) {
            return +metricsConfigurationQM[moduleName + "Percent_" + percentage][careCoordinator][metricType][mineOrAll];
        }
        else {
            return +metricsConfigurationQM[moduleName + "Percent_" + percentage][defaultCareCoordForNonMetricsCoordinators][metricType][mineOrAll];
        }
    }

    var SampleDatForExport = [
        ["Col1", "Col2", "Col3", "Col4"], // header
        ["Data", 50, 100, 500], // data rows
        ["Data", -100, 20, 100],
    ];


    var exportToCsv = function (inputDataAs2DimArray, fileName) {
        var CsvString = "";
        inputDataAs2DimArray.forEach(function (RowItem, RowIndex) {
            RowItem.forEach(function (ColItem, ColIndex) {
                CsvString += ColItem + ',';
            });
            CsvString += "\r\n";
        });
        CsvString = "data:application/csv," + encodeURIComponent(CsvString);
        var x = document.createElement("A");
        x.setAttribute("href", CsvString);
        x.setAttribute("download", fileName + ".csv");
        document.body.appendChild(x);
        x.click();
    }

    function exportFile() {
        exportToCsv(getSelectedRiskScorePatients(), "Top" + selectedTopRiskScorePercentage + "RiskScorePatients");
    }

    function getSelectedRiskScorePatients() {
        var twoDimArray = [];
        var headerRow = ["Patient Id", "Risk Score", "Care Coordinator Name"];
        twoDimArray.push(headerRow);

        var noOfPatientsToExportPerCareCoordinator = 0;
        noOfPatientsToExportPerCareCoordinator = (selectedTopRiskScorePercentage / 100) * 1000;

        for (var i = 0; i < CareCoordinatorsNew.length; i++) {

            //patientsPerCareCoordinator[CareCoordinators[i].id] = patientsPerCareCoordinator[CareCoordinators[i].id].sort(sortPatientsByRiskScore);
            for (var j = 0; j < noOfPatientsToExportPerCareCoordinator; j++) {
                var patient = patientsPerCareCoordinator[CareCoordinatorsNew[i].id][j];
                var dataRow = [patient["Mbr_ID"], patient["Mbr_Risk_Score"], CareCoordinatorsNew[i].label];
                twoDimArray.push(dataRow);
            }
        }
        return twoDimArray;
    }

    // place at correct location
    d3.select("#closeCareCoordinatorDetailBox").on("click", function () {
        alert("clicked");
        d3.select("#divCareCoordinatorContainer").style("display", "none");
    });

    function createCarePathLegend(carePathSize) {
        var carePathSvg = d3.select("#histogramChart").append("svg").attr("width", carePathSize - 90).attr("height", "60");;

        var colorsArray = ["#f7fbff", "#e3eef9", "#cfe1f2", "#b5d4e9", "#93c3df", "#6daed5", "#4b97c9", "#2f7ebc", "#1864aa", "#0a4a90", "#08306b"];


        var startX = 0, startY = 40;
        var subG = carePathSvg.selectAll(".legendItems")
            .data(legendData).enter()
            .append("g")
            .attr("transform", function (d, i) {
                if (i == 0) {
                    startX = 25
                }
                else {
                    startX = startX + 50;
                }
                return "translate(" + startX + "," + startY + ")";
            });

        subG.append("rect")
            .style("fill", function (d) {
                return d.color;
            })
            .style("width", 50).style("height", 20);

        subG.append("text").text(function (d) {
            return d.text;
        })
            .style("font-size", "12px")
            .style("fill", "white")
            .attr("y", -5);
    }

    function createCicosLegend(circosSVGWidth) {
        $("#heatMapLegend").html(`<button type="button" class="btnLegendClose" onclick="closeCircosLegend()">x</button>`);
        var carePathSvg = d3.select("#heatMapLegend").append("svg").attr("width", circosSVGWidth - 90).attr("height", "60");

        var colorsArray = ["#f7fbff", "#e3eef9", "#cfe1f2", "#b5d4e9", "#93c3df", "#6daed5", "#4b97c9", "#2f7ebc", "#1864aa", "#0a4a90", "#08306b"];


        var startX = 0, startY = 25;
        var subG = carePathSvg.selectAll(".legendItems")
            .data(legendData).enter()
            .append("g")
            .attr("transform", function (d, i) {
                if (i == 0) {
                    startX = 25
                }
                else {
                    startX = startX + 50;
                }
                return "translate(" + startX + "," + startY + ")";
            });

        subG.append("rect")
            .style("fill", function (d) {
                return d.color;
            })
            .style("width", 50).style("height", 20);

        subG.append("text").text(function (d) {
            return d.text;
        })
            .style("font-size", "12px")
            .style("fill", "white")
            .attr("y", -5);
    }

    function showLoader() {
        document.getElementById("loader").classList.remove("d-none");
    }

    function hideLoader() {
        document.getElementById("loader").classList.add("d-none");
    }

    var baseURL = "";
    if (window.location.origin == "http://localhost") {
        // debugger
        baseURL = window.location.origin + "/" + (window.location.pathname.split('/')[1]) + "/"
    }
    else {
        baseURL = window.location.origin;
    }

    function goBack() {
        window.history.back();
    }

    var gieStainColor = {
        gpos100: 'rgb(0,0,0)',
        gpos: 'rgb(0,0,0)',
        gpos75: 'rgb(130,130,130)',
        gpos66: 'rgb(160,160,160)',
        gpos50: 'rgb(200,200,200)',
        gpos33: 'rgb(210,210,210)',
        gpos25: 'rgb(200,200,200)',
        gvar: 'rgb(220,220,220)',
        gneg: 'rgb(255,255,255)',
        acen: 'rgb(217,47,39)',
        stalk: 'rgb(100,127,164)',
        select: 'rgb(135,177,255)'
    }
    var callLogMax = 0;

    metricsConfigurationMineAllData();

    const convertSecToHoursMinSec = (seconds) => {
        // debugger
        let durationInHours = seconds / 3600;
        let durationInMin = (durationInHours % 1) * 60;
        let secRemainingFromMin = (durationInMin % 1) * 60;

        if (Math.trunc(durationInHours) === 1) {
            setTotalDurationInHours(Math.trunc(durationInHours) + " hour ");
        } else if (Math.trunc(durationInHours) > 1) {
            setTotalDurationInHours(Math.trunc(durationInHours) + " hours ");
        } else {
            setTotalDurationInHours("");
        }

        if (Math.trunc(durationInMin) === 1) {
            setTotalDurationInMin(Math.trunc(durationInMin) + " minute ");
        } else if (Math.trunc(durationInMin) > 1) {
            setTotalDurationInMin(Math.trunc(durationInMin) + " minutes ");
        } else {
            setTotalDurationInMin("");
        }

        if (Math.trunc(secRemainingFromMin) === 1) {
            setSecRemainingFromMin(Math.trunc(secRemainingFromMin) + " second ");
        } else if (Math.trunc(secRemainingFromMin) > 1) {
            setSecRemainingFromMin(Math.trunc(secRemainingFromMin) + " seconds ");
        } else {
            setSecRemainingFromMin("");
        }

    }

    let dataSubmitted = false;

    const getTotalCallsMine = async () => {
        setLoadingEnable(true)
        let memberListName = "VisitRemindersUploadLead";
        let url = '/api/chartsData';
        let obj = { memberListName, PracticeId }
        const userData = await usersPostRequest(token, url, obj);
        if (userData == "Token is expired, login again") {
            setIsTokenExpired(true)
        }
        else {
            userData ? setTotalCallsMine(userData[0].TotalCallsMine) : "";
        }
    }

    // visitReminder switch toggle on load
    async function showMetricChartsOnLoad() {
        dataSubmitted = true;
        setLoadingEnable(true);
        setDashboardUrl(window.location.href);

        // if (document.getElementById("toogleGenerateMetricChart")) {
        //     document.getElementById("toogleGenerateMetricChart").checked = true;
        //     document.getElementById("column1").classList.remove("col-sm-12", "col-lg-10", "col-xl-10");
        //     document.getElementById("column1").classList.add("col-sm-12", "col-lg-5", "col-xl-5");
        //     document.getElementById("column2").style.display = "block";
        // }

        let memberListName = "VisitRemindersUploadLead";
        let url = '/api/chartsData';
        let obj = { memberListName, PracticeId };
        const userData = await usersPostRequest(token, url, obj);

        if (userData == "Token is expired, login again") {
            setIsTokenExpired(true)
        }
        else {
            userData ? setBookedCallsMine(userData[0].BookedCallsMine) : "";
            // userData ? setTotalCallsMine(userData[0].TotalCallsMine) : "";
            convertSecToHoursMinSec(userData[0].TotalDurationOfCallsMine)

            let totalCallsData = {
                "label": "Total Calls",
                "all": userData[0].TotalCallsAll,
                "current": userData[0].TotalCallsMine
            };

            let totalDurationData = {
                "label": "Total Duration (min)",
                "all": userData[0].TotalDurationOfCallsAll,
                "current": userData[0].TotalCallsMine,
                "totalCallsMine": Math.trunc(userData[0].TotalDurationOfCallsMine / 60)
            };

            let completedCallsData = {
                "label": "Completed Calls",
                "all": userData[0].CompletedCallsAll,
                "current": userData[0].CompletedCallsMine
            };

            let missedBusyNoAnswerCallsData = {
                "label": "Missed/ Busy",
                "nextLabel": "No Answer",
                "all": userData[0].MissedBusyNoAnswerCallsAll,
                "current": userData[0].MissedBusyNoAnswerCallsMine
            };

            let bookedCallsData = {
                "label": "Booked",
                "all": userData[0].BookedCallsAll,
                "current": userData[0].BookedCallsMine
            };

            setTimeout(function () {
                document.getElementById("column1").classList.remove("col-sm-12", "col-lg-10", "col-xl-10", "d-none");
                document.getElementById("column1").classList.add("col-sm-12", "col-lg-5", "col-xl-5");
                document.getElementById("column2").style.display = "block";

                if (totalCallsData.current >= 0 && document.getElementById("toogleAllCareCoordinators")) {
                    generateMetricChart("renderToDivCircle1", totalCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle2", totalDurationData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle4", completedCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle5", missedBusyNoAnswerCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle3", bookedCallsData, 170, 170, "ccm");
                    // document.getElementById("toogleAllCareCoordinators").removeAttribute("disabled");
                    document.getElementById("toogleAllCareCoordinators").checked = false;
                    document.getElementById("toogleSwitchPauthS").checked = false;
                    document.getElementById("toogleSwitchPauthQ").checked = false;
                    document.getElementById("toogleSwitchLeadG").checked = false;
                    document.getElementById("toogleAwareC").checked = false;
                    // document.getElementById("toogleSwitchInBoundCalls").checked = false;
                    document.getElementById("toogleSwitchSurvey").checked = false;
                    document.getElementById("rOILabel").innerHTML = "ROI Calculation";
                    document.getElementById("roiValueBox").value = "";
                    document.getElementById("qmSubModuleTitle").innerHTML = "";
                    document.getElementById("qmSubModuleTitle").classList.add("d-none");
                }
            }, 120);
            setLoadingEnable(false);
        }
    }

    useEffect(() => {
        if (PracticeId && token && document.getElementById("toogleGenerateMetricChart") && dataSubmitted == false) {
            document.getElementById("toogleGenerateMetricChart").checked = true;
            document.getElementById("column1").classList.add("d-none");
            getTotalCallsMine()
        }
    }, [PracticeId, token, dataSubmitted]);

    useEffect(() => {
        if (dataSubmitted === false && totalCallsMine !== null) {
            showMetricChartsOnLoad()
        }

    }, [dataSubmitted, totalCallsMine])

    const visitReminderHandle = async (e) => {
        // document.getElementById("qmMenu").classList.add("d-none");
        // document.getElementById("roiWindow").classList.remove("d-none");
        setMemberListName("VisitRemindersUploadLead")
        setChartsLoadingEnable(true)
        document.getElementById("todayDate").innerHTML = new Date();

        let memberListName = "VisitRemindersUploadLead";
        let url = '/api/chartsData';
        let obj = { startDate, endDate, memberListName, PracticeId }
        const userData = await usersPostRequest(token, url, obj);
        if (userData == "Token is expired, login again") {
            setIsTokenExpired(true)
        }
        else {
            userData ? setBookedCallsMine(userData[0].BookedCallsMine) : "";
            // userData ? setTotalCallsMine(userData[0].TotalCallsMine) : "";
            convertSecToHoursMinSec(userData[0].TotalDurationOfCallsMine)

            let totalCallsData = {
                "label": "Total Calls",
                "all": userData[0].TotalCallsAll,
                "current": userData[0].TotalCallsMine
            };

            let totalDurationData = {
                "label": "Total Duration (min)",
                "all": userData[0].TotalDurationOfCallsAll,
                "current": userData[0].TotalCallsMine,
                "totalCallsMine": Math.trunc(userData[0].TotalDurationOfCallsMine / 60)
            };

            let completedCallsData = {
                "label": "Completed Calls",
                "all": userData[0].CompletedCallsAll,
                "current": userData[0].CompletedCallsMine
            };

            let missedBusyNoAnswerCallsData = {
                "label": "Missed/ Busy",
                "nextLabel": "No Answer",
                "all": userData[0].MissedBusyNoAnswerCallsAll,
                "current": userData[0].MissedBusyNoAnswerCallsMine
            };

            let bookedCallsData = {
                "label": "Booked",
                "all": userData[0].BookedCallsAll,
                "current": userData[0].BookedCallsMine
            };

            if (e.checked && totalCallsData) {
                document.getElementById("column1").classList.remove("col-sm-12", "col-lg-10", "col-xl-10", "d-none");
                document.getElementById("column1").classList.add("col-sm-12", "col-lg-5", "col-xl-5");
                document.getElementById("column2").style.display = "block";
                setTimeout(function () {
                    generateMetricChart("renderToDivCircle1", totalCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle2", totalDurationData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle4", completedCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle5", missedBusyNoAnswerCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle3", bookedCallsData, 170, 170, "ccm");
                    document.getElementById("toogleAllCareCoordinators").checked = false;
                    document.getElementById("toogleSwitchPauthS").checked = false;
                    document.getElementById("toogleSwitchPauthQ").checked = false;
                    document.getElementById("toogleSwitchLeadG").checked = false;
                    document.getElementById("toogleAwareC").checked = false;
                    // document.getElementById("toogleSwitchInBoundCalls").checked = false;
                    document.getElementById("toogleSwitchSurvey").checked = false;
                    document.getElementById("rOILabel").innerHTML = "ROI Calculation";
                    document.getElementById("roiValueBox").value = "";
                    document.getElementById("qmSubModuleTitle").innerHTML = "";
                    document.getElementById("qmSubModuleTitle").classList.add("d-none");
                }, 80);

            } else if (e.target.checked && totalCallsData) {
                document.getElementById("column1").classList.remove("col-sm-12", "col-lg-10", "col-xl-10", "d-none");
                document.getElementById("column1").classList.add("col-sm-12", "col-lg-5", "col-xl-5");
                document.getElementById("column2").style.display = "block";
                setTimeout(function () {
                    generateMetricChart("renderToDivCircle1", totalCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle2", totalDurationData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle4", completedCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle5", missedBusyNoAnswerCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle3", bookedCallsData, 170, 170, "ccm");
                    document.getElementById("toogleAllCareCoordinators").checked = false;
                    document.getElementById("toogleSwitchPauthS").checked = false;
                    document.getElementById("toogleSwitchPauthQ").checked = false;
                    document.getElementById("toogleSwitchLeadG").checked = false;
                    document.getElementById("toogleAwareC").checked = false;
                    // document.getElementById("toogleSwitchInBoundCalls").checked = false;
                    document.getElementById("toogleSwitchSurvey").checked = false;
                    document.getElementById("rOILabel").innerHTML = "ROI Calculation";
                    document.getElementById("roiValueBox").value = "";
                    document.getElementById("qmSubModuleTitle").innerHTML = "";
                    document.getElementById("qmSubModuleTitle").classList.add("d-none");
                }, 80);
            }
            else {
                // document.getElementById("column1").classList.add("col-sm-12", "col-lg-10", "col-xl-10");
                // document.getElementById("column1").classList.remove("col-sm-12", "col-lg-5", "col-xl-5");
                document.getElementById("column1").classList.add("d-none");
                document.getElementById("column2").style.display = "none";
                document.getElementById("toogleAllCareCoordinators").checked = false;
                document.getElementById("toogleSwitchPauthS").checked = false;
                document.getElementById("toogleSwitchPauthQ").checked = false;
                document.getElementById("toogleSwitchLeadG").checked = false;
                document.getElementById("toogleAwareC").checked = false;
                // document.getElementById("toogleSwitchInBoundCalls").checked = false;
                document.getElementById("toogleSwitchSurvey").checked = false;
            }
            setChartsLoadingEnable(false)
        }

    };

    const careReminderHandle = async (e) => {

        setMemberListName("CareRemindersUploadLead")
        setChartsLoadingEnable(true);
        let memberListName = "CareRemindersUploadLead";
        let url = '/api/chartsData';
        let obj = { startDate, endDate, memberListName, PracticeId }
        const userData = await usersPostRequest(token, url, obj);
        if (userData == "Token is expired, login again") {
            setIsTokenExpired(true)
        }
        else {
            userData ? setBookedCallsMine(userData[0].BookedCallsMine) : "";
            // userData ? setTotalCallsMine(userData[0].TotalCallsMine) : "";
            convertSecToHoursMinSec(userData[0].TotalDurationOfCallsMine)

            let totalCallsData = {
                "label": "Total Calls",
                "all": userData[0].TotalCallsAll,
                "current": userData[0].TotalCallsMine
            };

            let totalDurationData = {
                "label": "Total Duration (min)",
                "all": userData[0].TotalDurationOfCallsAll,
                "current": userData[0].TotalCallsMine,
                "totalCallsMine": Math.trunc(userData[0].TotalDurationOfCallsMine / 60)
            };

            let completedCallsData = {
                "label": "Completed Calls",
                "all": userData[0].CompletedCallsAll,
                "current": userData[0].CompletedCallsMine
            };

            let missedBusyNoAnswerCallsData = {
                "label": "Missed/ Busy",
                "nextLabel": "No Answer",
                "all": userData[0].MissedBusyNoAnswerCallsAll,
                "current": userData[0].MissedBusyNoAnswerCallsMine
            };

            let bookedCallsData = {
                "label": "Booked",
                "all": userData[0].BookedCallsAll,
                "current": userData[0].BookedCallsMine
            };

            if (e.checked && totalCallsData) {
                document.getElementById("column1").classList.remove("col-sm-12", "col-lg-10", "col-xl-10", "d-none");
                document.getElementById("column1").classList.add("col-sm-12", "col-lg-5", "col-xl-5");
                document.getElementById("column2").style.display = "block";

                setTimeout(function () {
                    generateMetricChart("renderToDivCircle1", totalCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle2", totalDurationData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle4", completedCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle5", missedBusyNoAnswerCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle3", bookedCallsData, 170, 170, "ccm");
                    document.getElementById("toogleGenerateMetricChart").checked = false;
                    document.getElementById("toogleSwitchPauthQ").checked = false;
                    document.getElementById("toogleSwitchPauthS").checked = false;
                    document.getElementById("toogleSwitchLeadG").checked = false;
                    document.getElementById("toogleAwareC").checked = false;
                    // document.getElementById("toogleSwitchInBoundCalls").checked = false;
                    document.getElementById("toogleSwitchSurvey").checked = false;
                    document.getElementById("qmSubModuleTitle").innerHTML = "";
                    document.getElementById("qmSubModuleTitle").classList.add("d-none");
                }, 80);
                document.getElementById("rOILabel").innerHTML = "Exp. Reimb";
                document.getElementById("roiValueBox").value = "16000";
                document.getElementById("roiValueBox").classList.add("text-success", "fw-bold");
            }
            else if (e.target.checked && totalCallsData) {
                document.getElementById("column1").classList.remove("col-sm-12", "col-lg-10", "col-xl-10", "d-none");
                document.getElementById("column1").classList.add("col-sm-12", "col-lg-5", "col-xl-5");
                document.getElementById("column2").style.display = "block";

                setTimeout(function () {
                    generateMetricChart("renderToDivCircle1", totalCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle2", totalDurationData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle4", completedCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle5", missedBusyNoAnswerCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle3", bookedCallsData, 170, 170, "ccm");
                    document.getElementById("toogleGenerateMetricChart").checked = false;
                    document.getElementById("toogleSwitchPauthQ").checked = false;
                    document.getElementById("toogleSwitchPauthS").checked = false;
                    document.getElementById("toogleSwitchLeadG").checked = false;
                    document.getElementById("toogleAwareC").checked = false;
                    // document.getElementById("toogleSwitchInBoundCalls").checked = false;
                    document.getElementById("toogleSwitchSurvey").checked = false;
                    document.getElementById("qmSubModuleTitle").innerHTML = "";
                    document.getElementById("qmSubModuleTitle").classList.add("d-none");
                }, 80);
                document.getElementById("rOILabel").innerHTML = "Exp. Reimb";
                document.getElementById("roiValueBox").value = "16000";
                document.getElementById("roiValueBox").classList.add("text-success", "fw-bold");
            }
            else {
                // document.getElementById("column1").classList.add("col-sm-12", "col-lg-10", "col-xl-10");
                // document.getElementById("column1").classList.remove("col-sm-12", "col-lg-5", "col-xl-5");
                document.getElementById("column1").classList.add("d-none");
                document.getElementById("column2").style.display = "none";
                document.getElementById("toogleGenerateMetricChart").checked = false;
                document.getElementById("toogleAllCareCoordinators").checked = false;
                document.getElementById("toogleSwitchPauthQ").checked = false;
                document.getElementById("toogleSwitchLeadG").checked = false;
                document.getElementById("toogleAwareC").checked = false;
                // document.getElementById("toogleSwitchInBoundCalls").checked = false;
                document.getElementById("toogleSwitchSurvey").checked = false;
                document.getElementById("rOILabel").innerHTML = "ROI Calculation";
                document.getElementById("roiValueBox").value = "";
                document.getElementById("roiValueBox").classList.remove("text-success", "fw-bold");
                document.getElementById("qmSubModuleTitle").innerHTML = "";
                document.getElementById("qmSubModuleTitle").classList.add("d-none");
            }
            setChartsLoadingEnable(false)
        }

    };

    const tooglePauthSHandle = async (e) => {

        setMemberListName("PAuthStatusUploadLead")
        setChartsLoadingEnable(true);
        let memberListName = "PAuthStatusUploadLead";
        let url = '/api/chartsData';
        let obj = { startDate, endDate, memberListName, PracticeId }
        const userData = await usersPostRequest(token, url, obj);
        if (userData == "Token is expired, login again") {
            setIsTokenExpired(true)
        }
        else {
            userData ? setBookedCallsMine(userData[0].BookedCallsMine) : "";
            // userData ? setTotalCallsMine(userData[0].TotalCallsMine) : "";
            convertSecToHoursMinSec(userData[0].TotalDurationOfCallsMine)

            let totalCallsData = {
                "label": "Total Calls",
                "all": userData[0].TotalCallsAll,
                "current": userData[0].TotalCallsMine
            };

            let totalDurationData = {
                "label": "Total Duration (min)",
                "all": userData[0].TotalDurationOfCallsAll,
                "current": userData[0].TotalCallsMine,
                "totalCallsMine": Math.trunc(userData[0].TotalDurationOfCallsMine / 60)
            };

            let completedCallsData = {
                "label": "Completed Calls",
                "all": userData[0].CompletedCallsAll,
                "current": userData[0].CompletedCallsMine
            };

            let missedBusyNoAnswerCallsData = {
                "label": "Missed/ Busy",
                "nextLabel": "No Answer",
                "all": userData[0].MissedBusyNoAnswerCallsAll,
                "current": userData[0].MissedBusyNoAnswerCallsMine
            };

            let bookedCallsData = {
                "label": "Booked",
                "all": userData[0].BookedCallsAll,
                "current": userData[0].BookedCallsMine
            };

            if (e.checked && totalCallsData) {
                document.getElementById("column1").classList.remove("col-sm-12", "col-lg-10", "col-xl-10", "d-none");
                document.getElementById("column1").classList.add("col-sm-12", "col-lg-5", "col-xl-5");
                document.getElementById("column2").style.display = "block";

                setTimeout(function () {
                    generateMetricChart("renderToDivCircle1", totalCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle2", totalDurationData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle4", completedCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle5", missedBusyNoAnswerCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle3", bookedCallsData, 170, 170, "ccm");
                    document.getElementById("toogleGenerateMetricChart").checked = false;
                    document.getElementById("toogleAllCareCoordinators").checked = false;
                    document.getElementById('toogleAllCareCoordinators').disabled = false;
                    document.getElementById("toogleSwitchPauthQ").checked = false;
                    document.getElementById("toogleSwitchLeadG").checked = false;
                    document.getElementById("toogleAwareC").checked = false;
                    // document.getElementById("toogleSwitchInBoundCalls").checked = false;
                    document.getElementById("toogleSwitchSurvey").checked = false;
                    document.getElementById("qmSubModuleTitle").innerHTML = "";
                    document.getElementById("qmSubModuleTitle").classList.add("d-none");
                }, 80);
                document.getElementById("rOILabel").innerHTML = "Exp. Reimb";
                document.getElementById("roiValueBox").value = "16000";
                document.getElementById("roiValueBox").classList.add("text-success", "fw-bold");
            }
            else if (e.target.checked && totalCallsData) {
                document.getElementById("column1").classList.remove("col-sm-12", "col-lg-10", "col-xl-10", "d-none");
                document.getElementById("column1").classList.add("col-sm-12", "col-lg-5", "col-xl-5");
                document.getElementById("column2").style.display = "block";

                setTimeout(function () {
                    generateMetricChart("renderToDivCircle1", totalCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle2", totalDurationData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle4", completedCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle5", missedBusyNoAnswerCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle3", bookedCallsData, 170, 170, "ccm");
                    document.getElementById("toogleGenerateMetricChart").checked = false;
                    document.getElementById("toogleAllCareCoordinators").checked = false;
                    document.getElementById('toogleAllCareCoordinators').disabled = false;
                    document.getElementById("toogleSwitchPauthQ").checked = false;
                    document.getElementById("toogleSwitchLeadG").checked = false;
                    document.getElementById("toogleAwareC").checked = false;
                    // document.getElementById("toogleSwitchInBoundCalls").checked = false;
                    document.getElementById("toogleSwitchSurvey").checked = false;
                    document.getElementById("qmSubModuleTitle").innerHTML = "";
                    document.getElementById("qmSubModuleTitle").classList.add("d-none");
                }, 80);
                document.getElementById("rOILabel").innerHTML = "Exp. Reimb";
                document.getElementById("roiValueBox").value = "16000";
                document.getElementById("roiValueBox").classList.add("text-success", "fw-bold");
            }
            else {
                // document.getElementById("column1").classList.add("col-sm-12", "col-lg-10", "col-xl-10");
                // document.getElementById("column1").classList.remove("col-sm-12", "col-lg-5", "col-xl-5");
                document.getElementById("column1").classList.add("d-none");
                document.getElementById("column2").style.display = "none";
                document.getElementById("toogleGenerateMetricChart").checked = false;
                document.getElementById("toogleAllCareCoordinators").checked = false;
                document.getElementById("toogleSwitchPauthQ").checked = false;
                document.getElementById("toogleSwitchLeadG").checked = false;
                document.getElementById("toogleAwareC").checked = false;
                // document.getElementById("toogleSwitchInBoundCalls").checked = false;
                document.getElementById("toogleSwitchSurvey").checked = false;
                document.getElementById("rOILabel").innerHTML = "ROI Calculation";
                document.getElementById("roiValueBox").value = "";
                document.getElementById("roiValueBox").classList.remove("text-success", "fw-bold");
                document.getElementById("qmSubModuleTitle").innerHTML = "";
                document.getElementById("qmSubModuleTitle").classList.add("d-none");
            }
            setChartsLoadingEnable(false)
        }
    };

    const tooglePauthQHandle = async (e) => {

        setMemberListName("PriorAuthQLead")
        setChartsLoadingEnable(true)
        document.getElementById("column1").classList.remove("col-sm-12", "col-lg-10", "col-xl-10");
        document.getElementById("column1").classList.add("col-sm-12", "col-lg-5", "col-xl-5");
        let memberListName = "PriorAuthQLead";
        let url = '/api/chartsData';
        let obj = { startDate, endDate, memberListName, PracticeId }
        const userData = await usersPostRequest(token, url, obj);
        if (userData == "Token is expired, login again") {
            setIsTokenExpired(true)
        }
        else {
            userData ? setBookedCallsMine(userData[0].BookedCallsMine) : "";
            // userData ? setTotalCallsMine(userData[0].TotalCallsMine) : "";
            convertSecToHoursMinSec(userData[0].TotalDurationOfCallsMine)

            let totalCallsData = {
                "label": "Total Calls",
                "all": userData[0].TotalCallsAll,
                "current": userData[0].TotalCallsMine
            };

            let totalDurationData = {
                "label": "Total Duration (min)",
                "all": userData[0].TotalDurationOfCallsAll,
                "current": userData[0].TotalCallsMine,
                "totalCallsMine": Math.trunc(userData[0].TotalDurationOfCallsMine / 60)
            };

            let completedCallsData = {
                "label": "Completed Calls",
                "all": userData[0].CompletedCallsAll,
                "current": userData[0].CompletedCallsMine
            };

            let missedBusyNoAnswerCallsData = {
                "label": `Missed/ Busy`,
                "nextLabel": "No Answer",
                "all": userData[0].MissedBusyNoAnswerCallsAll,
                "current": userData[0].MissedBusyNoAnswerCallsMine
            };

            let bookedCallsData = {
                "label": "Booked",
                "all": userData[0].BookedCallsAll,
                "current": userData[0].BookedCallsMine
            };

            if (e.checked && totalCallsData) {
                document.getElementById("column1").classList.remove("col-sm-12", "col-lg-10", "col-xl-10", "d-none");
                document.getElementById("column1").classList.add("col-sm-12", "col-lg-5", "col-xl-5");
                document.getElementById("column2").style.display = "block";

                setTimeout(function () {
                    generateMetricChart("renderToDivCircle1", totalCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle2", totalDurationData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle4", completedCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle5", missedBusyNoAnswerCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle3", bookedCallsData, 170, 170, "ccm");
                    document.getElementById("toogleGenerateMetricChart").checked = false;
                    document.getElementById("toogleAllCareCoordinators").checked = false;
                    document.getElementById('toogleAllCareCoordinators').disabled = false;
                    document.getElementById("toogleSwitchPauthS").checked = false;
                    document.getElementById("toogleSwitchLeadG").checked = false;
                    document.getElementById("toogleAwareC").checked = false;
                    // document.getElementById("toogleSwitchInBoundCalls").checked = false;
                    document.getElementById("toogleSwitchSurvey").checked = false;
                    document.getElementById("qmSubModuleTitle").innerHTML = "";
                    document.getElementById("qmSubModuleTitle").classList.add("d-none");
                }, 80);
                document.getElementById("rOILabel").innerHTML = "Exp. Reimb";
                document.getElementById("roiValueBox").value = "12000";
                document.getElementById("roiValueBox").classList.add("text-success", "fw-bold");
            }
            else if (e.target.checked && totalCallsData) {
                document.getElementById("column1").classList.remove("col-sm-12", "col-lg-10", "col-xl-10", "d-none");
                document.getElementById("column1").classList.add("col-sm-12", "col-lg-5", "col-xl-5");
                document.getElementById("column2").style.display = "block";

                setTimeout(function () {
                    generateMetricChart("renderToDivCircle1", totalCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle2", totalDurationData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle4", completedCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle5", missedBusyNoAnswerCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle3", bookedCallsData, 170, 170, "ccm");
                    document.getElementById("toogleGenerateMetricChart").checked = false;
                    document.getElementById("toogleAllCareCoordinators").checked = false;
                    document.getElementById('toogleAllCareCoordinators').disabled = false;
                    document.getElementById("toogleSwitchPauthS").checked = false;
                    document.getElementById("toogleSwitchLeadG").checked = false;
                    document.getElementById("toogleAwareC").checked = false;
                    // document.getElementById("toogleSwitchInBoundCalls").checked = false;
                    document.getElementById("toogleSwitchSurvey").checked = false;
                    document.getElementById("qmSubModuleTitle").innerHTML = "";
                    document.getElementById("qmSubModuleTitle").classList.add("d-none");
                }, 80);
                document.getElementById("rOILabel").innerHTML = "Exp. Reimb";
                document.getElementById("roiValueBox").value = "12000";
                document.getElementById("roiValueBox").classList.add("text-success", "fw-bold");
            }
            else {
                // document.getElementById("column1").classList.add("col-sm-12", "col-lg-10", "col-xl-10");
                // document.getElementById("column1").classList.remove("col-sm-12", "col-lg-5", "col-xl-5");
                document.getElementById("column1").classList.add("d-none");
                document.getElementById("column2").style.display = "none";
                document.getElementById("toogleGenerateMetricChart").checked = false;
                document.getElementById("toogleAllCareCoordinators").checked = false;
                document.getElementById("toogleSwitchPauthS").checked = false;
                document.getElementById("toogleSwitchLeadG").checked = false;
                document.getElementById("toogleAwareC").checked = false;
                // document.getElementById("toogleSwitchInBoundCalls").checked = false;
                document.getElementById("toogleSwitchSurvey").checked = false;
                document.getElementById("rOILabel").innerHTML = "ROI Calculation";
                document.getElementById("roiValueBox").value = "";
                document.getElementById("roiValueBox").classList.remove("text-success", "fw-bold");
                document.getElementById("qmSubModuleTitle").innerHTML = "";
                document.getElementById("qmSubModuleTitle").classList.add("d-none");
            }
            setChartsLoadingEnable(false)

        }
    };

    const toogleLeadGHandle = async (e) => {

        setMemberListName("LeadGenerationUploadLead")
        setChartsLoadingEnable(true);
        let memberListName = "LeadGenerationUploadLead";
        let url = '/api/chartsData';
        let obj = { startDate, endDate, memberListName, PracticeId }
        const userData = await usersPostRequest(token, url, obj);
        if (userData == "Token is expired, login again") {
            setIsTokenExpired(true)
        }
        else {
            userData ? setBookedCallsMine(userData[0].BookedCallsMine) : "";
            // userData ? setTotalCallsMine(userData[0].TotalCallsMine) : "";
            convertSecToHoursMinSec(userData[0].TotalDurationOfCallsMine)

            let totalCallsData = {
                "label": "Total Calls",
                "all": userData[0].TotalCallsAll,
                "current": userData[0].TotalCallsMine
            };

            let totalDurationData = {
                "label": "Total Duration (min)",
                "all": userData[0].TotalDurationOfCallsAll,
                "current": userData[0].TotalCallsMine,
                "totalCallsMine": Math.trunc(userData[0].TotalDurationOfCallsMine / 60)
            };

            let completedCallsData = {
                "label": "Completed Calls",
                "all": userData[0].CompletedCallsAll,
                "current": userData[0].CompletedCallsMine
            };

            let missedBusyNoAnswerCallsData = {
                "label": "Missed/ Busy",
                "nextLabel": "No Answer",
                "all": userData[0].MissedBusyNoAnswerCallsAll,
                "current": userData[0].MissedBusyNoAnswerCallsMine
            };

            let bookedCallsData = {
                "label": "Booked",
                "all": userData[0].BookedCallsAll,
                "current": userData[0].BookedCallsMine
            };

            if (e.checked && totalCallsData !== null) {
                document.getElementById("column1").classList.remove("col-sm-12", "col-lg-10", "col-xl-10", "d-none");
                document.getElementById("column1").classList.add("col-sm-12", "col-lg-5", "col-xl-5");
                document.getElementById("column2").style.display = "block";

                setTimeout(function () {
                    generateMetricChart("renderToDivCircle1", totalCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle2", totalDurationData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle4", completedCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle5", missedBusyNoAnswerCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle3", bookedCallsData, 170, 170, "ccm");
                    document.getElementById("toogleGenerateMetricChart").checked = false;
                    document.getElementById("toogleAllCareCoordinators").checked = false;
                    document.getElementById('toogleAllCareCoordinators').disabled = false;
                    document.getElementById("toogleSwitchPauthS").checked = false;
                    document.getElementById("toogleSwitchPauthQ").checked = false;
                    document.getElementById("toogleAwareC").checked = false;
                    // document.getElementById("toogleSwitchInBoundCalls").checked = false;
                    document.getElementById("toogleSwitchSurvey").checked = false;
                    document.getElementById("qmSubModuleTitle").innerHTML = "";
                    document.getElementById("qmSubModuleTitle").classList.add("d-none");
                }, 80);
                document.getElementById("rOILabel").innerHTML = "Exp. Reimb";
                document.getElementById("roiValueBox").value = "8000";
                document.getElementById("roiValueBox").classList.add("text-success", "fw-bold");
            }
            else if (e.target.checked && totalCallsData !== null) {
                document.getElementById("column1").classList.remove("col-sm-12", "col-lg-10", "col-xl-10", "d-none");
                document.getElementById("column1").classList.add("col-sm-12", "col-lg-5", "col-xl-5");
                document.getElementById("column2").style.display = "block";

                setTimeout(function () {
                    generateMetricChart("renderToDivCircle1", totalCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle2", totalDurationData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle4", completedCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle5", missedBusyNoAnswerCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle3", bookedCallsData, 170, 170, "ccm");
                    document.getElementById("toogleGenerateMetricChart").checked = false;
                    document.getElementById("toogleAllCareCoordinators").checked = false;
                    document.getElementById('toogleAllCareCoordinators').disabled = false;
                    document.getElementById("toogleSwitchPauthS").checked = false;
                    document.getElementById("toogleSwitchPauthQ").checked = false;
                    document.getElementById("toogleAwareC").checked = false;
                    // document.getElementById("toogleSwitchInBoundCalls").checked = false;
                    document.getElementById("toogleSwitchSurvey").checked = false;
                    document.getElementById("qmSubModuleTitle").innerHTML = "";
                    document.getElementById("qmSubModuleTitle").classList.add("d-none");
                }, 80);
                document.getElementById("rOILabel").innerHTML = "Exp. Reimb";
                document.getElementById("roiValueBox").value = "8000";
                document.getElementById("roiValueBox").classList.add("text-success", "fw-bold");
            }
            else {
                // document.getElementById("column1").classList.add("col-sm-12", "col-lg-10", "col-xl-10");
                // document.getElementById("column1").classList.remove("col-sm-12", "col-lg-5", "col-xl-5");
                document.getElementById("column1").classList.add("d-none");
                document.getElementById("column2").style.display = "none";
                document.getElementById("toogleGenerateMetricChart").checked = false;
                document.getElementById("toogleAllCareCoordinators").checked = false;
                document.getElementById("toogleSwitchPauthS").checked = false;
                document.getElementById("toogleSwitchPauthQ").checked = false;
                document.getElementById("toogleAwareC").checked = false;
                // document.getElementById("toogleSwitchInBoundCalls").checked = false;
                document.getElementById("toogleSwitchSurvey").checked = false;
                document.getElementById("rOILabel").innerHTML = "ROI Calculation";
                document.getElementById("roiValueBox").value = "";
                document.getElementById("roiValueBox").classList.remove("text-success", "fw-bold");
                document.getElementById("qmSubModuleTitle").innerHTML = "";
                document.getElementById("qmSubModuleTitle").classList.add("d-none");
            }
            setChartsLoadingEnable(false)
        }
    };

    const toogleAwareCHandle = async (e) => {

        setMemberListName("AwarenessCampaignUploadLead")
        setChartsLoadingEnable(true);
        let memberListName = "AwarenessCampaignUploadLead";
        let url = '/api/chartsData';
        let obj = { startDate, endDate, memberListName, PracticeId }
        const userData = await usersPostRequest(token, url, obj);
        if (userData == "Token is expired, login again") {
            setIsTokenExpired(true)
        }
        else {
            userData ? setBookedCallsMine(userData[0].BookedCallsMine) : "";
            // userData ? setTotalCallsMine(userData[0].TotalCallsMine) : "";
            convertSecToHoursMinSec(userData[0].TotalDurationOfCallsMine)

            let totalCallsData = {
                "label": "Total Calls",
                "all": userData[0].TotalCallsAll,
                "current": userData[0].TotalCallsMine
            };

            let totalDurationData = {
                "label": "Total Duration (min)",
                "all": userData[0].TotalDurationOfCallsAll,
                "current": userData[0].TotalCallsMine,
                "totalCallsMine": Math.trunc(userData[0].TotalDurationOfCallsMine / 60)
            };

            let completedCallsData = {
                "label": "Completed Calls",
                "all": userData[0].CompletedCallsAll,
                "current": userData[0].CompletedCallsMine
            };

            let missedBusyNoAnswerCallsData = {
                "label": `Missed/ Busy`,
                "nextLabel": "No Answer",
                "all": userData[0].MissedBusyNoAnswerCallsAll,
                "current": userData[0].MissedBusyNoAnswerCallsMine
            };

            let bookedCallsData = {
                "label": "Booked",
                "all": userData[0].BookedCallsAll,
                "current": userData[0].BookedCallsMine
            };

            if (e.checked && totalCallsData !== null) {
                document.getElementById("column1").classList.remove("col-sm-12", "col-lg-10", "col-xl-10", "d-none");
                document.getElementById("column1").classList.add("col-sm-12", "col-lg-5", "col-xl-5");
                document.getElementById("column2").style.display = "block";

                setTimeout(function () {
                    generateMetricChart("renderToDivCircle1", totalCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle2", totalDurationData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle4", completedCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle5", missedBusyNoAnswerCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle3", bookedCallsData, 170, 170, "ccm");
                    document.getElementById("toogleGenerateMetricChart").checked = false;
                    document.getElementById("toogleAllCareCoordinators").checked = false;
                    document.getElementById("toogleSwitchPauthS").checked = false;
                    document.getElementById("toogleSwitchPauthQ").checked = false;
                    document.getElementById("toogleSwitchLeadG").checked = false;
                    // document.getElementById("toogleSwitchInBoundCalls").checked = false;
                    document.getElementById("toogleSwitchSurvey").checked = false;
                    document.getElementById("qmSubModuleTitle").innerHTML = "";
                    document.getElementById("qmSubModuleTitle").classList.add("d-none");
                }, 80);
                document.getElementById("rOILabel").innerHTML = "Exp. Reimb";
                document.getElementById("roiValueBox").value = "12000";
                document.getElementById("roiValueBox").classList.add("text-success", "fw-bold");
            }
            else if (e.target.checked && totalCallsData !== null) {
                document.getElementById("column1").classList.remove("col-sm-12", "col-lg-10", "col-xl-10", "d-none");
                document.getElementById("column1").classList.add("col-sm-12", "col-lg-5", "col-xl-5");
                document.getElementById("column2").style.display = "block";

                setTimeout(function () {
                    generateMetricChart("renderToDivCircle1", totalCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle2", totalDurationData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle4", completedCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle5", missedBusyNoAnswerCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle3", bookedCallsData, 170, 170, "ccm");
                    document.getElementById("toogleGenerateMetricChart").checked = false;
                    document.getElementById("toogleAllCareCoordinators").checked = false;
                    document.getElementById("toogleSwitchPauthS").checked = false;
                    document.getElementById("toogleSwitchPauthQ").checked = false;
                    document.getElementById("toogleSwitchLeadG").checked = false;
                    // document.getElementById("toogleSwitchInBoundCalls").checked = false;
                    document.getElementById("toogleSwitchSurvey").checked = false;
                    document.getElementById("qmSubModuleTitle").innerHTML = "";
                    document.getElementById("qmSubModuleTitle").classList.add("d-none");
                }, 80);
                document.getElementById("rOILabel").innerHTML = "Exp. Reimb";
                document.getElementById("roiValueBox").value = "12000";
                document.getElementById("roiValueBox").classList.add("text-success", "fw-bold");
            }
            else {
                // document.getElementById("column1").classList.add("col-sm-12", "col-lg-10", "col-xl-10");
                // document.getElementById("column1").classList.remove("col-sm-12", "col-lg-5", "col-xl-5");
                document.getElementById("column1").classList.add("d-none");
                document.getElementById("column2").style.display = "none";
                document.getElementById("toogleGenerateMetricChart").checked = false;
                document.getElementById("toogleAllCareCoordinators").checked = false;
                document.getElementById("toogleSwitchPauthS").checked = false;
                document.getElementById("toogleSwitchPauthQ").checked = false;
                document.getElementById("toogleSwitchLeadG").checked = false;
                // document.getElementById("toogleSwitchInBoundCalls").checked = false;
                document.getElementById("toogleSwitchSurvey").checked = false;
                document.getElementById("rOILabel").innerHTML = "ROI Calculation";
                document.getElementById("roiValueBox").value = "";
                document.getElementById("roiValueBox").classList.remove("text-success", "fw-bold");
                document.getElementById("qmSubModuleTitle").innerHTML = "";
                document.getElementById("qmSubModuleTitle").classList.add("d-none");
            }
            setChartsLoadingEnable(false)
        }
    };

    const toogleSurveyHandle = async (e) => {

        setMemberListName("SurveyUploadLead")
        setChartsLoadingEnable(true);
        let memberListName = "SurveyUploadLead";
        let url = '/api/chartsData';
        let obj = { startDate, endDate, memberListName, PracticeId }
        const userData = await usersPostRequest(token, url, obj);
        if (userData == "Token is expired, login again") {
            setIsTokenExpired(true)
        }
        else {
            userData ? setBookedCallsMine(userData[0].BookedCallsMine) : "";
            // userData ? setTotalCallsMine(userData[0].TotalCallsMine) : "";
            convertSecToHoursMinSec(userData[0].TotalDurationOfCallsMine)

            let totalCallsData = {
                "label": "Total Calls",
                "all": userData[0].TotalCallsAll,
                "current": userData[0].TotalCallsMine
            };

            let totalDurationData = {
                "label": "Total Duration (min)",
                "all": userData[0].TotalDurationOfCallsAll,
                "current": userData[0].TotalCallsMine,
                "totalCallsMine": Math.trunc(userData[0].TotalDurationOfCallsMine / 60)
            };

            let completedCallsData = {
                "label": "Completed Calls",
                "all": userData[0].CompletedCallsAll,
                "current": userData[0].CompletedCallsMine
            };

            let missedBusyNoAnswerCallsData = {
                "label": "Missed/ Busy",
                "nextLabel": "No Answer",
                "all": userData[0].MissedBusyNoAnswerCallsAll,
                "current": userData[0].MissedBusyNoAnswerCallsMine
            };

            let bookedCallsData = {
                "label": "Booked",
                "all": userData[0].BookedCallsAll,
                "current": userData[0].BookedCallsMine
            };

            if (e.checked && totalCallsData !== null) {
                document.getElementById("column1").classList.remove("col-sm-12", "col-lg-10", "col-xl-10", "d-none");
                document.getElementById("column1").classList.add("col-sm-12", "col-lg-5", "col-xl-5");
                document.getElementById("column2").style.display = "block";

                setTimeout(function () {
                    generateMetricChart("renderToDivCircle1", totalCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle2", totalDurationData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle4", completedCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle5", missedBusyNoAnswerCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle3", bookedCallsData, 170, 170, "ccm");
                    document.getElementById("toogleGenerateMetricChart").checked = false;
                    document.getElementById("toogleAllCareCoordinators").checked = false;
                    document.getElementById("toogleSwitchPauthS").checked = false;
                    document.getElementById("toogleSwitchPauthQ").checked = false;
                    document.getElementById("toogleSwitchLeadG").checked = false;
                    document.getElementById("toogleAwareC").checked = false;
                    // document.getElementById("toogleSwitchInBoundCalls").checked = false;
                    document.getElementById("qmSubModuleTitle").innerHTML = "";
                    document.getElementById("qmSubModuleTitle").classList.add("d-none");
                }, 80);
                document.getElementById("rOILabel").innerHTML = "Exp. Reimb";
                document.getElementById("roiValueBox").value = "5000";
                document.getElementById("roiValueBox").classList.add("text-success", "fw-bold");
            }
            else if (e.target.checked && totalCallsData !== null) {
                document.getElementById("column1").classList.remove("col-sm-12", "col-lg-10", "col-xl-10", "d-none");
                document.getElementById("column1").classList.add("col-sm-12", "col-lg-5", "col-xl-5");
                document.getElementById("column2").style.display = "block";

                setTimeout(function () {
                    generateMetricChart("renderToDivCircle1", totalCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle2", totalDurationData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle4", completedCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle5", missedBusyNoAnswerCallsData, 170, 170, "ccm");
                    generateMetricChart("renderToDivCircle3", bookedCallsData, 170, 170, "ccm");
                    document.getElementById("toogleGenerateMetricChart").checked = false;
                    document.getElementById("toogleAllCareCoordinators").checked = false;
                    document.getElementById("toogleSwitchPauthS").checked = false;
                    document.getElementById("toogleSwitchPauthQ").checked = false;
                    document.getElementById("toogleSwitchLeadG").checked = false;
                    document.getElementById("toogleAwareC").checked = false;
                    // document.getElementById("toogleSwitchInBoundCalls").checked = false;
                    document.getElementById("qmSubModuleTitle").innerHTML = "";
                    document.getElementById("qmSubModuleTitle").classList.add("d-none");
                }, 80);
                document.getElementById("rOILabel").innerHTML = "Exp. Reimb";
                document.getElementById("roiValueBox").value = "5000";
                document.getElementById("roiValueBox").classList.add("text-success", "fw-bold");
            }
            else {
                // document.getElementById("column1").classList.add("col-sm-12", "col-lg-10", "col-xl-10");
                // document.getElementById("column1").classList.remove("col-sm-12", "col-lg-5", "col-xl-5");
                document.getElementById("column1").classList.add("d-none");
                document.getElementById("column2").style.display = "none";
                document.getElementById("toogleGenerateMetricChart").checked = false;
                document.getElementById("toogleAllCareCoordinators").checked = false;
                document.getElementById("toogleSwitchPauthS").checked = false;
                document.getElementById("toogleSwitchPauthQ").checked = false;
                document.getElementById("toogleSwitchLeadG").checked = false;
                document.getElementById("toogleAwareC").checked = false;
                // document.getElementById("toogleSwitchInBoundCalls").checked = false;
                document.getElementById("rOILabel").innerHTML = "ROI Calculation";
                document.getElementById("roiValueBox").value = "";
                document.getElementById("roiValueBox").classList.remove("text-success", "fw-bold");
                document.getElementById("qmSubModuleTitle").innerHTML = "";
                document.getElementById("qmSubModuleTitle").classList.add("d-none");
            }
            setChartsLoadingEnable(false)
        }
    };

    const startDateHandle = (e) => {
        setStartDate(e.target.value);
        document.getElementById('datesRangeSubmit').removeAttribute("disabled");
        document.getElementById('datesRangeSubmit').classList.remove("dateRangeCursor");
    }

    const endDateHandle = (e) => {
        setEndDate(e.target.value);
        document.getElementById('datesRangeSubmit').removeAttribute("disabled");
        document.getElementById('datesRangeSubmit').classList.remove("dateRangeCursor");
    }

    const datesRangeHandle = (e) => {

        if (memberListName === "VisitRemindersUploadLead") {
            visitReminderHandle(document.getElementById("toogleGenerateMetricChart"));
        }
        else if (memberListName === "CareRemindersUploadLead") {
            careReminderHandle(document.getElementById("toogleAllCareCoordinators"));
        }
        else if (memberListName === "PAuthStatusUploadLead") {
            tooglePauthSHandle(document.getElementById("toogleSwitchPauthS"));
        }
        else if (memberListName === "PriorAuthQLead") {
            tooglePauthQHandle(document.getElementById("toogleSwitchPauthQ"));
        }
        else if (memberListName === "LeadGenerationUploadLead") {
            toogleLeadGHandle(document.getElementById("toogleSwitchLeadG"));
        }
        else if (memberListName === "AwarenessCampaignUploadLead") {
            toogleAwareCHandle(document.getElementById("toogleAwareC"));
        }
        else {
            toogleSurveyHandle(document.getElementById("toogleSwitchSurvey"));
        }
    }

    useEffect(() => {
        if (!startDate && !endDate) {
            document.getElementById('datesRangeSubmit').setAttribute("disabled", "true");
            document.getElementById('datesRangeSubmit').classList.add("dateRangeCursor");
        }
    }, [startDate, endDate]);

    useEffect(() => {
        if (isTokenExpired === true) {
            alert("Token is expired, login again");
            setToken("");
            setIsTokenExpired(false);
            setDashboardUrl("")
            navigate("/")
            dispatch(logout());
        }
    }, [isTokenExpired]);

    // document.getElementById('toogleSwitchInBoundCalls').addEventListener('change', function () {
    //     setMemberListName("InBoundCallsUpload");

    //     if (this.checked && totalCallsData !== null) {
    //         document.getElementById("column1").classList.remove("col-sm-12", "col-lg-10", "col-xl-10");
    //         document.getElementById("column1").classList.add("col-sm-12", "col-lg-5", "col-xl-5");
    //         document.getElementById("column2").style.display = "block";

    //         setTimeout(function () {
    //             generateMetricChart("renderToDivCircle1", totalCallsData, 170, 170, "ccm");
    //             generateMetricChart("renderToDivCircle2", totalDurationData, 170, 170, "ccm");
    //             generateMetricChart("renderToDivCircle4", completedCallsData, 170, 170, "ccm");
    //             generateMetricChart("renderToDivCircle5", missedBusyNoAnswerCallsData, 170, 170, "ccm");
    //             generateMetricChart("renderToDivCircle3", bookedCallsData, 170, 170, "ccm");
    //             document.getElementById("toogleGenerateMetricChart").checked = false;
    //             document.getElementById("toogleAllCareCoordinators").checked = false;
    //             document.getElementById('toogleAllCareCoordinators').disabled = true;
    //             document.getElementById("toogleSwitchPauthS").checked = false;
    //             document.getElementById("toogleSwitchPauthQ").checked = false;
    //             document.getElementById("toogleSwitchLeadG").checked = false;
    //             document.getElementById("toogleAwareC").checked = false;
    //             document.getElementById("toogleSwitchSurvey").checked = false;
    //             document.getElementById("qmSubModuleTitle").innerHTML = "";
    //             document.getElementById("qmSubModuleTitle").classList.add("d-none");
    //         }, 80);
    //         document.getElementById("rOILabel").innerHTML = "Exp. Reimb";
    //         document.getElementById("roiValueBox").value = "15000";
    //         document.getElementById("roiValueBox").classList.add("text-success", "fw-bold");
    //     } else {
    //         document.getElementById("column1").classList.add("col-sm-12", "col-lg-10", "col-xl-10");
    //         document.getElementById("column1").classList.remove("col-sm-12", "col-lg-5", "col-xl-5");
    //         document.getElementById("column2").style.display = "none";
    //         document.getElementById("toogleGenerateMetricChart").checked = false;
    //         document.getElementById('toogleAllCareCoordinators').dispatchEvent(new Event('change'));
    //         document.getElementById('toogleAllCareCoordinators').disabled = false;
    //         document.getElementById("toogleAllCareCoordinators").checked = false;
    //         document.getElementById("toogleSwitchPauthS").checked = false;
    //         document.getElementById("toogleSwitchPauthQ").checked = false;
    //         document.getElementById("toogleSwitchLeadG").checked = false;
    //         document.getElementById("toogleAwareC").checked = false;
    //         document.getElementById("toogleSwitchSurvey").checked = false;
    //         document.getElementById("rOILabel").innerHTML = "ROI Calculation";
    //         document.getElementById("roiValueBox").value = "";
    //         document.getElementById("roiValueBox").classList.remove("text-success", "fw-bold");
    //         document.getElementById("qmSubModuleTitle").innerHTML = "";
    //         document.getElementById("qmSubModuleTitle").classList.add("d-none");
    //     }
    // });


    function transitionPath(layerOnWhichTransitionApplied) {
        // CareCoordinatorsPatients1
        d3.selectAll("." + layerOnWhichTransitionApplied).selectAll("path").style("opacity", 0);
        d3.selectAll("." + layerOnWhichTransitionApplied).selectAll("path")
            .transition()
            .duration(1500)
            .delay(function (d, i) {
                return i * 15;
            })
            .style("opacity", "1");
    }

    function generateMetricChart(renderToDiv, data, width, height, toggleType) {
        // debugger
        d3.select("#" + renderToDiv).html("");

        var percentage = "";
        if (toggleType == "ccm" || toggleType == "qm" || toggleType == "qm_sub") {
            percentage = "";
        }

        var svg = d3.select("#" + renderToDiv)
            .append("svg")
            .attr("width", width)
            .attr("height", height)
            .append("g")
            .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

        // main outer complete circle
        // An arc will be created
        var completeArc = d3.arc()
            .innerRadius(75)
            .outerRadius(60)
            .cornerRadius(13)
            .startAngle(0)
            .endAngle(8 * Math.PI / 4);

        svg.append("path")
            .attr("class", "completeArc")
            .attr("d", completeArc)
            .attr("fill", "#d0d4d8")
        /*
              // An arc will be created
              var arc = d3.arc()
                .innerRadius(70)
                .outerRadius(90)
                .cornerRadius(15)
                //.startAngle(0)
                //.endAngle(6*Math.PI/4)
                .startAngle(0)//8 * Math.PI / 4)
                //.endAngle((((data.all / 100) * 8)) * Math.PI / 4)
                .endAngle((8 - ((data.all / 100) * 8)) * Math.PI / 4)
                //.startAngle(6)
                //.endAngle(2)
                ;
        */
        var transitionDurationToCompleteArc = 2000;
        var arcCornerRounding = 10;
        var allAvgArc = d3.arc()
            .innerRadius(60)
            .outerRadius(75)
            .cornerRadius(arcCornerRounding)
            .startAngle(0);
        // 50 / 100 * 8   
        var allAvgPath = svg.append("path")
            .datum({ endAngle: 0 })// by default endAngle will be 0 and when it start tween then complete it
            .attr("class", "arc chart1")
            .attr("d", allAvgArc)
            .attr("fill", "#8d9cab")

        // arc tween code   
        // arc denominator = 5000 will be used in QM Cumulative metrics chart

        var totalArcDenominator = toggleType == "ccm" || toggleType == "qm_sub" ? 1000 : 5000;
        if (toggleType == "ccm" || toggleType == "qm" || toggleType == "qm_sub") {
            allAvgPath.transition()
                .duration(transitionDurationToCompleteArc)
                .attrTween("d", arcTween((((data.all / totalArcDenominator) * 8)) * Math.PI / 4, allAvgArc));
        }
        else {
            allAvgPath.transition()
                .duration(transitionDurationToCompleteArc)
                .attrTween("d", arcTween((((data.all / 100) * 8)) * Math.PI / 4, allAvgArc));
        }

        allAvgPath.append("title")
            .text(data.all + percentage);

        // Returns a tween for a transition’s "d" attribute, transitioning any selected
        // arcs from their current angle to the specified new angle.
        function arcTween(newAngle, arc) {

            // The function passed to attrTween is invoked for each selected element when
            // the transition starts, and for each element returns the interpolator to use
            // over the course of transition. This function is thus responsible for
            // determining the starting angle of the transition (which is pulled from the
            // element’s bound datum, d.endAngle), and the ending angle (simply the
            // newAngle argument to the enclosing function).
            return function (d) {

                // To interpolate between the two angles, we use the default d3.interpolate.
                // (Internally, this maps to d3.interpolateNumber, since both of the
                // arguments to d3.interpolate are numbers.) The returned function takes a
                // single argument t and returns a number between the starting angle and the
                // ending angle. When t = 0, it returns d.endAngle; when t = 1, it returns
                // newAngle; and for 0 < t < 1 it returns an angle in-between.
                var interpolate = d3.interpolate(d.endAngle, newAngle);

                // The return value of the attrTween is also a function: the function that
                // we want to run for each tick of the transition. Because we used
                // attrTween("d"), the return value of this last function will be set to the
                // "d" attribute at every tick. (It’s also possible to use transition.tween
                // to run arbitrary code for every tick, say if you want to set multiple
                // attributes from a single function.) The argument t ranges from 0, at the
                // start of the transition, to 1, at the end.
                return function (t) {

                    // Calculate the current arc angle based on the transition time, t. Since
                    // the t for the transition and the t for the interpolate both range from
                    // 0 to 1, we can pass t directly to the interpolator.
                    //
                    // Note that the interpolated angle is written into the element’s bound
                    // data object! This is important: it means that if the transition were
                    // interrupted, the data bound to the element would still be consistent
                    // with its appearance. Whenever we start a new arc transition, the
                    // correct starting angle can be inferred from the data.
                    d.endAngle = interpolate(t);

                    // Lastly, compute the arc path given the updated data! In effect, this
                    // transition uses data-space interpolation: the data is interpolated
                    // (that is, the end angle) rather than the path string itself.
                    // Interpolating the angles in polar coordinates, rather than the raw path
                    // string, produces valid intermediate arcs during the transition.
                    return arc(d);
                };
            };
        }
        // end arc tween code

        // inner complete circle
        // An arc will be created
        var innerCompleteArc = d3.arc()
            .innerRadius(42)
            .outerRadius(60)
            .startAngle(0)
            .endAngle(8 * Math.PI / 4);

        svg.append("path")
            .attr("class", "innerCompleteArc")
            .attr("d", innerCompleteArc)
            .attr("fill", "#F2F2F2");

        // An arc will be created
        /*
        var innerProgressArc = d3.arc()
          .innerRadius(50)
          .outerRadius(70)
          .cornerRadius(arcCornerRounding)
          //.startAngle(0)
          //.endAngle(6*Math.PI/4)
          .startAngle(8 * Math.PI / 4)
          //.endAngle(2 * Math.PI / 4)
          .endAngle((8 - ((data.current / 100) * 8)) * Math.PI / 4)
          //.startAngle(6)
          //.endAngle(2)
          ;
       
        svg.append("path")
          .attr("class", "innerProgressArc")
          .attr("d", innerProgressArc)
          .attr("fill", "#E67474");*/

        var innerProgressArc = d3.arc()
            .innerRadius(42)
            .outerRadius(60)
            .cornerRadius(arcCornerRounding)
            .startAngle(0);

        // 50 / 100 * 8   
        var innerProgressArcPath = svg.append("path")
            .datum({ endAngle: 0 })// by default endAngle will be 0 and when it start tween then complete it
            //.attr("class", "arc chart1")
            .attr("d", innerProgressArc)
            .attr("fill", "#E67474");

        // arc tween code     
        // arc denominator = 5000 will be used in QM Cumulative metrics chart

        debugger
        console.log(memberListName)
        var totalArcDenominator = toggleType == "ccm" || toggleType == "qm_sub" ? totalCallsMine : 5000;
        if (toggleType == "ccm" || toggleType == "qm" || toggleType == "qm_sub") {
            innerProgressArcPath.transition()
                .duration(transitionDurationToCompleteArc)
                .attrTween("d", arcTween((((data.current / totalCallsMine) * 8)) * Math.PI / 4, innerProgressArc));
        }
        else {
            innerProgressArcPath.transition()
                .duration(transitionDurationToCompleteArc)
                .attrTween("d", arcTween((((data.current / 100) * 8)) * Math.PI / 4, innerProgressArc));
        }

        // inner circle
        // An arc will be created
        var innerCircleArc = d3.arc()
            .innerRadius(0)
            .outerRadius(42)
            .cornerRadius(13)
            .startAngle(0)
            .endAngle(8 * Math.PI / 4);

        svg.append("path")
            .attr("class", "innerCircleArc")
            .attr("d", innerCircleArc)
            .attr("fill", "#33454F")
            .on("click", function (d) {
                d3.select(this)
                    .transition()
                    .duration(50)
                    .style("fill", "blue")
                    .transition()
                    .duration(250)
                    .style("fill", "#33454F");

                // download excel file
                var regex = /[.,\s]/g;
                var selectedPercentage = localStorage.selectedPercentage
                var selectedCareCoordinator = localStorage.selectedCareCoordinatorName.split(" ")[0]
                var clickedMetricText = d3.select(this.parentNode).select(".innerLabel").text();
                var selectedLabel = "";

                if (clickedMetricText == "Total Calls") {
                    selectedLabel = "Total Calls";
                }
                else if (clickedMetricText == "Total Duration(min)") {
                    selectedLabel = "Total Duration(min)";
                }
                else if (clickedMetricText == "Booked") {
                    selectedLabel = "Booked";
                }
                else if (clickedMetricText == "Completed Calls") {
                    selectedLabel = "Completed Calls";
                }
                else if (clickedMetricText == "Missed/ Busy") {
                    selectedLabel = "Missed/ Busy";
                }
                var file = "Metrics/" + selectedCareCoordinator + "_" + selectedPercentage + "_" + selectedLabel + ".xlsx";
                window.location.href = file;
            })
            .on("mouseover", function () {
                d3.select(this).classed("highlighted", true);
            })
            .on("mouseout", function () {
                d3.select(this).classed("highlighted", false);
            });

        //   debugger;
        if ((toggleType == "qm" || toggleType == "qm_sub") && data.label == "Ineligibles") {
            // append inner stats
            svg.append("text")
                .attr("y", -15)
                .attr("x", -100)
                .attr("class", "innerAllCoordinates")
                .text("0");
            // append inner stats
            svg.append("text")
                .attr("y", 100)
                .attr("x", -100)
                .attr("class", "innerStats")
                .text("0");
        }
        else {
            // append inner stats
            svg.append("text")
                .attr("y", -15)
                .attr("class", "innerAllCoordinates")
                .text(data.all + percentage);
            // append inner stats
            svg.append("text")
                .attr("y", -5)
                .attr("class", "innerStats")
                .text(data.totalCallsMine ? data.totalCallsMine : data.current + percentage);
        }

        // append label    
        svg.append("text")
            .attr("y", 15)
            .attr("class", "innerLabel")
            .text(data.label)

        // append label for second line
        svg.append("text")
            .attr("y", 27)
            .attr("class", "innerLabel")
            .text(data.nextLabel)
    }

    // read from csv and generate metrics
    //readDataFromCSVToGenerateMetrics();

    function readDataFromCSVToGenerateMetrics() {
        //d3.csv("http://localhost/circos/Encounter_7_28_incl_CMS_Provider_and_Quality_small_csv_new.csv")
        //d3.csv("http://localhost/circos/3500_Member_Table_3.csv")
        //d3.csv("http://localhost/circos/MetricForCM-CodeReadable.csv")
        d3.csv(baseURL + "Data/23-10-2021_Updated.csv")
            .on("progress", function (evt) {
                //console.log("Amount loaded: " + evt.loaded)
            })
            .get(function (data) {
                // 1. Extract d["Mbr_Unique"] == "1" patients only
                // 2. find distinct care-coordinators
                // 3. 
                var selectedPatients = data.filter(function (d) {
                    var careCoordinatorNameWithoutSpaces = JSON.parse(JSON.stringify(d["care_cord_name"])).replaceAll(/\s/g, '');

                    if (d["Mbr_Risk_Score"] != "" && d["Mbr_Unique"] == "1") {
                        // patientsPerCareCoordinator[CareCoordinators[i].id]
                        if (patientsPerCareCoordinator[careCoordinatorNameWithoutSpaces] == undefined) {
                            patientsPerCareCoordinator[careCoordinatorNameWithoutSpaces] = [];
                            patientsPerCareCoordinator[careCoordinatorNameWithoutSpaces].push(d);

                            distinctCareCoordinators.push(d["care_cord_name"]);
                        }
                        else {
                            patientsPerCareCoordinator[careCoordinatorNameWithoutSpaces].push(d);
                        }

                        return {
                            DESYNPUF_ID: d["Mbr_ID"], // patient id
                            RiskScore: d["Mbr_Risk_Score"],
                            CareCoordinatorName: d["care_cord_name"],
                        };
                    }
                }
                );

            });
    }

    return (
        <>
            {loadingEnable === true || chartsLoadingEnable === true ?
                <>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', position: 'absolute', top: '50%', left: '50%' }}>
                        <CircularProgress />
                    </Box>
                </> : ""
            }
            {/* <div className="loaderContainer" id="loader">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div> 
                </div> */}
            {/* <span className="text-white position-absolute selectedCoordinator" id="selectedCareCoordinator">{userName}</span> */}
            <div className="text-white position-absolute selectedCoordinator" id="selectedCareCoordinator">
                <div className="dropdown d-flex flex-row-reverse">
                    {/* <a href="#" className="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
                        id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false" >
                    </a>
                    <ul className="dropdown-menu dropdown-menu-dark text-small shadow">
                        <li>
                            <a className="dropdown-item" href="#"> Settings </a>
                        </li>
                        <li>
                            <a className="dropdown-item" href="#"> Profile </a>
                        </li>
                        <li>
                            <hr className="dropdown-divider" />
                        </li>
                        <li>
                            <a className="dropdown-item" href="#" onClick={handleLogout}> Sign out </a>
                        </li>
                    </ul> */}
                    <ul className="ms-1 me-2 my-0 list-unstyled">
                        <li className="fw-bold" id="careCoordinatorName"></li>
                        <li className="fw-bold" id="careCoordinatorType"></li>
                    </ul>
                    <div>
                        <p id="userName" className="p-0 m-0"> {userName} </p>
                        <p id="userProfestion" className="p-0 m-0"> {userProfession} </p>
                    </div>

                    <img src="https://img.freepik.com/free-photo/portrait-smiling-handsome-male-doctor-man_171337-5055.jpg?size=626&ext=jpg"
                        width="37" height="37" className="rounded me-2" alt="Portrait Smiling" />
                </div>
            </div>
            <nav className="navbar navbar-expand-lg navbar-dark bg-transparent">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#"></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link to={"/"} className="nav-link linkHomePage active" onClick={careConsoleHandle} aria-current="page"><FontAwesomeIcon className="fs-4" icon={faHouse} /></Link>
                            </li>
                            {/* <li className="nav-item">
						<a className="nav-link active" aria-current="page" href="javascript:void(0)">Welcome Mr. Shahbaz</a>
					</li>
					<li className="nav-item">
						<a className="nav-link" href="javascript:void(0)">
							<img src="images/profile.jpg" width="35" height="35" className="rounded-circle"
								style="margin-top: -7px;">
						</a>
					</li>
					<li className="nav-item dropdown">
						<a className="nav-link dropdown-toggle ps-0 active" href="javascript:void(0)"
							id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false"
							data-bs-auto-close="outside">Welcome To Care Management System
						</a>
						<ul className="dropdown-menu config-dropdown" aria-labelledby="navbarDropdownMenuLink">
							<li><a className="dropdown-item" href="javascript:void(0)">Profile</a></li>
							<li>
								<hr className="dropdown-divider">
							</li>
							<li><a className="dropdown-item" href="javascript:void(0)">Sign out</a></li>
						</ul>	
					</li>  */}
                            <li className="nav-item dropdown">
                                <a className="nav-link notification active" href="#" id="navbarNotificationsLink"
                                    role="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                    <FontAwesomeIcon className="fs-4" icon={faBell} />
                                    <span className="count rounded-circle position-absolute">1</span>
                                </a>
                                <ul className="dropdown-menu dropdown-menu-start notifications-dropdown start-5"
                                    aria-labelledby="navbarNotificationsLink">
                                    <li>
                                        <a className="dropdown-item" href="#">You have one high priority email</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <Link to={"/"} className="simple-btn login-btn" onClick={careConsoleHandle}>
                                    Care Console
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            <div className="bg-container">
                <div className="form-group search align-items-center datePicker align-middle text-end" style={{ display: "flex", color: "#9da7aa", fontSize: "12px", marginLeft: "350px" }}>
                    <label className="text-nowrap mx-1 me-5" id="todayDate" style={{ fontWeight: "bold", marginRight: "100px !important" }}>{Date()}</label>
                    <label for="startDate" className="text-nowrap mx-1 ms-4">Start Date:</label>
                    <input type="date" id="startDate" value={startDate} onChange={startDateHandle} className="form-control rounded border-0 px-1" /> <br />
                    <label for="endDate" className="text-nowrap mx-1">End Date:</label>
                    <input type="date" id="endDate" value={endDate} onChange={endDateHandle} className="form-control rounded border-0 px-1" />
                    <button onClick={datesRangeHandle} id="datesRangeSubmit" className="simple-btn ms-2">Submit</button>
                </div>
                {/* <img src="Images/bg.png" /> */}
            </div>
            <div className={`contentContainer pt-1 ${loadingEnable === true ? "opacity-50" : ""} pb-2`}>
                <div className="row dashboard mx-0 pt-4">
                    <div className="col-sm-12 col-lg-1 col-xl-1"></div>
                    <div className="col-sm-10 col-lg-2 col-xl-2">
                        <div className="row ribbon-off-on text-center">
                            <div className="col-7">
                                <div className="me-2 text-white text-end">
                                    Survey
                                    {/* <div className="i-tooltip"><i className="fas fa-info-circle info-link"></i>
								<span className="tooltiptext open-end tooltiptext-end">
									25th and 75th Percentile of selected care coordinator call logs
								</span>
							</div>  */}
                                </div>
                            </div>
                            <div className="col-5">
                                <label className="switch">
                                    <input type="checkbox" onChange={toogleSurveyHandle} id="toogleSwitchSurvey" />
                                    <div className="slider round">
                                        <span className="on">ON</span>
                                        <span className="off">OFF</span>
                                    </div>
                                </label>
                            </div>
                        </div>
                        {/* <div className="d-flex justify-content-between text-white ribbon-off-on text-center">
                            <span className="ms-2 text-end">Inbound Call</span>
                            <label className="switch">
                                <input type="checkbox" id="toogleSwitchInBoundCalls" />
                                <div className="slider inBoundCallBtn round">
                                    <span className="on">ON</span>
                                    <span className="off">OFF</span>
                                </div>
                            </label>
                        </div> */}
                    </div>
                    <div className="col-sm-10 col-lg-2 col-xl-2">
                        <div className="row ribbon-off-on">
                            <div className="col-9">
                                <div className="text-white text-end">
                                    Prior Auth Status
                                </div>
                            </div>
                            <div className="col-3">
                                <label className="switch">
                                    <input type="checkbox" onChange={tooglePauthSHandle} id="toogleSwitchPauthS" />
                                    <div className="slider round">
                                        <span className="on">ON</span>
                                        <span className="off">OFF</span>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className="row ribbon-off-on">
                            <div className="col-9">
                                <div className="text-white text-end">
                                    Prior Auth Q
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="d-flex">
                                    <label className="switch">
                                        <input type="checkbox" onChange={tooglePauthQHandle} id="toogleSwitchPauthQ" />
                                        <div className="slider switchPauthQDashboard round">
                                            <span className="on">ON</span>
                                            <span className="off">OFF</span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-10 col-lg-2 col-xl-2">
                        <div className="row ribbon-off-on text-center">
                            <div className="col-8">
                                <div className="text-white text-end">
                                    VisitR
                                </div>
                            </div>
                            <div className="col-4">
                                <label className="switch">
                                    <input type="checkbox" onChange={visitReminderHandle} id="toogleGenerateMetricChart" />
                                    <div className="slider round">
                                        <span className="on">ON</span>
                                        <span className="off">OFF</span>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className="row ribbon-off-on text-center">
                            <div className="col-8">
                                <div className="text-white text-end">
                                    CareR
                                </div>
                            </div>
                            <div className="col-4">
                                <label className="switch">
                                    <input type="checkbox" onChange={careReminderHandle} id="toogleAllCareCoordinators" />
                                    <div className="slider round">
                                        <span className="on">ON</span>
                                        <span className="off">OFF</span>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-10 col-lg-2 col-xl-2">
                        <div className="row ribbon-off-on">
                            <div className="col-6">
                                <div className="text-white text-end">
                                    LeadG
                                </div>
                            </div>
                            <div className="col-6">
                                <label className="switch">
                                    <input type="checkbox" onChange={toogleLeadGHandle} id="toogleSwitchLeadG" />
                                    <div className="slider round">
                                        <span className="on">ON</span>
                                        <span className="off">OFF</span>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className="row ribbon-off-on">
                            <div className="col-6">
                                <div className="text-white text-end">
                                    AwareC
                                </div>
                            </div>
                            <div className="col-6">
                                <label className="switch">
                                    <input type="checkbox" onChange={toogleAwareCHandle} id="toogleAwareC" />
                                    <div className="slider round">
                                        <span className="on">ON</span>
                                        <span className="off">OFF</span>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-2 col-lg-2 col-xl-2 excelBtn">
                        <ul className="position-relative">
                            <li>
                                <a href="#" className="messages-btn"><FontAwesomeIcon style={{ color: "white" }} icon={faEnvelope} /></a>
                            </li>
                            <li className="ori-calculation position-absolute p-1 mt-1" id="roiWindow">
                                <label className="text-white" for="roi-calculation" id="rOILabel">ROI Calculation</label>
                                <input className="ori-input" type="text" id="roiValueBox" />
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={`row dashboard ${chartsLoadingEnable === true ? "opacity-50" : ""} mx-0 pt-3`}>
                    <div className="col-sm-12 col-lg-1 col-xl-1"></div>
                    <div className="col-sm-12 col-lg-10 col-xl-10 text-center" id="column1">
                        {/* <div id='histogramChart' className="histogramContainer"></div> */}
                        <div className="bg-secondary p-2 mb-3">
                            <h4 className="text-white">Hello {userName}</h4>
                            <p className="text-white">Our AI called {totalCallsMine ? totalCallsMine : "no"} people has saved you {totalDurationInHours} {totalDurationInMin} {secRemainingFromMin} {!totalDurationInHours && !totalDurationInMin && !secRemainingFromMin ? "0 calls" : ""} this month by making
                                calls for you</p>
                            <h6 className="text-white">{bookedCallsMine} booked this month</h6>
                        </div>
                        <div className="bg-secondary p-2 mb-3 d-none">
                            <h4>Week Graph</h4>
                        </div>
                        <div className="bg-secondary p-2 mb-3 d-none">
                            <h4>Month Graph</h4>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-5 col-xl-5 mt-2 text-center" id="column2" style={{ display: "none" }}>
                        <div className="text-white d-none" id="qmSubModuleTitle"></div>
                        <div className="row">
                            <div className="col-6 text-right" id="renderToDivCircle1"></div>
                            <div className="col-6 text-left" id="renderToDivCircle2"></div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center" id="renderToDivCircle3"></div>
                        </div>
                        <div className="row">
                            <div className="col-6 text-right" id="renderToDivCircle4"></div>
                            <div className="col-6 text-left" id="renderToDivCircle5"></div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-1 col-xl-1">
                    </div>
                </div>
            </div>
            <div className="user-info d-none m-fadeOut" id="divCareCoordinatorContainer">
                <div className="profile-img" id="imgCareCoordinatro">
                    <img src="Images/image-6.jpg" width="100" alt=""></img>
                </div>
                <div className="coordinatorTable" id="careCoordinatorDetail">
                    <h2 id="lblCoordinatorName"></h2>
                    <span id="lblCoordinatorLevel1Detail" style={{ display: "none" }}></span>
                    <table>
                        <tr>
                            <td>Years of Exp:</td>
                            <td className="titleValue" id="lblYearOfExp">4</td>
                        </tr>
                        <tr>
                            <td>Avg. Risk Score:</td>
                            <td className="titleValue" id="lblAvgRiskScore">5.5</td>
                        </tr>
                        <tr>
                            <td>Avg. Call Hours:</td>
                            <td className="titleValue" id="lblAvgCallsHour">125</td>
                        </tr>
                    </table>
                </div>
                <div className="close">
                    <button type="button" id="closeCareCoordinatorDetailBox">x</button>
                    <a href="#">Contact</a>
                </div>
            </div>
        </>
    );
};
