import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Tooltip } from "@mui/material";
import { faCircleXmark, faEllipsis, faThumbtack } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { useDispatch } from "react-redux";
import { login, logout } from "../features/userSlice";
import { v4 as uuidv4 } from 'uuid';
import { useContextStateManagement } from "../context/ContextApi";

export const AwareCGrid = ({ providerName, patientName, startDate, endDate, isUploadLead }) => {
  const [modalShow, setModalShow] = useState(false)
  const [activeModal, setActiveModal] = useState();
  const [userId, setUserId] = useState();
  const [menuId, setMenuId] = useState();
  const [awareNessCGridData, setAwareNessCGridData] = useState([]);
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [transcriptRecord, setTranscriptRecord] = useState([]);
  const dispatch = useDispatch();
  const { PracticeId, token, usersPostRequest, fetchUserRecord, setSideBarLinkActive, setToken, dateRangeRequest } = useContextStateManagement();

  const columns = [
    {
      field: "thumbtackIcon",
      headerName: "Pin",
      width: 50,
      renderCell: () => <FontAwesomeIcon icon={faThumbtack} />,
    },
    { field: "Status", headerName: "Status", width: 80 },
    {
      field: "CreateDateTime", headerName: "Date", width: 120,
      renderCell: (params) =>
        moment(params.value).format("MM/DD/YYYY"),
    },
    {
      field: "CallDetails",
      headerName: "Call Details",
      width: 100,
      renderCell: (params) => (
        <div className="text-center position-relative userImageCallDiv">
          <div className="callDiv d-flex justify-content-between mb-3">
            <p className="userCallInformation m-0" title="Total Duration">{params.row.LastCallDuration}</p>
            <p className="userCallInformation m-0" title="Completed">{params.row.MissedCalls}</p>
          </div>
          <div className="callDiv d-flex justify-content-between callLastDiv">
            <p className="userCallInformation m-0" title="Busy">{params.row.TotalCallAttempts}</p>
            <p className="userCallInformation m-0" title="No Answer">{params.row.LastCallDuration}</p>
          </div>
        </div>
      ),
    },
    {
      field: "patientDetails",
      headerName: "Name",
      width: 140,
      renderCell: (params) => (
        <div>
          <p className="multipleObjCol m-0 mb-1">{params.row.PatientFullName}</p>
          <p className="multipleObjCol m-0 mt-1">{new Date(params.row.PatientDateOfBirth).toLocaleDateString()}</p>
        </div>
      ),
    },
    { field: "PatientEmailAddress", headerName: "Email", width: 190 },
    { field: "PatientContactNumber", headerName: "Patient Contact Number", width: 200, },
    {
      field: "ProviderDetails", headerName: "Provider Details", width: 150,
      renderCell: (params) => (
        <div>
          <p className="multipleObjCol m-0 mb-1">{params.row.ProviderName}</p>
          <p className="multipleObjCol m-0 mt-1">{params.row.ProviderFacilityName}</p>
        </div>
      ),
    },
    { field: "Transcript", headerName: "Transcript", width: 150 },
    { field: "CallSummary", headerName: "Summary", width: 150 },
    { field: "menu", headerName: "Menu", width: 80, renderCell: (params) => <Tooltip title="Transcript"> <FontAwesomeIcon icon={faEllipsis} onClick={() => menuHandle(params.row.Id)} /> </Tooltip> },
    {
      field: "DoNotCall", headerName: "Do Not Call", width: 100,
      renderCell: (params) => <FontAwesomeIcon icon={faCircleXmark} className={`text-center ${params.row.DoNotCall === true ? "activeTrue" : ""} cursor-pointer`} onClick={() => DoNotCallHandle(params.row.Id)} />
    },
  ];

  const menuHandle = (id) => {
    setMenuId(id)
    setModalShow(true)
    setActiveModal("menu");
  }

  const DoNotCallHandle = (id) => {
    setUserId(id)
    setModalShow(true)
    setActiveModal("doNotCall");
  }

  async function awareNessCRFetchHandle() {
    const userData = await fetchUserRecord(token, `/api/AwarenessCampaignUploadLead/${PracticeId}`);
    if (userData == "Token is expired, login again") {
      setIsTokenExpired(true)
    }
    else {
      setAwareNessCGridData(userData);
    }
  }

  let doNotCall = false;
  const cancelCallHandle = async (e) => {
    doNotCall = true;
    let id = userId;
    let obj = { doNotCall, id };

    let url = `/api/AwarenessCampaignUploadLeadDoNotCall`;
    const userData = await usersPostRequest(token, url, obj);
    if (userData == "Token is expired, login again") {
      setIsTokenExpired(true)
    }
    else {
      awareNessCRFetchHandle()
      setModalShow(false)
    }
  }

  const allowCallHandle = async (e) => {
    doNotCall = false;
    let id = userId;
    let obj = { doNotCall, id };

    let url = `/api/AwarenessCampaignUploadLeadDoNotCall`;
    const userData = await usersPostRequest(token, url, obj);
    if (userData == "Token is expired, login again") {
      setIsTokenExpired(true)
    }
    else {
      awareNessCRFetchHandle()
      setModalShow(false)
    }
  }

  const getRowId = () => uuidv4();

  async function handleSearchInputChange() {
    let url = `/api/SearchAwarenessCampaignUploadLead`;
    let obj = { patientName, providerName, PracticeId }
    const userData = await usersPostRequest(token, url, obj);
    if (userData == "Token is expired, login again") {
      setIsTokenExpired(true)
    }
    else {
      setAwareNessCGridData(userData);
    }
  }

  async function handleStartDateChange() {
    let url = "/api/SearchWithDateAwarenessCampaign";
    let obj = {
      startDate: startDate,
      endDate: endDate,
      PracticeId: PracticeId
    }
    const userData = await dateRangeRequest(token, url, obj);
    if (userData == "Token is expired, login again") {
      setIsTokenExpired(true)
    }
    else {
      setAwareNessCGridData(userData);
    }
  }

  const filterTranscriptRecord = () => {
    // if (priorAuthQGridData.length > 0 && menuId != null) {
    let filteredData = awareNessCGridData.filter((e) => e.Id === menuId);
    setTranscriptRecord(filteredData);
    // }
  }

  useEffect(() => {
    if (menuId != null) {
      filterTranscriptRecord()
    }
  }, [menuId])

  useEffect(() => {
    if (isTokenExpired == true) {
      alert("Token is expired, login again");
      setIsTokenExpired(false);
      setToken("");
      dispatch(logout());
    }
  }, [isTokenExpired])

  useEffect(() => {
    if (providerName || patientName) {
      setSideBarLinkActive("careConsole");
      handleSearchInputChange();
    } else {
      awareNessCRFetchHandle()
    }
  }, [providerName, patientName])

  useEffect(() => {
    if (startDate || endDate) {
      setSideBarLinkActive("careConsole")
      handleStartDateChange();
    } else {
      awareNessCRFetchHandle()
    }
  }, [startDate, endDate])

  useEffect(() => {
    if (PracticeId && token || isUploadLead === true) {
      awareNessCRFetchHandle();
    }
  }, [PracticeId, token, isUploadLead])

  return (
    <>
      <div className="col-lg-12">
        <div className="grid-layout">
          <DataGrid
            rows={awareNessCGridData ? awareNessCGridData : []}
            columns={columns}
            autoWidth
            pageSize={12}
            // loading={awareNessCGridData.length === 0} 
            getRowId={getRowId}
 	    disableColumnSorting
            disableColumnMenu
          />
        </div>
      </div>
      <Modal className="popup" show={modalShow} onHide={() => setModalShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title> {activeModal === "menu" ? "Transcripts" : "Cancel Call "}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {activeModal === "menu" ? <p>{transcriptRecord.length > 0 ? transcriptRecord[0].Transcript : "No Transcript of current Record"}</p> : <p>Are you sure that you can't call this lead</p>}
        </Modal.Body>
        <Modal.Footer>
          {
            activeModal === "menu" ?
              <button className="simple-btn" onClick={() => setModalShow(false)}>No</button> :
              <button className="simple-btn me-2" id="closeTrue" onClick={allowCallHandle}>No</button>
          }
          {activeModal === "menu" ?
            <button className="simple-btn" onClick={() => setModalShow(false)}>Yes</button> :
            <button className="simple-btn" id="updateTrue" onClick={cancelCallHandle}>Yes</button>
          }
        </Modal.Footer>
      </Modal>
    </>
  );
};
