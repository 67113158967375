import * as React from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { validEmail } from "../Variables";
import { useContextStateManagement } from "../context/ContextApi";

const { useState } = React;

export const Register = () => {
  const [practiceNameMessage, setPracticeNameMessage] = useState("");
  const [practiceAddressMessage, setPracticeAddressMessage] = useState("");
  const [practiceContactMessage, setPracticeContactMessage] = useState("");
  const [emailMessage, setEmailMessage] = useState("");
  const [passwordMessage, setPasswordMessage] = useState("");
  const [nameMessage, setNameMessage] = useState("");
  const [professionMessage, setProfessionMessage] = useState("");
  const { setCurrentUrl } = useContextStateManagement();
  const navigate = useNavigate();

  const handleRegister = (e) => {

    // e.target.setAttribute("disabled", true);
    document.querySelector("body").style.cursor = "progress";
    let pracName = document.getElementById("practiceName");
    let pracAddress = document.getElementById("practiceAddress");
    let pracContact = document.getElementById("practiceContactNumber");
    let email = document.getElementById("userEmail");
    let pass = document.getElementById("userPassword");
    let name = document.getElementById("userName");
    let profession = document.getElementById("userProfession");
    let isFieldEmpty = false;

    let user = {
      PracticeName: pracName.value,
      PracticeAddress: pracAddress.value,
      PracticeContactNumber: pracContact.value,
      UserName: name.value,
      UserProfession: profession.value,
      UserEmail: email.value,
      UserPassword: pass.value,
    };

    // let errorMessage = [];

    if (user.PracticeName === "") {
      pracName.classList.add("empty");
      isFieldEmpty = true;
      setPracticeNameMessage("Enter Practice Name");
    }

    if (user.PracticeAddress === "") {
      pracAddress.classList.add("empty");
      isFieldEmpty = true;
      setPracticeAddressMessage("Enter Practice Address");
    }

    if (user.PracticeContactNumber === "") {
      pracContact.classList.add("empty");
      isFieldEmpty = true;
      setPracticeContactMessage("Enter Practice Contact Number");
    }

    if (user.UserName === "") {
      name.classList.add("empty");
      isFieldEmpty = true;
      setNameMessage("Enter User Name");
    }

    if (user.UserProfession === "") {
      profession.classList.add("empty");
      isFieldEmpty = true;
      setProfessionMessage("Enter User Profession");
    }

    if (user.UserEmail === "") {
      email.classList.add("empty");
      isFieldEmpty = true;
      setEmailMessage("Enter your email");
    } else if (
      user.UserEmail !== "" &&
      validEmail.test(user.UserEmail) === false
    ) {
      email.classList.add("empty");
      isFieldEmpty = true;
      setEmailMessage("Enter a valid email");
    }
    console.log(user.UserPassword.length)

    if (user.UserPassword === "") {
      pass.classList.add("empty");
      isFieldEmpty = true;
      setPasswordMessage("Enter password");
    } else if (user.UserPassword.length < 6) {
      isFieldEmpty = true
      setPasswordMessage("Enter 6 digits / characters or more");
    }

    if (isFieldEmpty === true) {
      return;
    }

    axios.post('/api/register', user)
      .then(response => {
        if (isFieldEmpty === false) {
          pracName.value = "";
          pracAddress.value = "";
          pracContact.value = "";
          name.value = "";
          profession.value = "";
          email.value = "";
          pass.value = "";
        }
        if (response) {
          navigate("/login");
          setCurrentUrl(window.location.href);
          alert("User registered successfully")
        } else {
          alert("Username already exist")
        }
        // setEmailMessage("User registered successfully")
      })
      .catch(error => {
        if (error) {
          alert(error.response ? error.response.data.error : "Server is not responding, Check your connection string")
        }
        // setEmailMessage("Username Already exist");
        // alert("Username already exist")
      })
  }

  const resetErrorFieldStyle = (e) => {
    if (e.target.classList.contains("empty")) {
      e.target.classList.remove("empty");
    }

    if (e.target.id === "practiceName") {
      setPracticeNameMessage("");
    } else if (e.target.id === "practiceAddress") {
      setPracticeAddressMessage("");
    } else if (e.target.id === "practiceContactNumber") {
      setPracticeContactMessage("");
    } else if (e.target.id === "userName") {
      setNameMessage("");
    } else if (e.target.id === "userProfession") {
      setProfessionMessage("");
    } else if (e.target.id === "userEmail") {
      setEmailMessage("");
    } else if (e.target.id === "userPassword") {
      setPasswordMessage("");
    }
  };

  const loginHandle = () => {
    navigate("/login");
    setCurrentUrl(window.location.href);
  }

  return (
    <>
      <div className="row login-start">
        <div className="col-12 col-sm-12 col-md-3 col-lg-4 col-xl-4"></div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
          <div className="row login-container register-container setting-login bg_dark_opacity">
            <div className="col-12">
              <h3 className="text-white mb-4">Sign Up</h3>
              <p className="text-white">Register to get success</p>
              <div className="form-group mb-3 pb-1 position-relative">
                <div className="col-sm-12">
                  <input
                    type="text"
                    id="practiceName"
                    className="bg-black text-white border-0 form-control"
                    placeholder="Enter Practice Name"
                    onFocus={resetErrorFieldStyle}
                  />
                </div>
                <small className="text-danger position-absolute fs-small login-field-error">
                  {practiceNameMessage}
                </small>
              </div>
              <div className="form-group mb-3 pb-1 position-relative">
                <div className="col-sm-12">
                  <input
                    type="email"
                    id="practiceAddress"
                    className="bg-black text-white border-0 form-control"
                    placeholder="Enter Practice Address"
                    onFocus={resetErrorFieldStyle}
                  />
                </div>
                <small className="text-danger position-absolute fs-small login-field-error">
                  {practiceAddressMessage}
                </small>
              </div>
              <div className="form-group mb-3 pb-1 position-relative">
                <div className="col-sm-12">
                  <input
                    type="text"
                    id="practiceContactNumber"
                    className="bg-black text-white border-0 form-control"
                    placeholder="Enter Practice Contact Number"
                    onFocus={resetErrorFieldStyle}
                  />
                </div>
                <small className="text-danger position-absolute fs-small login-field-error">
                  {practiceContactMessage}
                </small>
              </div>
              <div className="form-group mb-3 pb-1 position-relative">
                <div className="col-sm-12">
                  <input
                    type="text"
                    id="userName"
                    className="bg-black text-white border-0 form-control"
                    placeholder="Enter User Name"
                    onFocus={resetErrorFieldStyle}
                  />
                </div>
                <small className="text-danger position-absolute fs-small login-field-error">
                  {nameMessage}
                </small>
              </div>
              <div className="form-group mb-3 pb-1 position-relative">
                <div className="col-sm-12">
                  <input
                    type="text"
                    id="userProfession"
                    className="bg-black text-white border-0 form-control"
                    placeholder="Enter User Profession"
                    onFocus={resetErrorFieldStyle}
                  />
                </div>
                <small className="text-danger position-absolute fs-small login-field-error">
                  {professionMessage}
                </small>
              </div>
              <div className="form-group mb-3 pb-1 position-relative">
                <div className="col-sm-12">
                  <input
                    type="email"
                    id="userEmail"
                    className="bg-black text-white border-0 form-control"
                    placeholder="Enter User Id"
                    onFocus={resetErrorFieldStyle}
                  />
                </div>
                <small className="text-danger position-absolute fs-small login-field-error">
                  {emailMessage}
                </small>
              </div>

              <div className="form-group mb-3 py-1 position-relative">
                <div className="col-sm-12">
                  <input
                    type="password"
                    id="userPassword"
                    className="bg-black text-white border-0 form-control"
                    placeholder="Enter Password"
                    onFocus={resetErrorFieldStyle}
                  />
                </div>
                <small className="text-danger position-absolute fs-small login-field-error">
                  {passwordMessage}
                </small>
              </div>
              <div className="text-center mt-2">
                <button className="simple-btn login-btn mb-3" onClick={handleRegister}>Sign Up</button>
                <button className="simple-btn login-btn" onClick={loginHandle}>Login</button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-3 col-lg-4 col-xl-4"></div>
      </div>
    </>
  );
};
