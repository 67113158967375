import * as React from "react";
import { useContextStateManagement } from "../context/ContextApi";
import { resetErrorFieldStyle } from "../Variables";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../features/userSlice";

export const LeadGenerationOnboardingForm = () => {
    const { PracticeId, userProfession, token, usersPostRequest, setToken } = useContextStateManagement();
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const dispatch = useDispatch();
    let isAnyUploadFieldEmpty = false;

    const handleSubmit = async (e) => {
        let ProviderName = document.getElementById('ProviderName').value;
        let ProviderFacilityName = document.getElementById('ProviderFacilityName').value;
        let ProviderSpecialtyType = document.getElementById('ProviderSpecialtyType').value;
        let AdditionalOfferedService = document.getElementById('AdditionalOfferedService').value;
        let AdditionalOfferedServiceDetail = document.getElementById('AdditionalOfferedServiceDetail').value;
        let BetterServicesReason = document.getElementById('BetterServicesReason').value;
        let PatientIssues = document.getElementById('PatientIssues').value;
        let CustomerWebsite = document.getElementById('CustomerWebsite').value;
        let OperationalHours = document.getElementById('OperationalHours').value;
        let InsurancesAccepted = document.getElementById('InsurancesAccepted').value;

        document.querySelectorAll('.px-3').forEach((e) => {
            if (e.value === "") {
                e.classList.add("invalid-details")
            }
        })

        if (ProviderName === "" || ProviderFacilityName === "" || ProviderSpecialtyType === "" || PatientIssues === "" || AdditionalOfferedService === "" || AdditionalOfferedServiceDetail === "" || BetterServicesReason === "" || CustomerWebsite === "" || OperationalHours === "" || InsurancesAccepted === "") {
            isAnyUploadFieldEmpty = true;
        }

        if (isAnyUploadFieldEmpty === true) {
            alert('Please enter complete details of onboarding')
            return
        }

        let obj = {
            PracticeId: PracticeId,
            ProviderName: ProviderName,
            ProviderFacilityName: ProviderFacilityName,
            ProviderSpecialtyType: ProviderSpecialtyType,
            AdditionalOfferedService: AdditionalOfferedService,
            AdditionalOfferedServiceDetail: AdditionalOfferedServiceDetail,
            BetterServicesReason: BetterServicesReason,
            PatientIssues: PatientIssues,
            CustomerWebsite: CustomerWebsite,
            OperationalHours: OperationalHours,
            InsurancesAccepted: InsurancesAccepted
        }

        let url = '/api/LeadGenerationOnBoarding';
        const userData = await usersPostRequest(token, url, obj);
        if (userData == "Token is expired, login again") {
            setIsTokenExpired(true)
        }
        else if (userData && userData.message) {
            alert(userData.message)
        }

        if (ProviderName !== "" || ProviderSpecialtyType !== "" || PatientIssues !== "" || AdditionalOfferedService !== "" || AdditionalOfferedServiceDetail !== "" || BetterServicesReason !== "" || CustomerWebsite !== "" || OperationalHours !== "" || InsurancesAccepted !== "") {
            document.getElementById('ProviderName').value = "";
            document.getElementById('ProviderSpecialtyType').value = "";
            document.getElementById('PatientIssues').value = "";
            document.getElementById('AdditionalOfferedService').value = "";
            document.getElementById('AdditionalOfferedServiceDetail').value = "";
            document.getElementById('BetterServicesReason').value = "";
            document.getElementById('CustomerWebsite').value = "";
            document.getElementById('OperationalHours').value = "";
            document.getElementById('InsurancesAccepted').value = "";
        }
    };

    useEffect(() => {
        if (isTokenExpired === true) {
            alert("Token is expired, login again");
            setToken("")
            setIsTokenExpired(false)
            dispatch(logout());
        }
    }, [isTokenExpired]);

    return (
        <>
            <div className="container onboardingLayout py-4 px-4 shadow-sm">
                <div className="row p-3 col-md-5 m-auto login-container onboarding-forms bg_dark_opacity">
                    <div className="mb-3">
                        <h6 className="fw-bold mt-2 text-white mb-1">Lead Generation</h6>
                        <p className="text-white">Enter your practice information</p>
                    </div>
                    <div className="col">
                        <div className="mb-3">
                            <label className="text-white form-label">Provider Name</label>
                            <input type="text" onFocus={resetErrorFieldStyle} className="bg-black text-white border-0 py-2 px-3 form-control" id="ProviderName" placeholder="Enter your clinic name" />
                        </div>
                        <div className="mb-3">
                            <label className="text-white form-label">Clinic/Facility Name</label>
                            <input type="text" disabled onFocus={resetErrorFieldStyle} className="bg-black text-white border-0 py-2 px-3 form-control" value={userProfession} id="ProviderFacilityName" placeholder="Enter your clinic name" />
                        </div>
                        <div className="mb-3">
                            <label className="text-white form-label">Speciality Type</label>
                            <input type="text" onFocus={resetErrorFieldStyle} className="bg-black text-white border-0 py-2 px-3 form-control" id="ProviderSpecialtyType" placeholder="Cardiology" />
                        </div>
                        <div className="mb-3">
                            <label className="text-white form-label">Please explain the services offered by the Clinic</label>
                            <input type="text" onFocus={resetErrorFieldStyle} className="bg-black text-white border-0 py-2 px-3 form-control" id="AdditionalOfferedService" placeholder="Cardiology" />
                        </div>
                        <div className="mb-3">
                            <label className="text-white form-label">Please explain the additional services offered</label>
                            <textarea rows="4" onFocus={resetErrorFieldStyle} className="bg-black text-white border-0 py-2 px-3 form-control" placeholder="Enter your text"
                                id="AdditionalOfferedServiceDetail"></textarea>
                        </div>
                        <div className="mb-3">
                            <label className="text-white form-label">Why is your service better than others?</label>
                            <textarea rows="4" onFocus={resetErrorFieldStyle} className="bg-black text-white border-0 py-2 px-3 form-control" placeholder="Enter your text"
                                id="BetterServicesReason"></textarea>
                        </div>
                        <div className="mb-3">
                            <label className="text-white form-label">Enter your Customer or Patient pain points that your Company or Clinic solves</label>
                            <input type="text" onFocus={resetErrorFieldStyle} className="bg-black text-white border-0 py-2 px-3 form-control" id="PatientIssues" placeholder="Cardiology" />
                        </div>
                        <div className="mb-3">
                            <label className="text-white form-label">Customer or Internet Website</label>
                            <input type="text" onFocus={resetErrorFieldStyle} className="bg-black text-white border-0 py-2 px-3 form-control" id="CustomerWebsite" placeholder="CustomerWebsite" />
                        </div>
                        <div className="mb-3">
                            <label className="text-white form-label">Operational/Business Hours</label>
                            <input type="text" onFocus={resetErrorFieldStyle} className="bg-black text-white border-0 py-2 px-3 form-control" id="OperationalHours" placeholder="OperationalHours" />
                        </div>
                        <div className="mb-3">
                            <label className="text-white form-label">Insurances Accepted</label>
                            <input type="text" onFocus={resetErrorFieldStyle} className="bg-black text-white border-0 py-2 px-3 form-control" id="InsurancesAccepted" placeholder="InsurancesAccepted" />
                        </div>
                        <div className="text-center mb-2">
                            <a
                                href="javascript:void(0)"
                                className="simple-btn login-btn"
                                onClick={handleSubmit}
                            >
                                Save
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
