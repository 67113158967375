export const validEmail = new RegExp(
    '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Z|a-z]{2,}$'
);

export const validContactNumber = new RegExp(
    '^\\d{3}-\\d{3}-\\d{4}$'
);

export const isValidContactNumber = (number) => {
    return validContactNumber.test(number);
}

export const resetErrorFieldStyle = (e) => {
    if (e.target.classList.contains("invalid-Credentials")) {
        e.target.classList.remove("invalid-Credentials")
    } else if(e.target.classList.contains("invalid-details")){
        e.target.classList.remove("invalid-details")
    }
}


// export const resetErrorFieldStyleOnBoarding = (e) => {
//     if (e.target.classList.contains("invalid-details")) {
//         e.target.classList.remove("invalid-details")
//     }
// }