import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { login } from "../features/userSlice";
import { validEmail } from "../Variables";
import { useContextStateManagement } from "../context/ContextApi";
import { Link } from "react-router-dom";
import InputMask from 'react-input-mask';

export const Login = () => {
  const [emailMessage, setEmailMessage] = useState("");
  const [passwordMessage, setPasswordMessage] = useState("");
  const [userSuccess, setUserSuccess] = useState([]);
  const [qrCodeUrl, setQrCodeUrl] = useState();
  const [qrCodeMessage, setQrCodeMessage] = useState("");
  const [timeInSeconds, setTimeInSeconds] = useState(30);
  const [clickCount, setClickCount] = useState(0);
  const { setCurrentUrl, PracticeId, setSideBarLinkActive, token } = useContextStateManagement();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let userEnable = false;

  const getQRCode = async () => {
    await axios.get("/api/qrCodeUrl")
      .then(response => {
        setQrCodeUrl(response.data.response.QRCodeUrl);
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleSubmit = async (e) => {
    // e.target.setAttribute("disabled", true);
    document.querySelector("body").style.cursor = "progress";
    let email = document.getElementById("userEmail");
    let pass = document.getElementById("userPassword");
    let qrCodeForm = document.getElementById('qrCodeForm');
    let isFieldEmpty = false;

    let user = {
      UserEmail: email.value,
      UserPassword: pass.value,
    };

    // let errorMessage = [];

    if (user.UserEmail === "") {
      email.classList.add("empty");
      isFieldEmpty = true;
      setEmailMessage("Enter your email");
    } else if (
      user.UserEmail !== "" &&
      validEmail.test(user.UserEmail) === false
    ) {
      email.classList.add("empty");
      isFieldEmpty = true;
      setEmailMessage("Enter a valid email");
    }

    if (user.UserPassword === "") {
      pass.classList.add("empty");
      isFieldEmpty = true;
      setPasswordMessage("Enter your password");
    }

    if (isFieldEmpty === true) {
      // e.target.removeAttribute("disabled");
      // document.querySelector("body").style.cursor = "default";
      return;
    }

    if (user.UserEmail !== "" && validEmail.test(user.UserEmail) === true && user.UserPassword !== "") {

      axios.post('/api/login', user)
        .then(response => {
          if (response) {
            dispatch(login(response.data));
            getQRCode()
            setUserSuccess(response.data)
            if (qrCodeForm.classList.contains("d-none")) {
              qrCodeForm.classList.remove("d-none");
            }
            document.querySelectorAll('.form-hidden').forEach((elem) => {
              elem.classList.add("d-none")
            })
            userEnable = true;
            setSideBarLinkActive("careConsole");
            navigate("/");
          }
        })
        .catch(error => {
          // error.response ? setPasswordMessage(error.response.data.error) : "Server is not responding, Check your connection string"
          if (error.response.data.error) {
            setPasswordMessage(error.response.data.error)
          }
          else if (error.response.statusText) {
            setPasswordMessage("Server is not responding!")
          }
        })
    }
    //     e.target.removeAttribute("disabled");
    //     document.querySelector("body").style.cursor = "";
  };

  const resetErrorFieldStyle = (e) => {
    if (e.target.classList.contains("empty")) {
      e.target.classList.remove("empty");
    }

    if (e.target.id === "userEmail") {
      setEmailMessage("");
    } else if (e.target.id === "qrCode") {
      setQrCodeMessage("");
    } else {
      setPasswordMessage("");
    }
  };

  const signUpHandle = () => {
    navigate("/register");
    setCurrentUrl(window.location.href);
  }

  const verifyQRCodeHandle = async () => {

    let qrCodeVal = document.getElementById("qrCode").value;
    qrCodeVal = qrCodeVal.toString().replace(/ /g, '');
    let qrCode = parseInt(qrCodeVal);

    if (qrCodeVal === "") {
      setQrCodeMessage("Enter 6-digit code");
      return
    }

    axios.post("/api/verifyQRCode", { qrCode })
      .then(response => {
        if (response) {
          // dispatch(login(userSuccess))
          setQrCodeMessage("")
        }
      })
      .catch(error => {
        // error.response ? error.response.data.message : "Something went wrong";
        setQrCodeMessage("Enter Correct 6-digit code")
      })

  }

  const regenerateQRCodeHandle = async () => {

    // if (clickCount === 3) {
    //   qrCodeForm.classList.add("d-none");
    //   document.querySelectorAll('.form-hidden').forEach((elem) => {
    //     elem.classList.remove("d-none")
    //   })
    //   setTimeInSeconds("");
    //   return
    // }

    axios.post("/api/updateQRCode")
      .then(response => {
        if (document.getElementById("verifyQRCode").classList.contains("d-none")) {
          document.getElementById("verifyQRCode").classList.remove("d-none")
          setTimeInSeconds(30)
        }
        document.getElementById("regenerateQRCode").classList.add("d-none");
      })
      .catch(error => {
        console.log(error)
      })

    getQRCode()

  }


  useEffect(() => {
    if (qrCodeUrl && timeInSeconds > 0) {
      setTimeout(() => {
        setTimeInSeconds(timeInSeconds - 1);
      }, 1000);
    }

    if (timeInSeconds === 0) {
      if (document.getElementById("regenerateQRCode").classList.contains("d-none")) {
        alert('Resend qr code generate request')
        setQrCodeUrl("")
        document.getElementById("regenerateQRCode").classList.remove("d-none")
        document.getElementById("verifyQRCode").classList.add("d-none")
      }
    }

  }, [timeInSeconds, qrCodeUrl])

  return (
    <>
      <div className="row login-start">
        <div className="col-12 col-sm-12 col-md-3 col-lg-4 col-xl-4"></div>
        <div className="col-12 col-sm-12 col-md-3 col-lg-4 col-xl-4">
          <div className="row login-container bg_dark_opacity setting-login">
            <div className="col-12">
              <h3 className="text-white">Welcome!</h3>
              <p className="text-white">Login to get success</p>
              <div className="form-group form-hidden mb-3 py-1 position-relative">
                <div className="col-sm-12">
                  <input type="email" id="userEmail" className="bg-black text-white border-0 form-control" placeholder="Enter User Id" onFocus={resetErrorFieldStyle}
                  />
                </div>
                <small className="pb-2 text-danger position-absolute fs-small login-field-error">
                  {emailMessage}
                </small>
              </div>
              <div className="form-group form-hidden mb-3 py-1 position-relative">
                <div className="col-sm-12">
                  <input type="password" id="userPassword" className="bg-black text-white border-0 form-control"
                    placeholder="Enter Password" onFocus={resetErrorFieldStyle} />
                </div>
                <small className="pb-2 text-danger position-absolute fs-small login-field-error">
                  {passwordMessage}
                </small>
              </div>
              <div id="qrCodeForm" className="d-none">
                <div className={`form-group mb-3 py-1 position-relative`}>
                  <div className="col-sm-12 d-flex justify-content-center align-items-center">
                    <img src={qrCodeUrl} alt="QR Code" />
                  </div>
                  <small className="pb-2 text-danger position-absolute fs-small login-field-error">
                    {passwordMessage}
                  </small>
                </div>
                <div className="form-group mb-3 py-1 position-relative">
                  <div className="col-sm-12">
                    <InputMask mask="9 9 9 9 9 9" id="qrCode" className="bg-black text-center text-white border-0 form-control"
                      placeholder="Enter 6-digit code" onFocus={resetErrorFieldStyle} />
                  </div>
                  <small className="pb-2 text-danger position-absolute fs-small login-field-error">
                    {qrCodeMessage}
                  </small>
                </div>
                <div className="text-center position-relative">
                  <button id="verifyQRCode" className="simple-btn login-btn" onClick={verifyQRCodeHandle}>Verify <span className="position-absolute text-right">{timeInSeconds}s</span></button>
                </div>
                <div className="text-center">
                  <button id="regenerateQRCode" className="simple-btn login-btn d-none" onClick={regenerateQRCodeHandle}>Regenerate QR Code</button>
                </div>
              </div>

              <div className="text-center">
                <button className="simple-btn login-btn form-hidden mt-1" onClick={handleSubmit}>Login</button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-3 col-lg-4 col-xl-4"></div>
      </div>
    </>
  );
};
