import React, { useEffect, useState } from "react";
import { faCirclePlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";
import { isValidContactNumber, resetErrorFieldStyle } from "../Variables";
import { useContextStateManagement } from "../context/ContextApi";
import InputMask from 'react-input-mask';
import { useDispatch } from "react-redux";
import { logout } from "../features/userSlice";

export const LeadGForm = ({ closeModal, setIsUploadLead }) => {
  const [providerData, setProviderData] = useState([]);
  const [addRows, setAddRows] = useState([]);
  const [modalShow, setModalShow] = useState(false)
  const [providerId, setProviderId] = useState(0);
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const dispatch = useDispatch();
  const { PracticeId, token, setToken, fetchUserRecord, usersPostRequest } = useContextStateManagement();

  let isAnyUploadFieldEmpty = false;
  let isValidDetails = false;

  const isAnyFormFieldEmpty = () => {
    document.querySelectorAll('.uploadLeadForm-table-row').forEach(row => {
      const patientContactNumber = row.querySelector('.PatientContactNumber').value;

      row.querySelectorAll('.form-control').forEach((e) => {
        if (e.value === "") {
          e.classList.add("invalid-Credentials");
          isAnyUploadFieldEmpty = true;
        }
      })

      if (patientContactNumber !== "" && isValidContactNumber(patientContactNumber) === false) {
        row.querySelector('.PatientContactNumber').classList.add("invalid-Credentials");
        isValidDetails = false;
      }

      if (patientContactNumber !== "" && isValidContactNumber(row.querySelector('.PatientContactNumber').value) === true) {
        isValidDetails = true;
      }

      document.querySelectorAll('.uploadLeadForm-table-row').forEach((e) => {
        e.querySelectorAll('.form-control').forEach((e) => {
          if (e.classList.contains("invalid-Credentials")) {
            isValidDetails = false;
          }
        })
      })

    })
    if (isAnyUploadFieldEmpty === true) {
      alert("Please enter complete details of all leads")
      isAnyUploadFieldEmpty = false;
      setModalShow(false)
      return
    }

    if (isValidDetails === false) {
      setModalShow(false)
    } else if (isValidDetails === true) {
      setModalShow(true)
    }
  }

  const fetchApiHandle = async () => {
    const userData = await fetchUserRecord(token, `/api/LeadGenerationOnboarding/${PracticeId}`);
    if (userData == "Token is expired, login again") {
      setIsTokenExpired(true)
    }
    else {
      setProviderData(userData);
      userData.length > 0 ? setProviderId(userData[0].Id) : "";
    }
  }

  const addNewRowHandle = () => {
    let gridRows = [...addRows];
    const selectedProvider = providerData.filter((provider) => provider.Id == providerId);
    const firstProviderData = selectedProvider[0];

    const newRow = {
      Id: addRows.length + 1,
      PatientFullName: '',
      PatientContactNumber: '',
      PatientInsuranceProvider: '',
      ProviderName: firstProviderData.ProviderName,
      ProviderFacilityName: firstProviderData.ProviderFacilityName,
      ProviderSpecialtyType: firstProviderData.ProviderSpecialtyType,
      AdditionalOfferedService: firstProviderData.AdditionalOfferedService,
      AdditionalOfferedServiceDetail: firstProviderData.AdditionalOfferedServiceDetail,
      BetterServicesReason: firstProviderData.BetterServicesReason,
      PatientIssues: firstProviderData.PatientIssues,
      CustomerWebsite: firstProviderData.CustomerWebsite,
      OperationalHours: firstProviderData.OperationalHours,
      InsurancesAccepted: firstProviderData.InsurancesAccepted
    };

    gridRows.push(newRow);
    setAddRows(gridRows);
  };

  const populateDefaultRow = () => {
    let gridRows = [...addRows];
    if (gridRows.length == 0) {
      const selectedProvider = providerData.filter((provider) => provider.Id == providerId);
      const firstProviderData = selectedProvider[0];

      const newRow = {
        Id: addRows.length + 1,
        PatientFullName: '',
        PatientContactNumber: '',
        PatientInsuranceProvider: '',
        ProviderName: firstProviderData.ProviderName,
        ProviderFacilityName: firstProviderData.ProviderFacilityName,
        ProviderSpecialtyType: firstProviderData.ProviderSpecialtyType,
        AdditionalOfferedService: firstProviderData.AdditionalOfferedService,
        AdditionalOfferedServiceDetail: firstProviderData.AdditionalOfferedServiceDetail,
        BetterServicesReason: firstProviderData.BetterServicesReason,
        PatientIssues: firstProviderData.PatientIssues,
        CustomerWebsite: firstProviderData.CustomerWebsite,
        OperationalHours: firstProviderData.OperationalHours,
        InsurancesAccepted: firstProviderData.InsurancesAccepted
      };
      gridRows.push(newRow);
      setAddRows(gridRows);
    }
  }

  const updateGridRowData = (e) => {

    let pId = e.target.value;
    let rowIndex = e.target.getAttribute("dataindex");

    const selectedProvider = providerData.filter((provider) => provider.Id == pId);
    const firstProviderData = selectedProvider[0];

    let gridRows = [...addRows];

    gridRows[rowIndex].ProviderName = firstProviderData.ProviderName;
    gridRows[rowIndex].ProviderFacilityName = firstProviderData.ProviderFacilityName;
    gridRows[rowIndex].ProviderSpecialtyType = firstProviderData.ProviderSpecialtyType;
    gridRows[rowIndex].AdditionalOfferedService = firstProviderData.AdditionalOfferedService;
    gridRows[rowIndex].AdditionalOfferedServiceDetail = firstProviderData.AdditionalOfferedServiceDetail;
    gridRows[rowIndex].BetterServicesReason = firstProviderData.BetterServicesReason;
    gridRows[rowIndex].PatientIssues = firstProviderData.PatientIssues;
    gridRows[rowIndex].CustomerWebsite = firstProviderData.CustomerWebsite;
    gridRows[rowIndex].OperationalHours = firstProviderData.OperationalHours;
    gridRows[rowIndex].InsurancesAccepted = firstProviderData.InsurancesAccepte;

    setAddRows(gridRows);
  }

  const selectedProviderName = (e) => {
    if (e.selectedIndex === -1) {
      return null;
    }
    return e.options[e.selectedIndex].text;
  }

  const saveRecordHandle = async () => {
    const array = [];
    document.querySelectorAll('.uploadLeadForm-table-row').forEach(row => {
      const patientFullName = row.querySelector('.PatientFullName').value;
      const patientContactNumber = row.querySelector('.PatientContactNumber').value;
      const PatientInsuranceProvider = row.querySelector('.PatientInsuranceProvider').value;
      const ProviderId = row.querySelector('.ProviderName').value;
      const ProviderName = row.querySelector('.ProviderName');
      const newProviderName = selectedProviderName(ProviderName);
      const ProviderFacilityName = row.querySelector('.ProviderFacilityName').value;
      const ProviderSpecialtyType = row.querySelector('.ProviderSpecialtyType').value;
      const AdditionalOfferedService = row.querySelector('.AdditionalOfferedService').value;
      const AdditionalOfferedServiceDetail = row.querySelector('.AdditionalOfferedServiceDetail').value;
      const BetterServicesReason = row.querySelector('.BetterServicesReason').value;
      const PatientIssues = row.querySelector('.PatientIssues').value;
      const CustomerWebsite = row.querySelector('.CustomerWebsite').value;
      const OperationalHours = row.querySelector('.OperationalHours').value;
      const InsurancesAccepted = row.querySelector('.InsurancesAccepted').value;

      const pAuthQNewObj = {
        PracticeId: PracticeId,
        PatientFullName: patientFullName,
        PatientContactNumber: patientContactNumber,
        PatientInsuranceProvider: PatientInsuranceProvider,
        ProviderName: newProviderName,
        ProviderFacilityName: ProviderFacilityName,
        ProviderSpecialtyType: ProviderSpecialtyType,
        AdditionalOfferedService: AdditionalOfferedService,
        AdditionalOfferedServiceDetail: AdditionalOfferedServiceDetail,
        ProviderId: ProviderId,
        BetterServicesReason: BetterServicesReason,
        PatientIssues: PatientIssues,
        CustomerWebsite: CustomerWebsite,
        OperationalHours: OperationalHours,
        InsurancesAccepted: InsurancesAccepted,
        Transcript: "SDFASD",
        MissedCalls: 0,
        LastCallDuration: 0,
        CallSummary: "",
        TotalCallAttempts: 0,
        DoNotCall: false
      };

      array.push(pAuthQNewObj);
    });

    let url = '/api/LeadGenerationUploadLead';
    const userData = await usersPostRequest(token, url, array);
    if (userData == "Token is expired, login again") {
      setIsTokenExpired(true)
    }
    else {
      setIsUploadLead(true)
      closeModal();
    }

  };

  const deleteRowHandle = (id) => {
    let updatedRows = addRows.filter(item => item.Id !== id);
    setAddRows(updatedRows);
  }

  useEffect(() => {
    if (providerData.length == 0) {
      fetchApiHandle();
    }
  }, [])

  useEffect(() => {
    if (providerData.length > 0) {
      populateDefaultRow();
      document.getElementById("addNewRow").removeAttribute("disabled");
    } else {
      document.getElementById('addNewRow').setAttribute("disabled", "true");
    }
  }, [providerData]);

  useEffect(() => {
    if (isTokenExpired === true) {
      alert("Token is expired, login again");
      setToken("")
      setIsTokenExpired("")
      dispatch(logout());
    }
  }, [isTokenExpired]);

  return (
    <>
      <button id="addNewRow" className="simple-btn mb-3" onClick={addNewRowHandle}> <FontAwesomeIcon icon={faCirclePlus} className="me-2" />Add New Row</button>
      <div className="row position-relative">
        <div className="col-lg-12 priorTable">
          <table id="pAuthQualificationModalTable" className="table table-styling mb-5">
            <thead>
              <tr>
                <th colSpan="3" className="text-center border-end border-dark">
                  Patient Information
                </th>
                <th colSpan="11" className="text-center providerInfoHeading">
                  Provider Information
                </th>
              </tr>
              <tr className="text-center table-columns-name">
                <th style={{ paddingRight: "3.9rem" }}>Full Name</th>
                <th>Contact Number</th>
                <th className="border-end">Insurance Provider</th>
                <th>Name</th>
                <th>Clinic/Facility Name</th>
                <th>Specialty</th>
                <th>Please explain the services offered by the Clinic</th>
                <th>Please explain the additional services offered</th>
                <th>Why are your services better than your competitors</th>
                <th>Enter your Customer or Patient pain points that your Company or Clinic solves</th>
                <th>Customer or Internet Website</th>
                <th>Operational/Business Hours</th>
                <th>Insurances Accepted</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {addRows.map((row, index) => (
                <tr key={index} className="uploadLeadForm-table-row">
                  <td><input type="text" onBlur={resetErrorFieldStyle} className="PatientFullName form-control" placeholder="John Smith" /></td>
                  <td><InputMask mask="999-999-9999" onBlur={resetErrorFieldStyle} className="PatientContactNumber form-control" placeholder="123-456-7890" /></td>
                  <td><input type="text" onBlur={resetErrorFieldStyle} className="PatientInsuranceProvider form-control" placeholder="ABC Insurance" /></td>
                  <td>
                    <select className="ProviderName uploadLeadProviders form-select" dataindex={index} onChange={updateGridRowData} onBlur={resetErrorFieldStyle}>
                      {
                        providerData.length > 0 ?
                          providerData.map((provider) => {
                            return (
                              <option value={provider.Id}>{provider.ProviderName}</option>
                            );
                          })
                          : ""
                      }
                    </select>
                  </td>
                  <td><input type="text" onBlur={resetErrorFieldStyle} className="ProviderFacilityName form-control" value={row.ProviderFacilityName} /></td>
                  <td><input type="text" onBlur={resetErrorFieldStyle} className="ProviderSpecialtyType form-control" value={row.ProviderSpecialtyType} /></td>
                  <td><input type="text" onBlur={resetErrorFieldStyle} className="AdditionalOfferedService form-control" value={row.AdditionalOfferedService} /></td>
                  <td><input type="text" onBlur={resetErrorFieldStyle} className="AdditionalOfferedServiceDetail form-control" value={row.AdditionalOfferedServiceDetail} /></td>
                  <td><input type="text" onBlur={resetErrorFieldStyle} className="BetterServicesReason form-control" value={row.BetterServicesReason} /></td>
                  <td><input type="text" onBlur={resetErrorFieldStyle} className="PatientIssues form-control" value={row.PatientIssues} /></td>
                  <td><input type="text" onBlur={resetErrorFieldStyle} className="CustomerWebsite form-control" value={row.CustomerWebsite} /></td>
                  <td><input type="text" onBlur={resetErrorFieldStyle} className="OperationalHours form-control" value={row.OperationalHours} /></td>
                  <td><input type="text" onBlur={resetErrorFieldStyle} className="InsurancesAccepted form-control" value={row.InsurancesAccepted} /></td>
                  <td><button className="simple-btn" onClick={() => deleteRowHandle(row.Id)}><FontAwesomeIcon icon={faXmark} /></button></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="text-end position-absolute bottom-0 mb-3">
          <button className="simple-btn" onClick={isAnyFormFieldEmpty} >Save</button>
        </div>
      </div>
      <div className="conFirmModalContainer">
        <Modal className="popup" show={modalShow} onHide={() => setModalShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Do you confirm</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>By saving you are verifying that you are uploading the data in the database</p>
          </Modal.Body>
          <Modal.Footer>
            <button className="simple-btn me-2" onClick={() => setModalShow(false)}>No</button>
            <button className="simple-btn" onClick={saveRecordHandle}>Yes</button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  )
}
