import { createSlice } from "@reduxjs/toolkit";

export const userDetailSlice = createSlice({
    name: "userdetail",
    initialState: {
        userdetail: null,
    },
    reducers: {
        setUserDetail: (state, action) => {
            state.userdetail = action.payload;
        },
        clearUserDetail: (state, action) => {
            state.userdetail = null;
        }
    }
})

export const { setUserDetail, clearUserDetail } = userDetailSlice.actions;

export default userDetailSlice.reducer;