import { faBell, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../features/userSlice";
import { PauThQGrid } from "../child-components/PauthQGrid";
import { PauThSGrid } from "../child-components/PauthSGrid";
import { BeneVGrid } from "../child-components/BeneVGrid";
import { useContextStateManagement } from "../context/ContextApi";
import { CareRGrid } from "../child-components/CareRGrid";
import { VisitRGrid } from "../child-components/VisitRGrid";
import { AwareCGrid } from "../child-components/AwareCGrid";
import { LeadGGrid } from "../child-components/LeadGGrid";
import { SurveYGrid } from "../child-components/SurveYGrid";
import { GeneralCallSetting } from "../callSettings/GeneralCallSetting";
import { BulkUploadLead } from "../bulk-upload-lead/BulkUploadLead";
import { PAuthQOnBoardingForm } from "../onBoardingTemplateForms/PAuthQOnBoardingForm";
import { PAuthStatusOnboardingForm } from "../onBoardingTemplateForms/PAuthStatusOnboardingForm";
import { BenefitsVerificationOnboardingForm } from "../onBoardingTemplateForms/BenefitsVerificationOnboardingForm";
import { CareRemindersOnboardingForm } from "../onBoardingTemplateForms/CareRemindersOnboardingForm";
import { VisitRemindersOnboardingForm } from "../onBoardingTemplateForms/VisitRemindersOnboardingForm";
import { AwarenessCOnBoardingForm } from "../onBoardingTemplateForms/AwarenessCOnBoardingForm";
import { LeadGenerationOnboardingForm } from "../onBoardingTemplateForms/LeadGenerationOnboardingForm";
import TabPointer_icon from "../assets/images/TabPointer_icon.png"

export const Home = ({ setIsUploadLead, isUploadLead }) => {
  const { memberListName, setMemberListName, activeTab, setActiveTab, sideBarLinkActive,
    userName, setPracticeId, userProfession, accessRights, PracticeId
  } = useContextStateManagement();

  const dispatch = useDispatch();
  const [pAuthQTab, setPAuthQTab] = useState("hHubTab_Active");
  const [searchActive, setSearchActive] = useState("patient");
  const [providerName, setProviderName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [patientName, setPatientName] = useState("");
  const [isBulkUploaded, setIsBulkUploaded] = useState(false);
  let tabDisabled = false;

  // logout handle
  const tokenExpiryLogoutHandle = () => {
    setActiveTab("pAuthQTab");
    setPracticeId("");
    dispatch(logout());
  }

  function changeSearchingState(e) {
    if (e.target.checked === true) {
      setSearchActive("provider");
      let searchPatientName = patientName;
      setPatientName("");
      setProviderName(searchPatientName);

    } else {
      setSearchActive("patient");
      let serachProviderName = providerName;
      setProviderName("");
      setPatientName(serachProviderName);
    }
  }

  function showHideSelectedTabControls(selectedTabId) {
    if (selectedTabId === "pAuthQTab") {
      setActiveTab("pAuthQTab");
      setMemberListName("Prior Auth Qualification");
    } else if (selectedTabId === "pAuthSTab") {
      setActiveTab("pAuthSTab");
      setMemberListName("Prior Auth Status");
    } else if (selectedTabId === "benefitVTab") {
      setActiveTab("benefitVTab");
      setMemberListName("Benefits Verifications");
    } else if (selectedTabId === "careRTab") {
      setActiveTab("careRTab");
      setMemberListName("Care Reminders");
    } else if (selectedTabId === "visitRTab") {
      setActiveTab("visitRTab");
      setMemberListName("Visit Reminders");
    } else if (selectedTabId === "awareNessCTab") {
      setActiveTab("awareNessCTab");
      setMemberListName("Awareness Campaign");
    } else if (selectedTabId === "leadGTab") {
      setActiveTab("leadGTab");
      setMemberListName("Lead Generation");
    } else if (selectedTabId === "surveyTab") {
      setActiveTab("surveyTab");
      setMemberListName("Survey");
    }
  }


  // const startDateHandle = (e) => {
  //   setStartDate(e.target.value)
  //   handleStartDateChange()
  // }

  // const endDateHandle = (e) => {
  //   setEndDate(e.target.value)
  //   handleStartDateChange()
  // }

  const resetDatesHandle = () => {
    setStartDate("")
    setEndDate("")
  }

  // const handleAccessTabs = () => {
  //   accessRights.forEach((featureslist) => {
  //     if (featureslist.FeatureId === 1 && featureslist.RightsDetail == '1') {
  //       debugger
  //       setPAuthQTab("")
  //       setActiveTab("")
  //       document.getElementById('pAuthQTabContainer').classList.add("disabledTab");
  //       var nodes = document.getElementById("pAuthQTabContainer").getElementsByTagName('*');
  //       for (var i = 0; i < nodes.length; i++) {
  //         console.log(nodes[i])
  //         nodes[i].disabled = true;
  //       }
  //       tabDisabled = true;

  //       return
  //     }

  //   })
  // }


  // useEffect(() => {
  //   if (accessRights.length > 0) {

  //     handleAccessTabs()
  //   }
  // }, [accessRights]);

  return (
    <>
      <div className="row pt-2">
        <div className="col-lg-8">
          <div className="form-group search position-relative align-items-center">
            <FontAwesomeIcon className="position-absolute top-0 start-0" icon={faMagnifyingGlass} />
            <input type="text" className="form-control search-bar fs-1" id="searchGrid" placeholder="Search first and last names of the members"
              defaultValue={searchActive === "patient" ? patientName : providerName}
              onChange={searchActive === "patient" ? (e) => setPatientName(e.target.value) : (e) => setProviderName(e.target.value)}
            />
            <label className="ms-2 fs-1"><input type="checkbox" className="providerNameCheckbox mx-1" id="filterProviderName" value="providerName"
              onChange={changeSearchingState} /> <span>Search By Provider Name</span>
            </label>
            <br />
          </div>
        </div>
        <div className="col-lg-4">
          <div className="dropdown d-flex flex-row-reverse">
            <a href="#" className="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
              id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false" >
            </a>
            <ul className="dropdown-menu dropdown-menu-dark text-small shadow">
              {/* <li>
                <a className="dropdown-item" href="#"> Settings </a>
              </li>
              <li>
                <a className="dropdown-item" href="#"> Profile </a>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li> */}
              <li>
                <a className="dropdown-item" href="#" onClick={tokenExpiryLogoutHandle}> Sign out </a>
              </li>
            </ul>
            <ul className="ms-1 me-2 my-0 list-unstyled">
              <li className="fw-bold" id="careCoordinatorName"></li>
              <li className="fw-bold" id="careCoordinatorType"></li>
            </ul>
            <ul className="ms-1 me-2 my-0 list-unstyled">
              <li className="fw-bold" id="userName" style={{ fontSize: "0.9rem" }}> {userName} </li>
              <li className="fw-bold" id="userProfestion" style={{ fontSize: "0.7rem" }}> {userProfession} </li>
            </ul>

            <img src="https://img.freepik.com/free-photo/portrait-smiling-handsome-male-doctor-man_171337-5055.jpg?size=626&ext=jpg"
              width="37" height="37" className="rounded me-2" alt="Portrait Smiling" />
          </div>
        </div>
      </div>
      <div className="row pt-4 align-items-center">
        <div className="col-5">
          <h4 id="mbrGridHeading"> Members List :
            <span className="members-select-value" id="seLectedTabHeading">{` ${memberListName}`}</span>
          </h4>
          <div className="d-none" id="healthHubHeading">
            <div className="position-relative float-start hHub_img_container">
              <span className="greenNotificationsCircle" id="mbrRiskScore" align="center" title="Risk score"> 0 </span>
              <span
                className="grayNotificationsCircle"
                id="mbrRiskScoreDpndnt"
                align="center"
                title="Sdoh"
              >
                0
              </span>
              <img
                src="Images/0.jpg"
                className="rounded hHubImage"
                height="45"
                width="45"
                alt="Patient"
              />
              <span
                className="grayNotificationsCircle2"
                id="mbrReadmitCount"
                align="center"
                title="Readmissions"
              >
                0
              </span>
              <span
                className="grayNotificationsCircle3"
                id="mbrEDCount"
                align="center"
                title="ED admissions"
              >
                0
              </span>
            </div>
            <div className="align-items-center float-start ms-2 hHub-heading">
              {/* <h4 className="mb-0" id="hHubSlctdMbrName" data-mbrID="abc"></h4> */}
              <p className="text-sm mb-0" id="hHubSlctdMbrAddress"></p>
            </div>
          </div>
        </div>
        <div className="col-7">
          <div className="d-flex flex-row-reverse">
            <ul className="list-inline mb-0">
              <li className="list-inline-item align-middle d-none">
                <ul className="nav contrast">
                  <li className="nav-item">
                    <i className="fa fa-glasses me-1"></i>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#"
                      className="nav-link fw-normal px-2 py-0 active"
                      id="DarkMode"
                    >
                      Dark
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#"
                      className="nav-link fw-normal px-2 py-0 "
                      id="LightMode"
                    >
                      Light
                    </a>
                  </li>
                </ul>
              </li>
              <li className="list-inline-item align-middle d-none">
                <span>|</span>
              </li>
              <li className="list-inline-item align-middle"></li>
              <li className="list-inline-item align-middle">
                <i className="fa fa-font"></i>
              </li>

              {/* <li className="list-inline-item align-middle">
                <span>Font Size</span>
              </li>
              <li className="list-inline-item align-middle">
                <input
                  type="range"
                  className="font_slider"
                  min="0.8"
                  max="1.5"
                  step=".1"
                  value="0.8"
                  id="slider"
                />
              </li> */}
            </ul>
            <div className="form-group d-flex justify-content-center align-items-center datePicker align-middle text-end ms-2 fs-1">
              <label htmlFor="startDate" className="text-nowrap mx-1">
                Start Date:
              </label>
              <input type="date" id="startDate" value={startDate} onChange={(e) => setStartDate(e.target.value)} className="form-control me-2" />{" "}
              <label htmlFor="endDate" className="text-nowrap mx-1">
                End Date:
              </label>
              <input type="date" id="endDate" value={endDate} onChange={(e) => setEndDate(e.target.value)} className="form-control" />
              <button onClick={resetDatesHandle} className="simple-btn ms-2">Reset</button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col" id="contentArea">
          <div
            className="rounded py-3 px-2 kendoContainer bg_dark_opacity"
            id="memberGridContainer"
          >
            <div className="row tabs mb-4">
              <div id="pAuthQTabContainer" className={`col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 position-relative tab-container`}>
                <div id="pAuthQTab" className={`position-relative rounded-3 ${activeTab === "pAuthQTab" ? "hHubTab_Active" : ""} hHubTab_Container`}
                  onClick={() => showHideSelectedTabControls('pAuthQTab')}>
                  <div className="py-2 ps-2">
                    <a className="btn btn-success btnHealthHubEvent py-2" data-name="Clm">
                      <span className="rounded-3 appointmentType" id="TabValue">
                        P<sub className="auth">auth</sub>Q
                      </span>
                    </a>
                  </div>
                </div>
                <a className="bg_dark color_blue hHUb_Notifications_bell" id="cLMNotification" title="Clm notifications">
                  <FontAwesomeIcon icon={faBell} className="align-middle" />
                  <span className="position-absolute notificationOrange d-none"></span>
                </a>
                <div className="btn-FHIR">
                  <div className="text-end EMR-div">
                    <div className="tab-ankar-link">
                      <a
                        href="#"
                        className="bg_darke EMR-inkar-tag PauthAnkarTag"
                        title="Open FHIR"
                      >
                        EMR
                      </a>
                    </div>
                    <div className="cd-blinking">
                      <div className="dot"></div>
                      <div className="pulse"></div>
                    </div>
                  </div>
                  <div className="text-end EMR-div">
                    <div className="tab-ankar-link">
                      <a
                        href="#"
                        className="bg_darke EMR-inkar-tag PauthAnkarTag"
                        title="Open FHIR"
                      >
                        Schedule
                      </a>
                    </div>
                    <div className="cd-blinking">
                      <div className="dot"></div>
                      <div className="pulse"></div>
                    </div>
                  </div>
                </div>
                <img
                  src={TabPointer_icon}
                  className="hHubTabsAfterIcon"
                  alt="Tab Pointer"
                />
              </div>
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 position-relative tab-container">
                <div className={`position-relative rounded-3 hHubTab_Container ${activeTab === "pAuthSTab" ? "hHubTab_Active" : ""} `}
                  id="pAuthSTab" onClick={() => showHideSelectedTabControls('pAuthSTab')} >
                  <div className="py-2 ps-2">
                    <a href="#" className="btn bg_lightgreen border_lightgreen btnHealthHubEvent py-2" data-name="EMR">
                      <span className="rounded-3 appointmentType" id="TabValueTwo">
                        P<sub>auth</sub>S
                      </span>
                    </a>
                  </div>
                </div>
                <a
                  className="bg_dark color_blue hHUb_Notifications_bell"
                  id="eRMNotification"
                  title="EMR notifications"
                >
                  <FontAwesomeIcon icon={faBell} className="align-middle" />
                  <span className="position-absolute notificationOrange d-none"></span>
                </a>
                <div className="btn-FHIR">
                  <div className="text-end EMR-div">
                    <div className="tab-ankar-link">
                      <a
                        href="#"
                        className="bg_darke EMR-inkar-tag PauthAnkarTag"
                        title="Open FHIR"
                      >
                        EMR
                      </a>
                    </div>
                    <div className="cd-blinking">
                      <div className="dot"></div>
                      <div className="pulse"></div>
                    </div>
                  </div>
                  <div className="text-end EMR-div">
                    <div className="tab-ankar-link">
                      <a
                        href="#"
                        className="bg_darke EMR-inkar-tag PauthAnkarTag"
                        title="Open FHIR"
                      >
                        Schedule
                      </a>
                    </div>
                    <div className="cd-blinking">
                      <div className="dot"></div>
                      <div className="pulse"></div>
                    </div>
                  </div>
                </div>
                <img
                  src={TabPointer_icon}
                  className="hHubTabsAfterIcon"
                  alt="Tab Pointer"
                />
              </div>
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 position-relative tab-container">
                <div
                  className={`position-relative rounded-3 hHubTab_Container ${activeTab === "benefitVTab" ? "hHubTab_Active" : ""} `}
                  id="benefitVTab"
                  onClick={() => showHideSelectedTabControls('benefitVTab')}
                >
                  <div className="py-2 ps-2">
                    <a href="#" className="btn btn-info btnHealthHubEvent py-2" data-name="Wearable"
                    >
                      <span
                        className="rounded-3 appointmentType"
                        id="WearableTabValue"
                      >
                        B<sub>ene</sub>V
                      </span>
                    </a>
                  </div>
                </div>
                <a
                  className="bg_dark color_blue hHUb_Notifications_bell"
                  id="wBleNotification"
                  title="Wearable notifications"
                >
                  <FontAwesomeIcon icon={faBell} className="align-middle" />
                  <span className="position-absolute notificationOrange d-none"></span>
                </a>
                <div className="btn-FHIR">
                  <div className="text-end EMR-div">
                    <div className="tab-ankar-link">
                      <a
                        href="#"
                        className="bg_darke EMR-inkar-tag PauthAnkarTag"
                        title="Open FHIR"
                      >
                        EMR
                      </a>
                    </div>
                    <div className="cd-blinking">
                      <div className="dot"></div>
                      <div className="pulse"></div>
                    </div>
                  </div>
                  <div className="text-end EMR-div">
                    <div className="tab-ankar-link">
                      <a
                        href="#"
                        className="bg_darke EMR-inkar-tag PauthAnkarTag"
                        title="Open FHIR"
                      >
                        Schedule
                      </a>
                    </div>
                    <div className="cd-blinking">
                      <div className="dot"></div>
                      <div className="pulse"></div>
                    </div>
                  </div>
                </div><img
                  src={TabPointer_icon}
                  className="hHubTabsAfterIcon"
                  alt="Tab Pointer"
                />
              </div>
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 position-relative tab-container">
                <div
                  className={`position-relative rounded-3 hHubTab_Container ${activeTab === "careRTab" ? "hHubTab_Active" : ""} `}
                  id="careRTab"
                  onClick={() => showHideSelectedTabControls("careRTab")}
                >
                  <div className="py-2 ps-2">
                    <a href="#" className="btn bg_orange bg_orange btnHealthHubEvent py-2" data-name="Sdoh">
                      <span className="rounded-3 appointmentType" id="sdohTabValue">
                        C<sub>are</sub>R
                      </span>
                    </a>
                  </div>
                </div>
                <a
                  className="bg_dark color_blue hHUb_Notifications_bell"
                  id="sDohNotification"
                  title="Sdoh notifications"
                >
                  <FontAwesomeIcon icon={faBell} className="align-middle" />
                  <span className="position-absolute notificationOrange d-none"></span>
                </a>
                <div className="btn-FHIR">
                  <div className="text-end EMR-div">
                    <div className="tab-ankar-link">
                      <a
                        href="#"
                        className="bg_darke EMR-inkar-tag PauthAnkarTag"
                        title="Open FHIR"
                      >
                        EMR
                      </a>
                    </div>
                    <div className="cd-blinking">
                      <div className="dot"></div>
                      <div className="pulse"></div>
                    </div>
                  </div>
                  <div className="text-end EMR-div">
                    <div className="tab-ankar-link">
                      <a
                        href="#"
                        className="bg_darke EMR-inkar-tag PauthAnkarTag"
                        title="Open FHIR"
                      >
                        Schedule
                      </a>
                    </div>
                    <div className="cd-blinking">
                      <div className="dot"></div>
                      <div className="pulse"></div>
                    </div>
                  </div>
                </div>
                <img
                  src={TabPointer_icon}
                  className="hHubTabsAfterIcon"
                  alt="Tab Pointer"
                />
              </div>
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 position-relative tab-container">
                <div
                  className={`position-relative rounded-3 hHubTab_Container ${activeTab === "visitRTab" ? "hHubTab_Active" : ""} `}
                  id="visitRTab"
                  onClick={() => showHideSelectedTabControls("visitRTab")}
                >
                  <div className="py-2 ps-2">
                    <a href="#" className="btn btn-warning btnHealthHubEvent py-2" data-name="Raf">
                      <span
                        className="rounded-3 appointmentType"
                        id="rafTabHeadingValue"
                      >
                        V<sub>isit</sub>R
                      </span>
                    </a>
                  </div>
                </div>
                <a
                  className="bg_dark color_blue hHUb_Notifications_bell"
                  id="rAfNotification"
                  title="Raf notifications"
                >
                  <FontAwesomeIcon icon={faBell} className="align-middle" />
                  <span className="position-absolute notificationOrange d-none"></span>
                </a>
                <div className="btn-FHIR">
                  <div className="text-end EMR-div">
                    <div className="tab-ankar-link">
                      <a
                        href="#"
                        className="bg_darke EMR-inkar-tag PauthAnkarTag"
                        title="Open FHIR"
                      >
                        EMR
                      </a>
                    </div>
                    <div className="cd-blinking">
                      <div className="dot"></div>
                      <div className="pulse"></div>
                    </div>
                  </div>
                  <div className="text-end EMR-div">
                    <div className="tab-ankar-link">
                      <a
                        href="#"
                        className="bg_darke EMR-inkar-tag PauthAnkarTag"
                        title="Open FHIR"
                      >
                        Schedule
                      </a>
                    </div>
                    <div className="cd-blinking">
                      <div className="dot"></div>
                      <div className="pulse"></div>
                    </div>
                  </div>
                </div>
                <img
                  src={TabPointer_icon}
                  className="hHubTabsAfterIcon"
                  alt="Tab Pointer"
                />
              </div>
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 position-relative tab-container">
                <div
                  className={`position-relative rounded-3 hHubTab_Container ${activeTab === "awareNessCTab" ? "hHubTab_Active" : ""} `}
                  id="awareNessCTab"
                  onClick={() => showHideSelectedTabControls("awareNessCTab")}
                >
                  <div className="py-2 ps-2">
                    <a href="#" className="btn btn-light btnHealthHubEvent py-2" data-name="Raf"
                    >
                      <span
                        className="rounded-3 appointmentType"
                        id="awareTabHeading"
                      >
                        A<sub>ware</sub>C
                      </span>
                    </a>
                  </div>
                </div>
                <a
                  className="bg_dark  color_blue hHUb_Notifications_bell"
                  id="rAfNotification"
                  title="Raf notifications"
                >
                  <FontAwesomeIcon icon={faBell} className="align-middle" />
                  <span className="position-absolute notificationOrange d-none"></span>
                </a>
                <div className="btn-FHIR">
                  <div className="text-end EMR-div">
                    <div className="tab-ankar-link">
                      <a
                        href="#"
                        className="bg_darke EMR-inkar-tag PauthAnkarTag"
                        title="Open FHIR"
                      >
                        EMR
                      </a>
                    </div>
                    <div className="cd-blinking">
                      <div className="dot"></div>
                      <div className="pulse"></div>
                    </div>
                  </div>
                  <div className="text-end EMR-div">
                    <div className="tab-ankar-link">
                      <a
                        href="#"
                        className="bg_darke EMR-inkar-tag PauthAnkarTag"
                        title="Open FHIR"
                      >
                        Schedule
                      </a>
                    </div>
                    <div className="cd-blinking">
                      <div className="dot"></div>
                      <div className="pulse"></div>
                    </div>
                  </div>
                </div>
                <img
                  src={TabPointer_icon}
                  className="hHubTabsAfterIcon"
                  alt="Tab Pointer"
                />
              </div>
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 position-relative tab-container">
                <div
                  className={`position-relative rounded-3 hHubTab_Container ${activeTab === "leadGTab" ? "hHubTab_Active" : ""} `}
                  id="leadGTab"
                  onClick={() => showHideSelectedTabControls("leadGTab")}
                >
                  <div className="py-2 ps-2">
                    <a href="#" className="btn btn-danger btnHealthHubEvent py-2" data-name="EMR">
                      <span
                        className="rounded-3 appointmentType"
                        id="leadTabHeading"
                      >
                        L<sub>ead</sub>G
                      </span>
                    </a>
                  </div>
                </div>
                <a
                  className="bg_dark color_blue hHUb_Notifications_bell"
                  id="eRMNotification"
                  title="EMR notifications"
                >
                  <FontAwesomeIcon icon={faBell} className="align-middle" />
                  <span className="position-absolute notificationOrange d-none"></span>
                </a>
                <div className="btn-FHIR">
                  <div className="text-end EMR-div">
                    <div className="tab-ankar-link">
                      <a
                        href="#"
                        className="bg_darke EMR-inkar-tag PauthAnkarTag"
                        title="Open FHIR"
                      >
                        Schedule
                      </a>
                    </div>
                    <div className="cd-blinking">
                      <div className="dot"></div>
                      <div className="pulse"></div>
                    </div>
                  </div>
                </div>
                <img
                  src={TabPointer_icon}
                  className="hHubTabsAfterIcon"
                  alt="Tab Pointer"
                />
              </div>
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 position-relative tab-container">
                <div
                  className={`position-relative rounded-3 hHubTab_Container ${activeTab === "surveyTab" ? "hHubTab_Active" : ""} `}
                  id="surveyTab"
                  onClick={() => showHideSelectedTabControls("surveyTab")}
                >
                  <div className="py-2 ps-2">
                    <a href="#" className="btn btn-secondary btnHealthHubEvent py-2" data-name="EMR"
                    >
                      <span
                        className="rounded-3 appointmentType"
                        id="refTabHeading"
                      >
                        S<sub>urve</sub>y
                      </span>
                    </a>
                  </div>
                </div>
                <a
                  className="bg_dark color_blue hHUb_Notifications_bell"
                  id="eRMNotification"
                  title="EMR notifications"
                >
                  <FontAwesomeIcon icon={faBell} className="align-middle" />
                  <span className="position-absolute notificationOrange d-none"></span>
                </a>
                <div className="btn-FHIR">
                  <div className="text-end EMR-div">
                    <div className="tab-ankar-link">
                      <a
                        href="#"
                        className="bg_darke EMR-inkar-tag PauthAnkarTag"
                        title="Open FHIR"
                      >
                        Schedule
                      </a>
                    </div>
                    <div className="cd-blinking">
                      <div className="dot"></div>
                      <div className="pulse"></div>
                    </div>
                  </div>
                </div>
                <img
                  src={TabPointer_icon}
                  className="hHubTabsAfterIcon"
                  alt="Tab Pointer"
                />
              </div>
            </div>
            {
              sideBarLinkActive === "careConsole" ?
                <div className="row">
                  {
                    activeTab === "pAuthQTab" ?  
                      <PauThQGrid isUploadLead={isUploadLead} providerName={providerName} patientName={patientName} startDate={startDate} endDate={endDate} /> :
                      activeTab === "pAuthSTab" ?
                        <PauThSGrid isUploadLead={isUploadLead} providerName={providerName} patientName={patientName} startDate={startDate} endDate={endDate} /> :
                        activeTab === "benefitVTab" ?
                          <BeneVGrid isUploadLead={isUploadLead} providerName={providerName} patientName={patientName} startDate={startDate} endDate={endDate} /> :
                          activeTab === "careRTab" ?
                            <CareRGrid isUploadLead={isUploadLead} providerName={providerName} patientName={patientName} startDate={startDate} endDate={endDate} /> :
                            activeTab === "visitRTab" ?
                              <VisitRGrid isUploadLead={isUploadLead} providerName={providerName} patientName={patientName} startDate={startDate} endDate={endDate} /> :
                              activeTab === "awareNessCTab" ?
                                <AwareCGrid isUploadLead={isUploadLead} providerName={providerName} patientName={patientName} startDate={startDate} endDate={endDate} /> :
                                activeTab === "leadGTab" ?
                                  <LeadGGrid isUploadLead={isUploadLead} providerName={providerName} patientName={patientName} startDate={startDate} endDate={endDate} /> : <SurveYGrid providerName={providerName} patientName={patientName} startDate={startDate} endDate={endDate} />
                  }
                </div>
                : sideBarLinkActive === "bulkUpload" ?
                  <div className="row">
                    <BulkUploadLead setIsBulkUploaded={setIsBulkUploaded} isBulkUploaded={isBulkUploaded} />
                  </div>
                  : sideBarLinkActive === "callSettings" ?
                    <div className="row">
                      <GeneralCallSetting />
                    </div>
                    : sideBarLinkActive === "onBoardingForm" ?
                      <div className="row">
                        {
                          activeTab === "pAuthQTab" ?
                            <PAuthQOnBoardingForm setIsUploadLead={setIsUploadLead} /> :
                            activeTab === "pAuthSTab" ?
                              <PAuthStatusOnboardingForm /> :
                              activeTab === "benefitVTab" ?
                                <BenefitsVerificationOnboardingForm /> :
                                activeTab === "careRTab" ?
                                  <CareRemindersOnboardingForm /> :
                                  activeTab === "visitRTab" ?
                                    <VisitRemindersOnboardingForm /> :
                                    activeTab === "awareNessCTab" ?
                                      <AwarenessCOnBoardingForm /> :
                                      activeTab === "leadGTab" ?
                                        <LeadGenerationOnboardingForm /> : <SurveYGrid />
                        }
                      </div>
                      : ""
            }
          </div>
        </div>
      </div>


    </>
  );
};
