import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { PriorAuthQForm } from "../upload-lead-form/PriorAuthQForm";
import { PriorAuthSForm } from "../upload-lead-form/PriorAuthSForm";
import { useContextStateManagement } from "../context/ContextApi";
import { BenefitsVForm } from "../upload-lead-form/BenefitsVForm";
import { CareRForm } from "../upload-lead-form/CareRForm";
import { VisitRForm } from "../upload-lead-form/VisitRForm";
import { AwareNessCForm } from "../upload-lead-form/AwareNessCForm";
import { LeadGForm } from "../upload-lead-form/LeadGForm";

export const UploadLeadModal = ({ showModal, closeModal, setIsUploadLead }) => {

  const {memberListName, activeTab} = useContextStateManagement();

  return (
    <>
      <Modal size="lg" show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Lead : {memberListName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {
              activeTab == "pAuthQTab" ? 
              <PriorAuthQForm showModal={showModal} closeModal={closeModal} setIsUploadLead={setIsUploadLead} />  : 
              activeTab == "pAuthSTab" ? 
              <PriorAuthSForm closeModal={closeModal} setIsUploadLead={setIsUploadLead} /> : 
              activeTab == "benefitVTab" ? 
              <BenefitsVForm closeModal={closeModal} setIsUploadLead={setIsUploadLead} /> : 
              activeTab == "careRTab" ? 
              <CareRForm closeModal={closeModal} setIsUploadLead={setIsUploadLead} /> : 
              activeTab == "visitRTab" ? 
              <VisitRForm closeModal={closeModal} setIsUploadLead={setIsUploadLead} /> : 
              activeTab == "awareNessCTab" ?
              <AwareNessCForm closeModal={closeModal} setIsUploadLead={setIsUploadLead} /> : 
              activeTab == "leadGTab" ?
              <LeadGForm closeModal={closeModal} setIsUploadLead={setIsUploadLead} /> : 
              ""
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </>
  );
};

