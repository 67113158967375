import * as React from "react";
import { validEmail, validContactNumber, resetErrorFieldStyle } from "../Variables";
import { useContextStateManagement } from "../context/ContextApi";
import InputMask from 'react-input-mask';
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../features/userSlice";

export const PAuthQOnBoardingForm = () => {
    const { PracticeId, userProfession, token, usersPostRequest, setToken } = useContextStateManagement();
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const dispatch = useDispatch();
    let isAnyUploadFieldEmpty = false;
    let isValidDetails = false;

    const handleSubmit = async (e) => {
        let ProviderName = document.getElementById('ProviderName').value;
        let ProviderFacilityName = document.getElementById('ProviderFacilityName').value;
        let NationalProviderIdentifier = document.getElementById('NationalProviderIdentifier').value;
        let ProviderContactNumber = document.getElementById('ProviderContactNumber').value;
        let ProviderFaxNumber = document.getElementById('ProviderFaxNumber').value;
        let ProviderEmailAddress = document.getElementById('ProviderEmailAddress').value;

        document.querySelectorAll('.px-3').forEach((e) => {
            if (e.value === "") {
                e.classList.add("invalid-details")
            }
        })

        if (ProviderName === "" || ProviderFacilityName === "" || NationalProviderIdentifier === "" || ProviderContactNumber === "" || ProviderFaxNumber === "" || ProviderEmailAddress === "") {
            isAnyUploadFieldEmpty = true;
        }

        if (ProviderEmailAddress !== "" && validEmail.test(ProviderEmailAddress) === false) {
            document.getElementById('ProviderEmailAddress').classList.add("invalid-details");
            isValidDetails = true;
        }

        if (ProviderContactNumber !== "" && validContactNumber.test(ProviderContactNumber) === false) {
            document.getElementById('ProviderContactNumber').classList.add("invalid-details");
            isValidDetails = true;
        }

        if (isAnyUploadFieldEmpty === true) {
            alert('Please enter complete details of onboarding')
            return
        }

        if (isValidDetails === true) {
            return
        }

        let obj = {
            PracticeId: PracticeId,
            ProviderName: ProviderName,
            ProviderFacilityName: ProviderFacilityName,
            NationalProviderIdentifier: NationalProviderIdentifier,
            ProviderContactNumber: ProviderContactNumber,
            ProviderFaxNumber: ProviderFaxNumber,
            ProviderEmailAddress: ProviderEmailAddress,
        }

        let url = '/api/PAuthQOnBoardingTemplate';
        const userData = await usersPostRequest(token, url, obj);
        if (userData == "Token is expired, login again") {
            setIsTokenExpired(true)
        }
        else if (userData && userData.message) {
            alert(userData.message)
        }

        if (ProviderName !== "" || NationalProviderIdentifier !== "" || ProviderContactNumber !== "" || ProviderFaxNumber !== "" || ProviderEmailAddress !== "") {
            document.getElementById('ProviderName').value = "";
            document.getElementById('NationalProviderIdentifier').value = "";
            document.getElementById('ProviderContactNumber').value = "";
            document.getElementById('ProviderFaxNumber').value = "";
            document.getElementById('ProviderEmailAddress').value = "";
        }
    };

    useEffect(() => {
        if (isTokenExpired === true) {
            alert("Token is expired, login again");
            setToken("")
            setIsTokenExpired(false)
            dispatch(logout());
        }
    }, [isTokenExpired]);

    return (
        <>
            <div className="container py-4 px-4 onboardingLayout shadow-sm">
                <div className="row p-3 col-md-5 m-auto login-container onboarding-forms bg_dark_opacity">
                    <div className="mb-3">
                        <h6 className="fw-bold mt-2 text-white mb-1">Prior Auth Q</h6>
                        <p className="text-white">Enter your practice information</p>
                    </div>
                    <div className="col">
                        <div className="mb-3">
                            <label className="text-white form-label">Provider Name</label>
                            <input type="text" className="bg-black border-0 text-white py-2 px-3 form-control" onFocus={resetErrorFieldStyle} id="ProviderName" placeholder="Enter your clinic name" />
                        </div>
                        <div className="mb-3">
                            <label className="text-white form-label">Clinic/Facility Name</label>
                            <input type="text" disabled className="bg-black border-0 text-white py-2 px-3 form-control" onFocus={resetErrorFieldStyle} id="ProviderFacilityName" value={userProfession} placeholder="Enter your clinic name" />
                        </div>
                        <div className="mb-3">
                            <label className="text-white form-label">National Provider Identifier (NPI)</label>
                            <input type="text" className="bg-black text-white border-0 py-2 px-3 form-control" onFocus={resetErrorFieldStyle} id="NationalProviderIdentifier" placeholder="Enter your clinic name" />
                        </div>
                        <div className="mb-3">
                            <label className="text-white form-label">Contact Information</label>
                            <InputMask mask="999-999-9999" className="bg-black text-white border-0 py-2 px-3 form-control" onFocus={resetErrorFieldStyle} id="ProviderContactNumber"
                                placeholder="Enter contact number" />
                        </div>
                        <div className="mb-3">
                            <label className="text-white form-label">Fax Number</label>
                            <input type="text" className="bg-black text-white border-0 py-2 px-3 form-control" onFocus={resetErrorFieldStyle} id="ProviderFaxNumber" placeholder="Add a row" />
                        </div>
                        <div className="mb-3">
                            <label className="text-white form-label">Email Address</label>
                            <input type="email" className="bg-black text-white border-0 py-2 px-3 form-control" onFocus={resetErrorFieldStyle} placeholder="Enter your text"
                                id="ProviderEmailAddress" />
                        </div>
                        <div className="text-center mb-2">
                            <a
                                href="javascript:void(0)"
                                className="simple-btn login-btn"
                                onClick={handleSubmit}
                            >
                                Save
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
