import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { faCirclePlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { validEmail, isValidContactNumber, resetErrorFieldStyle } from "../Variables";
import { useContextStateManagement } from "../context/ContextApi";
import InputMask from 'react-input-mask';
import { useDispatch } from "react-redux";
import { logout } from "../features/userSlice";

export const CareRForm = ({ closeModal, setIsUploadLead }) => {
  const [providerData, setProviderData] = useState([]);
  const [addRows, setAddRows] = useState([]);
  const [modalShow, setModalShow] = useState(false)
  const [providerId, setProviderId] = useState(0);
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const dispatch = useDispatch();
  const { PracticeId, token, setToken, fetchUserRecord, usersPostRequest } = useContextStateManagement();

  let isAnyUploadFieldEmpty = false;
  let isValidDetails = false;

  const isAnyFormFieldEmpty = () => {
    document.querySelectorAll('.uploadLeadForm-table-row').forEach(row => {
      const PatientContactNumber = row.querySelector('.PatientContactNumber').value;
      const PatientEmailAddress = row.querySelector('.PatientEmailAddress').value;

      row.querySelectorAll('.form-control').forEach((e) => {
        if (e.value === "") {
          e.classList.add("invalid-Credentials");
          isAnyUploadFieldEmpty = true;
        }
      })

      if (PatientEmailAddress !== "" && validEmail.test(PatientEmailAddress) === false) {
        row.querySelector('.PatientEmailAddress').classList.add("invalid-Credentials");
        isValidDetails = false;
      }

      if (PatientContactNumber !== "" && isValidContactNumber(PatientContactNumber) === false) {
        row.querySelector('.PatientContactNumber').classList.add("invalid-Credentials");
        isValidDetails = false;
      }

      if (PatientEmailAddress !== "" && validEmail.test(row.querySelector('.PatientEmailAddress').value) === true && PatientContactNumber !== "" && isValidContactNumber(row.querySelector('.PatientContactNumber').value) === true) {
        isValidDetails = true;
      }

      document.querySelectorAll('.uploadLeadForm-table-row').forEach((e) => {
        e.querySelectorAll('.form-control').forEach((e) => {
          if (e.classList.contains("invalid-Credentials")) {
            isValidDetails = false;
          }
        })
      })

    })

    if (isAnyUploadFieldEmpty === true) {
      alert("Please enter complete details of all leads")
      isAnyUploadFieldEmpty = false;
      setModalShow(false)
      return
    }

    if (isValidDetails === false) {
      setModalShow(false)
    } else if (isValidDetails === true) {
      setModalShow(true)
    }
  }

  const fetchApiHandle = async () => {
    const userData = await fetchUserRecord(token, `/api/CareRemindersOnboarding/${PracticeId}`);
    if (userData == "Token is expired, login again") {
      setIsTokenExpired(true)
    }
    else {
      setProviderData(userData);
      userData.length > 0 ? setProviderId(userData[0].Id) : "";
    }
  }

  const addNewRowHandle = () => {
    let gridRows = [...addRows];
    const selectedProvider = providerData.filter((provider) => provider.Id == providerId);
    const firstProviderData = selectedProvider[0];

    const newRow = {
      Id: gridRows.length + 1,
      PatientFullName: '',
      PatientDateOfBirth: '',
      PatientContactNumber: '',
      PatientEmailAddress: '',
      PatientInsuranceProvider: '',
      PatientInsuranceIdNumber: '',
      PatientInsuranceContactNumber: '',
      ProcedureCPT_codes: '',
      ProcedureICD_diagnosisCode: '',
      ProcedureDescription: '',
      ICD_descriptions: '',
      ProviderName: firstProviderData.ProviderName,
      ProviderFacilityName: firstProviderData.ProviderFacilityName,
      ProviderContactNumber: firstProviderData.ProviderContactNumber
    };

    gridRows.push(newRow);
    setAddRows(gridRows);
    //populateFormFieldsHandle();
  };

  const populateDefaultRow = () => {
    let gridRows = [...addRows];
    if (gridRows.length == 0) {
      const selectedProvider = providerData.filter((provider) => provider.Id == providerId);
      const firstProviderData = selectedProvider[0];

      const newRow = {
        Id: gridRows.length + 1,
        PatientFullName: '',
        PatientDateOfBirth: '',
        PatientContactNumber: '',
        PatientEmailAddress: '',
        PatientInsuranceProvider: '',
        PatientInsuranceIdNumber: '',
        PatientInsuranceContactNumber: '',
        ProcedureCPT_codes: '',
        ProcedureICD_diagnosisCode: '',
        ProcedureDescription: '',
        ICD_descriptions: '',
        ProviderName: firstProviderData.ProviderName,
        ProviderFacilityName: firstProviderData.ProviderFacilityName,
        ProviderContactNumber: firstProviderData.ProviderContactNumber
      };
      gridRows.push(newRow);
      setAddRows(gridRows);
    }
  }

  const updateGridRowData = (e) => {

    let pId = e.target.value;
    let rowIndex = e.target.getAttribute("dataindex");

    const selectedProvider = providerData.filter((provider) => provider.Id == pId);
    const firstProviderData = selectedProvider[0];

    let gridRows = [...addRows];

    gridRows[rowIndex].ProviderName = firstProviderData.ProviderName;
    gridRows[rowIndex].ProviderFacilityName = firstProviderData.ProviderFacilityName;
    gridRows[rowIndex].ProviderContactNumber = firstProviderData.ProviderContactNumber;

    setAddRows(gridRows);
  }

  const selectedProviderName = (e) => {
    if (e.selectedIndex === -1) {
      return null;
    }
    return e.options[e.selectedIndex].text;
  }

  const saveRecordHandle = async () => {
    const array = [];
    document.querySelectorAll('.uploadLeadForm-table-row').forEach(row => {
      const patientFullName = row.querySelector('.PatientFullName').value;
      const patientDateOfBirth = row.querySelector('.PatientDateOfBirth').value;
      const PatientGender = row.querySelector('.PatientGender').value;
      const PatientContactNumber = row.querySelector('.PatientContactNumber').value;
      const PatientEmailAddress = row.querySelector('.PatientEmailAddress').value;
      const CareGap = row.querySelector('.CareGap').value;
      const DetailCareGap = row.querySelector('.DetailCareGap').value;
      const PatientsFillCareGap = row.querySelector('.PatientsFillCareGap').value;
      const ProviderId = row.querySelector('.ProviderName').value;
      const ProviderName = row.querySelector('.ProviderName');
      const newProviderName = selectedProviderName(ProviderName);
      const ProviderFacilityName = row.querySelector('.ProviderFacilityName').value;
      const ProviderContactNumber = row.querySelector('.ProviderContactNumber').value;

      const pAuthQNewObj = {
        PracticeId: PracticeId,
        PatientFullName: patientFullName,
        PatientDateOfBirth: patientDateOfBirth,
        PatientGender: PatientGender,
        PatientContactNumber: PatientContactNumber,
        PatientEmailAddress: PatientEmailAddress,
        CareGap: CareGap,
        DetailCareGap: DetailCareGap,
        PatientsFillCareGap: PatientsFillCareGap,
        ProviderId: ProviderId,
        ProviderName: newProviderName,
        ProviderFacilityName: ProviderFacilityName,
        ProviderContactNumber: ProviderContactNumber,
        Transcript: "SDFASD",
        MissedCalls: 0,
        LastCallDuration: 0,
        CallSummary: "",
        TotalCallAttempts: 0,
        DoNotCall: false
      };
      array.push(pAuthQNewObj);
    });

    let url = '/api/CareRemindersUploadLead';
    const userData = await usersPostRequest(token, url, array);
    if (userData == "Token is expired, login again") {
      setIsTokenExpired(true)
    }
    else {
      setIsUploadLead(true)
      closeModal();
    }

  };

  const deleteRowHandle = (id) => {
    let updatedRows = addRows.filter(item => item.Id !== id);
    setAddRows(updatedRows);
  }

  useEffect(() => {
    if (providerData.length == 0) {
      fetchApiHandle();
    }
  }, [])

  useEffect(() => {
    if (providerData.length > 0) {
      populateDefaultRow();
      document.getElementById("addNewRow").removeAttribute("disabled");
    } else {
      document.getElementById('addNewRow').setAttribute("disabled", "true");
    }
  }, [providerData]);

  useEffect(() => {
    if (isTokenExpired === true) {
      alert("Token is expired, login again");
      setToken("")
      setIsTokenExpired("")
      dispatch(logout());
    }
  }, [isTokenExpired]);

  return (
    <>
      <button id="addNewRow" className="simple-btn mb-3" onClick={addNewRowHandle}> <FontAwesomeIcon icon={faCirclePlus} className="me-2" />Add New Row</button>
      <div className="row position-relative">
        <div className="col-lg-12 priorTable">
          <table id="pAuthQualificationModalTable" className="table table-styling mb-5">
            <thead>
              <tr>
                <th colSpan="8" className="text-center border-end border-dark">Patient Information</th>
                <th colSpan="4" className="text-center providerInfoHeading"> Provider Information</th>
              </tr>
              <tr className="text-center table-columns-name">
                <th style={{ paddingRight: "3.9rem" }}>Full Name</th>
                <th>Date of Birth</th>
                <th>Gender</th>
                <th>Contact Number</th>
                <th style={{ paddingRight: "5.6rem" }}>Email Address</th>
                <th>Care Gap</th>
                <th>Explain the Care Gap in Detail</th>
                <th className="border-end border-dark">
                  Reason for the patient to come to the clinic for the Care Gap
                  or procedure
                </th>
                <th>Provider Name</th>
                <th>Clinic/Facility Name</th>
                <th>Contact Number</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {addRows.map((row, index) => (
                <tr key={index} className="uploadLeadForm-table-row">
                  <td><input type="text" onBlur={resetErrorFieldStyle} className="form-control PatientFullName" placeholder="John Smith" /></td>
                  <td><input type="date" onBlur={resetErrorFieldStyle} className="form-control PatientDateOfBirth" placeholder="" /></td>
                  <td><input type="text" onBlur={resetErrorFieldStyle} className="form-control PatientGender" placeholder="male" /></td>
                  <td><InputMask mask="999-999-9999" onBlur={resetErrorFieldStyle} className="form-control PatientContactNumber" placeholder="123-456-7890" /></td>
                  <td><input type="email" onBlur={resetErrorFieldStyle} className="form-control PatientEmailAddress" placeholder="john.smith@example.com" /></td>
                  <td><input type="text" onBlur={resetErrorFieldStyle} className="form-control CareGap" placeholder="HBA1C" /></td>
                  <td><input type="text" onBlur={resetErrorFieldStyle} className="form-control DetailCareGap" placeholder="Flu Shot prevents complications assicoated with the Flu virus and reduces the risk of hospitilization etc…" /></td>
                  <td><input type="text" onBlur={resetErrorFieldStyle} className="form-control PatientsFillCareGap" placeholder="Why should you want your patients to fill in the care gap at your clinic, Please explain" /></td>
                  <td>
                    <select className="ProviderName uploadLeadProviders form-select" dataindex={index} onChange={updateGridRowData} onBlur={resetErrorFieldStyle}>
                      {
                        providerData.length > 0 ?
                          providerData.map((provider) => {
                            return (
                              <option value={provider.Id}>{provider.ProviderName}</option>
                            );
                          })
                          : ""
                      }
                    </select>
                  </td>

                  <td><input type="text" onBlur={resetErrorFieldStyle} className="form-control ProviderFacilityName" value={row.ProviderFacilityName} /></td>
                  <td><input type="text" onBlur={resetErrorFieldStyle} className="form-control ProviderContactNumber" value={row.ProviderContactNumber} /></td>
                  <td><button className="simple-btn" onClick={() => deleteRowHandle(row.Id)}><FontAwesomeIcon icon={faXmark} /></button></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="text-end position-absolute bottom-0 mb-3">
          <button className="simple-btn" onClick={isAnyFormFieldEmpty} >Save</button>
        </div>
      </div>
      <Modal className="popup" show={modalShow} onHide={() => setModalShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Do you confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>By saving you are verifying that you are uploading the data in the database</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="simple-btn me-2" onClick={() => setModalShow(false)}>No</button>
          <button className="simple-btn" onClick={saveRecordHandle}>Yes</button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

