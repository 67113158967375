import React, { useEffect, useState } from "react";
import { TextField, Autocomplete, MenuItem } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import axios from 'axios';
import { useContextStateManagement } from "../context/ContextApi";
import { useDispatch } from "react-redux";
import { logout } from "../features/userSlice";

export const GeneralCallSetting = () => {
    const [providerDetail, setProviderDetail] = useState([]);
    const [patientDetail, setPatientDetail] = useState([]);
    const dispatch = useDispatch();
    const { PracticeId, activeTab, activeOnBoardingTem, setActiveOnBoardingTem, sideBarLinkActive, dayOfCall,
        followUpCalls, timeOfCall, callAttemptsPerDay, doNotCallList, callSettingsData, fetchApiHandle, setCallSettingsData, setDoNotCallList,
        setCallAttemptsPerDay, setTimeOfCall, setFollowUpCalls, setDayOfCall, memberDoNotCallList, setMemberDoNotCallList,
        memberCallSettingsData, setMemberCallSettingsData, memberCallAttemptsPerDay, setMemberCallAttemptsPerDay,
        memberTimeOfCall, setMemberTimeOfCall, memberFollowUpCalls, setMemberFollowUpCalls, memberDayOfCall, setMemberDayOfCall,
        memberPatientName, setMemberPatientName, memberProviderName, setMemberProviderName, token, setToken, usersPostRequest, updateRequest, fetchUserRecord, activeUploadLead } = useContextStateManagement();
    let doNotCall = document.getElementById('doNotCallList');
    let callAttempts = document.getElementById('callAttemptsPerDay');
    let callTime = document.getElementById('timeOfCall');
    let callsfollowUp = document.getElementById('followUpCalls');
    let dayCall = document.getElementById('dayOfCall');
    let patientName = document.getElementById('patientName');
    let memberProviderDetails = document.getElementById('providerDetails')
    let memberDoNotCall = document.getElementById('memberDoNotCallList');
    let memberCallAttempts = document.getElementById('memberCallAttemptsPerDay');
    let memberCallTime = document.getElementById('memberTimeOfCall');
    let memberCallsfollowUp = document.getElementById('memberFollowUpCalls');
    let memberCallDay = document.getElementById('memberDayOfCall');
    let weekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const [isTokenExpired, setIsTokenExpired] = useState(false);

    const fetchPatientsHandle = async () => {
        const userData = await fetchUserRecord(token, activeUploadLead);
        if (userData == "Token is expired, login again") {
            setIsTokenExpired(true)
        }
        else {
            setPatientDetail(userData)
        }
    }

    const fetchProvidersHandle = async () => {
        const userData = await fetchUserRecord(token, activeOnBoardingTem);
        if (userData == "Token is expired, login again") {
            setIsTokenExpired(true)
        }
        else {
            setProviderDetail(userData)
        }

    }

    const generalCallSettingHandle = async () => {

        if (callSettingsData.length > 0) {

            let obj = {
                Id: callSettingsData[0].Id,
                PracticeId: PracticeId,
                DoNotCallList: doNotCall.value,
                NumberOfCallAttemptPerDays: callAttempts.value,
                TimeOfCall: callTime.value,
                FollowUpCallRelation: callsfollowUp.value,
                DayOfCall: dayOfCall
            }

            let url = `/api/GeneralCallSettings/${callSettingsData[0].Id}`;
            const userData = await updateRequest(token, url, obj);
            if (userData == "Token is expired, login again") {
                setIsTokenExpired(true)
            }
            else if (userData && userData.message) {
                fetchApiHandle()
                alert(userData.message)
            }

        } else {

            let obj = {
                PracticeId: PracticeId,
                DoNotCallList: doNotCall.value,
                NumberOfCallAttemptPerDays: callAttempts.value,
                TimeOfCall: callTime.value,
                FollowUpCallRelation: callsfollowUp.value,
                DayOfCall: dayOfCall
            }

            let url = '/api/GeneralCallSettings';
            const userData = await usersPostRequest(token, url, obj);
            if (userData == "Token is expired, login again") {
                setIsTokenExpired(true)
            }
            else if (userData && userData.message) {
                fetchApiHandle()
                alert(userData.message)
            }
        }
    }

    const specificMemberCallSettingHandle = async () => {
        if (memberCallSettingsData.length > 0) {
            let obj = {
                Id: memberCallSettingsData[0].Id,
                PracticeId: PracticeId,
                PatientName: patientName.value,
                ProviderDetails: memberProviderDetails.value,
                DoNotCallList: memberDoNotCall.value,
                NumberOfCallAttemptPerDays: memberCallAttempts.value,
                TimeOfCall: memberCallTime.value,
                FollowUpCallRelation: memberCallsfollowUp.value,
                DayOfCall: memberDayOfCall
            }

            let url = `/api/CallSettingsMemberListWise/${memberCallSettingsData[0].Id}`;
            const userData = await updateRequest(token, url, obj);
            if (userData == "Token is expired, login again") {
                setIsTokenExpired(true)
            }
            else if (userData && userData.message) {
                fetchApiHandle()
                alert(userData.message)
            }

        } else {

            let obj = {
                PracticeId: PracticeId,
                PatientName: patientName.value,
                ProviderDetails: memberProviderDetails.value,
                DoNotCallList: memberDoNotCall.value,
                NumberOfCallAttemptPerDays: memberCallAttempts.value,
                TimeOfCall: memberCallTime.value,
                FollowUpCallRelation: memberCallsfollowUp.value,
                DayOfCall: memberDayOfCall
            }

            let url = `/api/CallSettingsMemberListWise`;
            const userData = await usersPostRequest(token, url, obj);
            if (userData == "Token is expired, login again") {
                setIsTokenExpired(true)
            }
            else if (userData && userData.message) {
                fetchApiHandle()
                alert(userData.message)
            }
        }
    }

    useEffect(() => {
        fetchProvidersHandle()
        fetchPatientsHandle()
    }, [activeTab])

    useEffect(() => {
        if (isTokenExpired === true) {
            alert("Token is expired, login again");
            setToken("")
            setIsTokenExpired(false)
            dispatch(logout());
        }
    }, [isTokenExpired]);

    return (
        <>
            <div className="row" id="callSettingGrid" >
                <div className="col-lg-12" id="">
                    <div>
                        <div id="pAuthQCallSettingTable" className="pAuthQ-callSetting-table">
                            <p className="heading">General Call Settings for AI Calls</p>
                            <table className="table">
                                <thead>
                                    <tr className="call-row-heading">
                                        <th className="callSettingsHeading text-white">Do Not Call the list</th>
                                        <th className="callSettingsHeading">Call Attempts/Day</th>
                                        <th className="callSettingsHeading">Time of Call (CST)</th>
                                        <th className="callSettingsHeading">Follow up Call if the first call was busy or missed or not connected in the first attempt 2 days</th>
                                        <th className="callSettingsHeading">Day of Call</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <select className="form-select" value={doNotCallList} onChange={(e) => setDoNotCallList(e.target.value)} id="doNotCallList">
                                                <option value="false">No</option>
                                                <option value="true">Yes</option>
                                            </select>
                                            <button type="submit" className="mt-3 mb-1 simple-btn px-4" onClick={generalCallSettingHandle}>Save</button>
                                        </td>
                                        <td>
                                            <select className="form-select" id="callAttemptsPerDay" value={callAttemptsPerDay} onChange={(e) => setCallAttemptsPerDay(e.target.value)}>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select className="form-select dropdownCallSettingsTimeOfCall" id="timeOfCall" value={timeOfCall} onChange={(e) => setTimeOfCall(e.target.value)}>
                                                <option value="08AM">08 AM</option>
                                                <option value="09AM">09 AM</option>
                                                <option value="10AM">10 AM</option>
                                                <option value="11AM">11 AM</option>
                                                <option value="12AM">12 AM</option>
                                                <option value="01PM">01 PM</option>
                                                <option value="02PM">02 PM</option>
                                                <option value="03PM">03 PM</option>
                                                <option value="04PM">04 PM</option>
                                                <option value="05PM">05 PM</option>
                                                <option value="06PM">06 PM</option>
                                                <option value="07PM">07 PM</option>
                                                <option value="08PM">08 PM</option>
                                                <option value="09PM">09 PM</option>
                                                <option value="10PM">10 PM</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select className="form-select dropdownCallSettingsFollowUpCall" id="followUpCalls" value={followUpCalls} onChange={(e) => setFollowUpCalls(e.target.value)}>
                                                <option value="1extraday">1 extra day</option>
                                                <option value="2days">2 days</option>
                                                <option value="3days">3 days</option>
                                                <option value="4days">4 days</option>
                                                <option value="5days">5 days</option>
                                                <option value="1week">1 week</option>
                                                <option value="2weeks">2 weeks</option>
                                                <option value="nofollowup">No Follow up once the conversation is done</option>
                                                <option value="nofollowupatall">No follow up at all</option>
                                            </select>
                                        </td>
                                        <td>
                                            <Autocomplete
                                                sx={{ backgroundColor: "#fff", color: "#000", height: 32, width: 275, borderRadius: ".25rem" }}
                                                multiple
                                                id="dayOfCall"
                                                value={dayOfCall}
                                                onChange={(event, newValue) => {
                                                    setDayOfCall(newValue);
                                                }}
                                                popupIcon={< KeyboardArrowDownOutlinedIcon />}
                                                options={weekDays}
                                                getOptionLabel={(option) => option}
                                                disableCloseOnSelect
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        // variant="outlined"
                                                        className="textField"
                                                        placeholder="Select Days of Call ..."
                                                        size="small"
                                                        color="secondary"
                                                    />
                                                )}
                                                renderOption={(props, option, { selected }) => (
                                                    <MenuItem
                                                        {...props}
                                                        key={option}
                                                        value={option}
                                                        sx={{ fontSize: "0.75rem", justifyContent: "space-between" }}
                                                    >
                                                        {option}
                                                        {selected ? <CheckIcon color="info" /> : null}
                                                    </MenuItem>
                                                )}
                                            />

                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                            <p className="heading">Specific Member Settings for AI Calls</p>
                            <table className="table">
                                <thead>
                                    <tr className="call-row-heading">
                                        <th className="callSettingsHeading">Patient Name</th>
                                        <th className="callSettingsHeading">Provider Details</th>
                                        <th className="callSettingsHeading">Do Not Call the list</th>
                                        <th className="callSettingsHeading">Call Attempts/Day</th>
                                        <th className="callSettingsHeading">Time of Call (CST)</th>
                                        <th className="callSettingsHeading">Follow up Call if the first call was busy or missed or not connected in the first attempt 2 days</th>
                                        <th className="callSettingsHeading">Day of Call</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <select className="form-select" id="patientName" value={memberPatientName} onChange={(e) => setMemberPatientName(e.target.value)}>
                                                {
                                                    patientDetail.length > 0 ?
                                                        patientDetail.map((patient) => {
                                                            return (
                                                                <option value={patient.Id}>{patient.PatientFullName}</option>
                                                            )
                                                        })
                                                        : ""
                                                }
                                            </select>
                                            <button type="submit" className="mt-3 mb-1 simple-btn px-4" onClick={specificMemberCallSettingHandle}>Save</button>
                                        </td>
                                        <td>
                                            <select className="form-select" id="providerDetails" value={memberProviderName} onChange={(e) => setMemberProviderName(e.target.value)}>
                                                {
                                                    providerDetail.length > 0 ?
                                                        providerDetail.map((provider) => {
                                                            return (
                                                                <option value={provider.Id}>{provider.ProviderName}</option>
                                                            )
                                                        })
                                                        : ""
                                                }
                                            </select>
                                        </td>
                                        <td>
                                            <select className="form-select" id="memberDoNotCallList" value={memberDoNotCallList} onChange={(e) => setMemberDoNotCallList(e.target.value)}>
                                                <option value="false">No</option>
                                                <option value="true">Yes</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select className="form-select" id="memberCallAttemptsPerDay" value={memberCallAttemptsPerDay} onChange={(e) => setMemberCallAttemptsPerDay(e.target.value)}>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select className="form-select dropdownCallSettingsTimeOfCall" id="memberTimeOfCall" value={memberTimeOfCall} onChange={(e) => setMemberTimeOfCall(e.target.value)}>
                                                <option value="08AM">08 AM</option>
                                                <option value="09AM">09 AM</option>
                                                <option value="10AM">10 AM</option>
                                                <option value="11AM">11 AM</option>
                                                <option value="12AM">12 AM</option>
                                                <option value="01PM">01 PM</option>
                                                <option value="02PM">02 PM</option>
                                                <option value="03PM">03 PM</option>
                                                <option value="04PM">04 PM</option>
                                                <option value="05PM">05 PM</option>
                                                <option value="06PM">06 PM</option>
                                                <option value="07PM">07 PM</option>
                                                <option value="08PM">08 PM</option>
                                                <option value="09PM">09 PM</option>
                                                <option value="10PM">10 PM</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select className="form-select dropdownCallSettingsFollowUpCall" id="memberFollowUpCalls" value={memberFollowUpCalls} onChange={(e) => setMemberFollowUpCalls(e.target.value)}>
                                                <option value="1extraday">1 extra day</option>
                                                <option value="2days">2 days</option>
                                                <option value="3days">3 days</option>
                                                <option value="4days">4 days</option>
                                                <option value="5days">5 days</option>
                                                <option value="1week">1 week</option>
                                                <option value="2weeks">2 weeks</option>
                                                <option value="nofollowup">No Follow up once the conversation is done</option>
                                                <option value="nofollowupatall">No follow up at all</option>
                                            </select>
                                        </td>
                                        <td className="multiSelectDaysList">
                                            <Autocomplete
                                                sx={{ backgroundColor: "#fff", height: 32, width: 275, borderRadius: ".25rem" }}
                                                multiple
                                                id="memberDayOfCall"
                                                value={memberDayOfCall}
                                                onChange={(event, newValue) => {
                                                    setMemberDayOfCall(newValue);
                                                }}
                                                popupIcon={< KeyboardArrowDownOutlinedIcon />}
                                                options={weekDays}
                                                getOptionLabel={(option) => option}
                                                disableCloseOnSelect
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        // variant="outlined"
                                                        className="textField"
                                                        placeholder="Select Days of Call ..."
                                                        size="small"
                                                        autoFocus
                                                    // defaultValue="Small"
                                                    />
                                                )}
                                                renderOption={(props, option, { selected }) => (
                                                    <MenuItem
                                                        {...props}
                                                        key={option}
                                                        value={option}
                                                        sx={{ fontSize: "0.75rem", justifyContent: "space-between" }}
                                                    >
                                                        {option}
                                                        {selected ? <CheckIcon color="info" /> : null}
                                                    </MenuItem>
                                                )}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <table className="table" id="pAuthStatusCallSettingTable" style={{ display: "none" }}>
                            <thead>

                                <tr className="call-row-heading">
                                    <th>Do Not Call the list</th>
                                    <th>Call Attempts/Day</th>
                                    <th>Time of Call (CST)</th>
                                    <th>Follow up Call if the first call was busy or missed or not connected in the first attempt 2 days</th>
                                    <th>Day of Call</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <select className="form-select">
                                            <option value="No">No</option>
                                            <option value="yes">Yes</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select className="form-select">
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select className="form-select dropdownCallSettingsTimeOfCall" >
                                            <option value="08AM">08 AM</option>
                                            <option value="09AM">09 AM</option>
                                            <option value="10AM">10 AM</option>
                                            <option value="11AM">11 AM</option>
                                            <option value="12AM">12 AM</option>
                                            <option value="01PM">01 PM</option>
                                            <option value="02PM">02 PM</option>
                                            <option value="03PM">03 PM</option>
                                            <option value="04PM">04 PM</option>
                                            <option value="05PM">05 PM</option>
                                            <option value="06PM">06 PM</option>
                                            <option value="07PM">07 PM</option>
                                            <option value="08PM">08 PM</option>
                                            <option value="09PM">09 PM</option>
                                            <option value="10PM">10 PM</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select className="form-select dropdownCallSettingsFollowUpCall">
                                            <option value="1extraday">1 extra day</option>
                                            <option value="2days">2 days</option>
                                            <option value="3days">3 days</option>
                                            <option value="4days">4 days</option>
                                            <option value="5days">5 days</option>
                                            <option value="1week">1 week</option>
                                            <option value="2weeks">2 weeks</option>
                                            <option value="nofollowup">No Follow up once the conversation is done</option>
                                            <option value="nofollowupatall">No follow up at all</option>
                                        </select>
                                    </td>
                                    <td className="multiSelectDaysList">
                                        <select className="form-select" id="pAuthStatusMultiSelectDays" multiple="multiple" data-placeholder="Select Days of Call . . .">
                                            <option>Monday</option>
                                            <option>Tuesday</option>
                                            <option>Wednesday</option>
                                            <option>Thursday</option>
                                            <option>Friday</option>
                                            <option>Saturday</option>
                                            <option>Sunday</option>
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table className="table" id="benefitVCallSettingTable" style={{ display: "none" }}>
                            <thead>

                                <tr className="call-row-heading">
                                    <th>Do Not Call the list</th>
                                    <th>Call Attempts/Day</th>
                                    <th>Time of Call (CST)</th>
                                    <th>Follow up Call if the first call was busy or missed or not connected in the first attempt 2 days</th>
                                    <th>Day of Call</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <select className="form-select">
                                            <option value="No">No</option>
                                            <option value="yes">Yes</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select className="form-select">
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select className="form-select dropdownCallSettingsTimeOfCall" >
                                            <option value="08AM">08 AM</option>
                                            <option value="09AM">09 AM</option>
                                            <option value="10AM">10 AM</option>
                                            <option value="11AM">11 AM</option>
                                            <option value="12AM">12 AM</option>
                                            <option value="01PM">01 PM</option>
                                            <option value="02PM">02 PM</option>
                                            <option value="03PM">03 PM</option>
                                            <option value="04PM">04 PM</option>
                                            <option value="05PM">05 PM</option>
                                            <option value="06PM">06 PM</option>
                                            <option value="07PM">07 PM</option>
                                            <option value="08PM">08 PM</option>
                                            <option value="09PM">09 PM</option>
                                            <option value="10PM">10 PM</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select className="form-select dropdownCallSettingsFollowUpCall">
                                            <option value="1extraday">1 extra day</option>
                                            <option value="2days">2 days</option>
                                            <option value="3days">3 days</option>
                                            <option value="4days">4 days</option>
                                            <option value="5days">5 days</option>
                                            <option value="1week">1 week</option>
                                            <option value="2weeks">2 weeks</option>
                                            <option value="nofollowup">No Follow up once the conversation is done</option>
                                            <option value="nofollowupatall">No follow up at all</option>
                                        </select>
                                    </td>
                                    <td className="multiSelectDaysList">
                                        <select className="form-select" id="benefitVMultiSelectDays" multiple="multiple" data-placeholder="Select Days of Call . . .">
                                            <option>Monday</option>
                                            <option>Tuesday</option>
                                            <option>Wednesday</option>
                                            <option>Thursday</option>
                                            <option>Friday</option>
                                            <option>Saturday</option>
                                            <option>Sunday</option>
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table className="table" id="careRCallSettingTable" style={{ display: "none" }}>
                            <thead>

                                <tr className="call-row-heading">
                                    <th>Do Not Call the list</th>
                                    <th>Call Attempts/Day</th>
                                    <th>Time of Call (CST)</th>
                                    <th>Follow up Call if the first call was busy or missed or not connected in the first attempt 2 days</th>
                                    <th>Day of Call</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <select className="form-select">
                                            <option value="No">No</option>
                                            <option value="yes">Yes</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select className="form-select">
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select className="form-select dropdownCallSettingsTimeOfCall" >
                                            <option value="08AM">08 AM</option>
                                            <option value="09AM">09 AM</option>
                                            <option value="10AM">10 AM</option>
                                            <option value="11AM">11 AM</option>
                                            <option value="12AM">12 AM</option>
                                            <option value="01PM">01 PM</option>
                                            <option value="02PM">02 PM</option>
                                            <option value="03PM">03 PM</option>
                                            <option value="04PM">04 PM</option>
                                            <option value="05PM">05 PM</option>
                                            <option value="06PM">06 PM</option>
                                            <option value="07PM">07 PM</option>
                                            <option value="08PM">08 PM</option>
                                            <option value="09PM">09 PM</option>
                                            <option value="10PM">10 PM</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select className="form-select dropdownCallSettingsFollowUpCall">
                                            <option value="1extraday">1 extra day</option>
                                            <option value="2days">2 days</option>
                                            <option value="3days">3 days</option>
                                            <option value="4days">4 days</option>
                                            <option value="5days">5 days</option>
                                            <option value="1week">1 week</option>
                                            <option value="2weeks">2 weeks</option>
                                            <option value="nofollowup">No Follow up once the conversation is done</option>
                                            <option value="nofollowupatall">No follow up at all</option>
                                        </select>
                                    </td>
                                    <td className="multiSelectDaysList">
                                        <select className="form-select" id="careRMultiSelectDays" multiple="multiple" data-placeholder="Select Days of Call . . .">
                                            <option>Monday</option>
                                            <option>Tuesday</option>
                                            <option>Wednesday</option>
                                            <option>Thursday</option>
                                            <option>Friday</option>
                                            <option>Saturday</option>
                                            <option>Sunday</option>
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table className="table" id="visitRCallSettingTable" style={{ display: "none" }}>
                            <thead>

                                <tr className="call-row-heading">
                                    <th>Do Not Call the list</th>
                                    <th>Call Attempts/Day</th>
                                    <th>Time of Call (CST)</th>
                                    <th>Follow up Call if the first call was busy or missed or not connected in the first attempt 2 days</th>
                                    <th>Day of Call</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <select className="form-select">
                                            <option value="No">No</option>
                                            <option value="yes">Yes</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select className="form-select">
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select className="form-select dropdownCallSettingsTimeOfCall" >
                                            <option value="08AM">08 AM</option>
                                            <option value="09AM">09 AM</option>
                                            <option value="10AM">10 AM</option>
                                            <option value="11AM">11 AM</option>
                                            <option value="12AM">12 AM</option>
                                            <option value="01PM">01 PM</option>
                                            <option value="02PM">02 PM</option>
                                            <option value="03PM">03 PM</option>
                                            <option value="04PM">04 PM</option>
                                            <option value="05PM">05 PM</option>
                                            <option value="06PM">06 PM</option>
                                            <option value="07PM">07 PM</option>
                                            <option value="08PM">08 PM</option>
                                            <option value="09PM">09 PM</option>
                                            <option value="10PM">10 PM</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select className="form-select dropdownCallSettingsFollowUpCall">
                                            <option value="1extraday">1 extra day</option>
                                            <option value="2days">2 days</option>
                                            <option value="3days">3 days</option>
                                            <option value="4days">4 days</option>
                                            <option value="5days">5 days</option>
                                            <option value="1week">1 week</option>
                                            <option value="2weeks">2 weeks</option>
                                            <option value="nofollowup">No Follow up once the conversation is done</option>
                                            <option value="nofollowupatall">No follow up at all</option>
                                        </select>
                                    </td>
                                    <td className="multiSelectDaysList">
                                        <select className="form-select" id="visitRMultiSelectDays" multiple="multiple" data-placeholder="Select Days of Call . . .">
                                            <option>Monday</option>
                                            <option>Tuesday</option>
                                            <option>Wednesday</option>
                                            <option>Thursday</option>
                                            <option>Friday</option>
                                            <option>Saturday</option>
                                            <option>Sunday</option>
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table className="table" id="awareCCallSettingTable" style={{ display: "none" }}>
                            <thead>

                                <tr className="call-row-heading">
                                    <th>Do Not Call the list</th>
                                    <th>Call Attempts/Day</th>
                                    <th>Time of Call (CST)</th>
                                    <th>Follow up Call if the first call was busy or missed or not connected in the first attempt 2 days</th>
                                    <th>Day of Call</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <select className="form-select">
                                            <option value="No">No</option>
                                            <option value="yes">Yes</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select className="form-select">
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select className="form-select dropdownCallSettingsTimeOfCall" >
                                            <option value="08AM">08 AM</option>
                                            <option value="09AM">09 AM</option>
                                            <option value="10AM">10 AM</option>
                                            <option value="11AM">11 AM</option>
                                            <option value="12AM">12 AM</option>
                                            <option value="01PM">01 PM</option>
                                            <option value="02PM">02 PM</option>
                                            <option value="03PM">03 PM</option>
                                            <option value="04PM">04 PM</option>
                                            <option value="05PM">05 PM</option>
                                            <option value="06PM">06 PM</option>
                                            <option value="07PM">07 PM</option>
                                            <option value="08PM">08 PM</option>
                                            <option value="09PM">09 PM</option>
                                            <option value="10PM">10 PM</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select className="form-select dropdownCallSettingsFollowUpCall">
                                            <option value="1extraday">1 extra day</option>
                                            <option value="2days">2 days</option>
                                            <option value="3days">3 days</option>
                                            <option value="4days">4 days</option>
                                            <option value="5days">5 days</option>
                                            <option value="1week">1 week</option>
                                            <option value="2weeks">2 weeks</option>
                                            <option value="nofollowup">No Follow up once the conversation is done</option>
                                            <option value="nofollowupatall">No follow up at all</option>
                                        </select>
                                    </td>
                                    <td className="multiSelectDaysList">
                                        <select className="form-select" id="awareCMultiSelectDays" multiple="multiple" data-placeholder="Select Days of Call . . .">
                                            <option>Monday</option>
                                            <option>Tuesday</option>
                                            <option>Wednesday</option>
                                            <option>Thursday</option>
                                            <option>Friday</option>
                                            <option>Saturday</option>
                                            <option>Sunday</option>
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table className="table" id="leadGCallSettingTable" style={{ display: "none" }}>
                            <thead>

                                <tr className="call-row-heading">
                                    <th>Do Not Call the list</th>
                                    <th>Call Attempts/Day</th>
                                    <th>Time of Call (CST)</th>
                                    <th>Follow up Call if the first call was busy or missed or not connected in the first attempt 2 days</th>
                                    <th>Day of Call</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <select className="form-select">
                                            <option value="No">No</option>
                                            <option value="yes">Yes</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select className="form-select">
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select className="form-select dropdownCallSettingsTimeOfCall" >
                                            <option value="08AM">08 AM</option>
                                            <option value="09AM">09 AM</option>
                                            <option value="10AM">10 AM</option>
                                            <option value="11AM">11 AM</option>
                                            <option value="12AM">12 AM</option>
                                            <option value="01PM">01 PM</option>
                                            <option value="02PM">02 PM</option>
                                            <option value="03PM">03 PM</option>
                                            <option value="04PM">04 PM</option>
                                            <option value="05PM">05 PM</option>
                                            <option value="06PM">06 PM</option>
                                            <option value="07PM">07 PM</option>
                                            <option value="08PM">08 PM</option>
                                            <option value="09PM">09 PM</option>
                                            <option value="10PM">10 PM</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select className="form-select dropdownCallSettingsFollowUpCall">
                                            <option value="1extraday">1 extra day</option>
                                            <option value="2days">2 days</option>
                                            <option value="3days">3 days</option>
                                            <option value="4days">4 days</option>
                                            <option value="5days">5 days</option>
                                            <option value="1week">1 week</option>
                                            <option value="2weeks">2 weeks</option>
                                            <option value="nofollowup">No Follow up once the conversation is done</option>
                                            <option value="nofollowupatall">No follow up at all</option>
                                        </select>
                                    </td>
                                    <td className="multiSelectDaysList">
                                        <select className="form-select" id="leadGMultiSelectDays" multiple="multiple" data-placeholder="Select Days of Call . . .">
                                            <option>Monday</option>
                                            <option>Tuesday</option>
                                            <option>Wednesday</option>
                                            <option>Thursday</option>
                                            <option>Friday</option>
                                            <option>Saturday</option>
                                            <option>Sunday</option>
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table className="table" id="surveyCallSettingTable" style={{ display: "none" }}>
                            <thead>

                                <tr className="call-row-heading">
                                    <th>Do Not Call the list</th>
                                    <th>Call Attempts/Day</th>
                                    <th>Time of Call (CST)</th>
                                    <th>Follow up Call if the first call was busy or missed or not connected in the first attempt 2 days</th>
                                    <th>Day of Call</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <select className="form-select">
                                            <option value="No">No</option>
                                            <option value="yes">Yes</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select className="form-select">
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select className="form-select dropdownCallSettingsTimeOfCall" >
                                            <option value="08AM">08 AM</option>
                                            <option value="09AM">09 AM</option>
                                            <option value="10AM">10 AM</option>
                                            <option value="11AM">11 AM</option>
                                            <option value="12AM">12 AM</option>
                                            <option value="01PM">01 PM</option>
                                            <option value="02PM">02 PM</option>
                                            <option value="03PM">03 PM</option>
                                            <option value="04PM">04 PM</option>
                                            <option value="05PM">05 PM</option>
                                            <option value="06PM">06 PM</option>
                                            <option value="07PM">07 PM</option>
                                            <option value="08PM">08 PM</option>
                                            <option value="09PM">09 PM</option>
                                            <option value="10PM">10 PM</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select className="form-select dropdownCallSettingsFollowUpCall">
                                            <option value="1extraday">1 extra day</option>
                                            <option value="2days">2 days</option>
                                            <option value="3days">3 days</option>
                                            <option value="4days">4 days</option>
                                            <option value="5days">5 days</option>
                                            <option value="1week">1 week</option>
                                            <option value="2weeks">2 weeks</option>
                                            <option value="nofollowup">No Follow up once the conversation is done</option>
                                            <option value="nofollowupatall">No follow up at all</option>
                                        </select>
                                    </td>
                                    <td className="multiSelectDaysList">
                                        <select className="form-select" id="surveyMultiSelectDays" multiple="multiple" data-placeholder="Select Days of Call . . .">
                                            <option>Monday</option>
                                            <option>Tuesday</option>
                                            <option>Wednesday</option>
                                            <option>Thursday</option>
                                            <option>Friday</option>
                                            <option>Saturday</option>
                                            <option>Sunday</option>
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </>
    )
}
