import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logout } from "../features/userSlice";
import { useNavigate } from "react-router-dom";

const StateManagement = createContext(null)
const useContextStateManagement = () => useContext(StateManagement)

const ContextStateManagement = ({ children }) => {

  const [memberListName, setMemberListName] = useState("Prior Auth Qualification");
  const [activeTab, setActiveTab] = useState("pAuthQTab");
  const [PracticeId, setPracticeId] = useState("");
  const [userName, setUserName] = useState();
  const [userProfession, setUserProfession] = useState();
  const [dateUrl, setDateUrl] = useState();
  const [sideBarLinkActive, setSideBarLinkActive] = useState("careConsole");
  const [currentUrl, setCurrentUrl] = useState("");
  const [dashboardUrl, setDashboardUrl] = useState("");
  const [tokenError, setTokenError] = useState("");
  const [activeOnBoardingTem, setActiveOnBoardingTem] = useState();
  const [callSettingsData, setCallSettingsData] = useState([]);
  const [doNotCallList, setDoNotCallList] = useState("");
  const [callAttemptsPerDay, setCallAttemptsPerDay] = useState("");
  const [timeOfCall, setTimeOfCall] = useState("");
  const [followUpCalls, setFollowUpCalls] = useState("");
  const [dayOfCall, setDayOfCall] = useState([]);
  const [memberCallSettingsData, setMemberCallSettingsData] = useState([]);
  const [memberDoNotCallList, setMemberDoNotCallList] = useState("");
  const [memberCallAttemptsPerDay, setMemberCallAttemptsPerDay] = useState("");
  const [memberTimeOfCall, setMemberTimeOfCall] = useState("");
  const [memberFollowUpCalls, setMemberFollowUpCalls] = useState("");
  const [memberDayOfCall, setMemberDayOfCall] = useState([]);
  const [memberPatientName, setMemberPatientName] = useState("");
  const [memberProviderName, setMemberProviderName] = useState("");
  const [providerId, setProviderId] = useState(0);
  const [providerData, setProviderData] = useState([]);
  const [accessRights, setAccessRights] = useState([]);
  const [token, setToken] = useState("");
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [tokenExpiryTime, setTokenExpiryTime] = useState();
  const [activeUploadLead, setActiveUploadLead] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // useSelector for getting logged in user token
  const user = useSelector(state => state.user.user);

  const usersPostRequest = async (token, apiUrl, obj) => {
    // debugger
    try {
      if (token) {
        const response = await axios.post(apiUrl, obj, {
          headers: {
            Authorization: `Bearer ${token}` // Include token in Authorization header
          }
        });
        return response.data; // Return user record data
      }
    } catch (error) {
      return error.response.data.error;
    }
  };

  const updateRequest = async (token, apiUrl, obj) => {
    // debugger
    try {
      if (token) {
        const response = await axios.put(apiUrl, obj, {
          headers: {
            Authorization: `Bearer ${token}` // Include token in Authorization header
          }
        });
        return response.data; // Return user record data
      }
    } catch (error) {
      // error.response.data.error == "Token is expired, login again" ? setTokenError(error.response.data.error) : setTokenError("");
      return error.response.data.error
    }
  };

  const dateRangeRequest = async (token, apiUrl, obj) => {
    // debugger
    try {
      if (token) {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}` // Include token in Authorization header
          },
          params: obj
        });
        return response.data; // Return user record data
      }
    } catch (error) {
      // error.response.data.error == "Token is expired, login again" ? setTokenError(error.response.data.error) : setTokenError(""); // throw error; // Rethrow error for handling in calling function
      return error.response.data.error;
    }
  };

  const fetchUserRecord = async (token, apiUrl) => {
    // debugger
    try {
      if (token) {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}` // Include token in Authorization header
          }
        });
        return response.data; // Return user record data
      }
    } catch (error) {
      return error.response.data.error;
    }
  };

  const fetchData = async () => {
    setToken(user.token);
    const userData = await fetchUserRecord(user.token, '/api/currentUser');
    if (userData == "Token is expired, login again") {
      setPracticeId("")
      setIsTokenExpired(true)
    }
    else {
      let PracticeId = userData.data.PracticeId;
      let userName = userData.data.UserName;
      let userProfession = userData.data.UserProfession;
      setUserName(userName)
      setUserProfession(userProfession)
      setPracticeId(PracticeId)
      setTokenExpiryTime(userData.message.exp)
    }
  };

  useEffect(() => {
    // debugger
    if (user && user.token) {
      fetchData();
    }
  }, [user]);
  
  async function fetchAccessRights() {
    const userData = await fetchUserRecord(user.token, `/api/getAccessRightsDetails/${PracticeId}`);
    if (userData) {
      setAccessRights(userData);
    }
  }

  const fetchApiHandle = async () => {
    const userData = await fetchUserRecord(user.token, `/api/GeneralCallSettings/${PracticeId}`);
    if (userData == "Token is expired, login again") {
      setIsTokenExpired(true)
    }
    else if (userData == "General call settings are not saved yet, please add settings and saved") {
      alert(userData)
    }
    else {
      setCallSettingsData(userData)
      setDoNotCallList(userData[0].DoNotCallList)
      setCallAttemptsPerDay(userData[0].NumberOfCallAttemptPerDays)
      setTimeOfCall(userData[0].TimeOfCall)
      setFollowUpCalls(userData[0].FollowUpCallRelation)
      setDayOfCall(userData[0].DayOfCall)
    }

    const userDataMemberCallSettings = await fetchUserRecord(user.token, `/api/CallSettingsMemberListWise/${PracticeId}`);
    if (userDataMemberCallSettings == "Token is expired, login again") {
      setIsTokenExpired(true)
    }
    else if (userDataMemberCallSettings == "Specific member call settings are not saved yet, please add settings and saved") {
      setMemberCallSettingsData([])
      // alert(userDataMemberCallSettings)
    }
    else {
      setMemberCallSettingsData(userDataMemberCallSettings)
      setMemberPatientName(userDataMemberCallSettings[0].PatientName)
      setMemberProviderName(userDataMemberCallSettings[0].ProviderDetails)
      setMemberDoNotCallList(userDataMemberCallSettings[0].DoNotCallList)
      setMemberCallAttemptsPerDay(userDataMemberCallSettings[0].NumberOfCallAttemptPerDays)
      setMemberTimeOfCall(userDataMemberCallSettings[0].TimeOfCall)
      setMemberFollowUpCalls(userDataMemberCallSettings[0].FollowUpCallRelation)
      setMemberDayOfCall(userDataMemberCallSettings[0].DayOfCall)
    }

  }

  useEffect(() => {
    if (PracticeId) {
      setDoNotCallList("")
      setCallAttemptsPerDay("")
      setTimeOfCall("")
      setFollowUpCalls("")
      setDayOfCall([])
      setMemberPatientName("")
      setMemberProviderName("")
      setMemberDoNotCallList("")
      setMemberCallAttemptsPerDay("")
      setMemberTimeOfCall("")
      setMemberFollowUpCalls("")
      setMemberDayOfCall([])
    }
  }, [PracticeId]);

  useEffect(() => {
    if (PracticeId && user) {
      fetchAccessRights();
    }
  }, [PracticeId, user])


  useEffect(() => {

    if (!PracticeId) return;

    if (activeTab === "pAuthQTab") {
      setActiveOnBoardingTem(`/api/PAuthQOnBoardingTemplate/${PracticeId}`);
      setActiveUploadLead(`/api/PriorAuthQLead/${PracticeId}`);
    } else if (activeTab === "pAuthSTab") {
      setActiveOnBoardingTem(`/api/PAuthStatusOnboarding/${PracticeId}`);
      setActiveUploadLead(`/api/PAuthStatusUploadLead/${PracticeId}`);
    } else if (activeTab === "benefitVTab") {
      setActiveOnBoardingTem(`/api/BenefitsVerificationOnboarding/${PracticeId}`);
      setActiveUploadLead(`/api/BenefitsVerificationUploadLead/${PracticeId}`);
    } else if (activeTab === "careRTab") {
      setActiveOnBoardingTem(`/api/CareRemindersOnboarding/${PracticeId}`);
      setActiveUploadLead(`/api/CareRemindersUploadLead/${PracticeId}`);
    } else if (activeTab === "visitRTab") {
      setActiveOnBoardingTem(`/api/VisitRemindersOnboarding/${PracticeId}`);
      setActiveUploadLead(`/api/VisitRemindersUploadLead/${PracticeId}`);
    } else if (activeTab === "awareNessCTab") {
      setActiveOnBoardingTem(`/api/AwarenessCampaignOnBoarding/${PracticeId}`);
      setActiveUploadLead(`/api/AwarenessCampaignUploadLead/${PracticeId}`);
    } else if (activeTab === "leadGTab") {
      setActiveOnBoardingTem(`/api/LeadGenerationOnboarding/${PracticeId}`);
      setActiveUploadLead(`/api/LeadGenerationUploadLead/${PracticeId}`);
    } else if (activeTab === "surveyTab") {
      setDateUrl(`/api/SearchDateSurveyUploadLead`);
    }
  }, [PracticeId, activeTab])


  useEffect(() => {
    if (isTokenExpired === true) {
      alert("Token is expired, login again");
      setToken("")
      setIsTokenExpired(false)
      navigate("/");
      dispatch(logout());
    }
  }, [isTokenExpired]);

  return (
    <StateManagement.Provider value={{
      memberListName, setMemberListName, activeTab, setActiveTab,
      PracticeId, sideBarLinkActive, setSideBarLinkActive,
      setDateUrl, currentUrl, setCurrentUrl, userName, userProfession, dashboardUrl, setPracticeId,
      setDashboardUrl, activeOnBoardingTem, setActiveOnBoardingTem, fetchApiHandle, callSettingsData, setCallSettingsData,
      doNotCallList, setDoNotCallList, callAttemptsPerDay, setCallAttemptsPerDay, timeOfCall, setTimeOfCall, followUpCalls, setFollowUpCalls,
      dayOfCall, setDayOfCall, memberCallSettingsData, setMemberCallSettingsData, memberDoNotCallList, setMemberDoNotCallList,
      memberCallAttemptsPerDay, setMemberCallAttemptsPerDay, memberTimeOfCall, setMemberTimeOfCall, memberFollowUpCalls, setMemberFollowUpCalls,
      memberDayOfCall, setMemberDayOfCall, memberPatientName, setMemberPatientName, memberProviderName, setMemberProviderName,
      providerId, setProviderId, providerData, setProviderData, accessRights, fetchUserRecord, token, setToken,
      usersPostRequest, tokenError, setTokenError, activeOnBoardingTem, updateRequest, dateRangeRequest, activeUploadLead, setActiveUploadLead
    }} >
      {children}
    </StateManagement.Provider>
  )
}

export { useContextStateManagement, ContextStateManagement }
