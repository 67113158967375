import React, { useEffect, useState } from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { useSelector } from "react-redux";
import { Login } from "./components/Login";
import { Route, Routes } from 'react-router-dom';
import { Home } from "./components/Home";
import "./assets/css/CarePathStyles.css"
import "./assets/css/home.css";
import "./assets/css/style.css";
import "./assets/css/Site.css";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.min.js";
import "./assets/css/bootstrap.min.css";
import "./assets/js/bootstrap.min.js";
import './App.css';
import { Sidebar } from "./child-components/Sidebar";
import { Register } from "./components/Register";
import { Dashboard } from "./components/Dashboard.js";
import { useContextStateManagement } from "./context/ContextApi";
import { useLocation } from "react-router-dom";

function App() {
  const user = useSelector(state => state.user.user);
  const location = useLocation();
  const { currentUrl, dashboardUrl, setDashboardUrl, setSideBarLinkActive, userProfession, userName } = useContextStateManagement();
  const [isUploadLead, setIsUploadLead] = useState(false);


  useEffect(() => {
    window.history.pushState(currentUrl, document.title, window.location.href);
    window.history.pushState(dashboardUrl, document.title, window.location.href);
    window.addEventListener('popstate', function (event) {
      window.history.pushState(currentUrl, document.title, window.location.href);
      window.history.pushState(dashboardUrl, document.title, window.location.href);
    });
  }, [location, currentUrl]);

  return (
    <>
      {
        user ?
          <div className="container-fluid">
            <div className="row flex-nowrap">
              <div id="sideBar" className={`col-auto ${window.location.href && window.location.href.indexOf("Dashboard") > 0 ? "d-none" : ""} col-md-3 col-xl-2 ps-4 pe-0`}>
                <Sidebar setIsUploadLead={setIsUploadLead} />
              </div>
              <div className="col layout">
                <Routes>
                  <Route path="/" element={<Home setIsUploadLead={setIsUploadLead} isUploadLead={isUploadLead} />} />
                  <Route path="/Dashboard" element={< Dashboard setDashboardUrl={setDashboardUrl} setSideBarLinkActive={setSideBarLinkActive} userName={userName} userProfession={userProfession} />} />
                </Routes>
              </div>
            </div>
          </div>
	  // : currentUrl.indexOf("register") > 0 ?
          //   <Routes>
          //     <Route path="/register" element={<Register />} />
          //   </Routes>
          :
          <>
            <Login />
          </>  
      }
    </>
  );
}

export default App;
